import Page from "@/components/page";
import Pagination from "@/components/pagination";
import { Button, buttonVariants } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { TAdmin } from "@/types/admin";
import { TLogsList } from "@/types/general";
import { ChevronLeft, Loader2 } from "lucide-react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { NavLink, useLoaderData, useNavigation, useSearchParams } from "react-router-dom";

export default function LogsListPage() {
  const data = useLoaderData() as { logs: TLogsList; admins: TAdmin[] } | null;
  const { t } = useTranslation();
  const navigation = useNavigation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [user, setUser] = React.useState(searchParams.get("user") ?? "");
  const entity = searchParams.get("entity") ?? "";

  // TODO: Implement Error Page
  if (!data) return <div>Error</div>;
  return (
    <Page
      breadcrumbs={[{ label: t("sidebar.home"), link: "/" }, { label: t("logs.title") }]}
      title={t("logs.title")}
      action={
        <>
          {navigation.state === "loading" ? <Loader2 className="text-primary animate-spin" /> : null}

          <Select
            value={user}
            onValueChange={(value) => {
              setUser(value);
              setSearchParams((prevParams) => {
                prevParams.set("user", value);
                return prevParams;
              });
            }}
          >
            <SelectTrigger className="w-[160px]">
              <SelectValue placeholder={t("logs.users")} />
            </SelectTrigger>
            <SelectContent>
              {data.admins?.map((admin) => (
                <SelectItem key={admin.id} value={admin.id}>
                  {admin.first_name}&nbsp;{admin.last_name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          <Button
            variant="ghost"
            disabled={!user && !entity}
            onClick={() => {
              setUser("");
              setSearchParams({});
            }}
          >
            <Trans i18nKey="logs.clearSearch"></Trans>
          </Button>
        </>
      }
    >
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>
              <Trans i18nKey="common.date" />
            </TableHead>
            <TableHead>
              <Trans i18nKey="common.time" />
            </TableHead>
            <TableHead>
              <Trans i18nKey="common.admin" />
            </TableHead>
            <TableHead>
              <Trans i18nKey="logs.action" />
            </TableHead>
            <TableHead />
          </TableRow>
        </TableHeader>
        <TableBody>
          {data.logs.items.length ? (
            data.logs.items.map((log) => (
              <TableRow key={log.id}>
                <TableCell className="text-gray-scale-3">
                  {log.date ? (
                    new Intl.DateTimeFormat("fa-IR", { day: "2-digit", month: "long", year: "numeric" }).format(
                      new Date(log.date)
                    )
                  ) : (
                    <Trans i18nKey="common.unknown" />
                  )}
                </TableCell>
                <TableCell className="text-gray-scale-3">
                  {log.date ? (
                    new Intl.DateTimeFormat("fa-IR", { hour: "2-digit", minute: "2-digit", hour12: false }).format(
                      new Date(log.date)
                    )
                  ) : (
                    <Trans i18nKey="common.unknown" />
                  )}
                </TableCell>
                <TableCell className="text-gray-scale-1">
                  {log.user.first_name}&nbsp;{log.user.last_name}
                </TableCell>
                <TableCell className="text-gray-scale-1">
                  <Trans i18nKey={`logs.userActions.${log.action}`} />
                </TableCell>
                <TableCell>
                  <NavLink className={buttonVariants({ variant: "secondary", size: "icon" })} to={`/logs/${log.id}`}>
                    {({ isPending }) => (isPending ? <Loader2 className="animate-spin" /> : <ChevronLeft />)}
                  </NavLink>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow className="text-center text-gray-scale-4">
              <TableCell />
              <TableCell />
              <TableCell>
                <Trans i18nKey="common.isEmpty" />
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          )}
        </TableBody>
      </Table>
      {data.logs.items.length ? (
        <Pagination className="mt-4" currentPage={data.logs.page} path="/logs" totalPage={data.logs.total_pages} />
      ) : null}
    </Page>
  );
}
