import { ORDER_STATUS } from "@/consts/order.const";
import { TOrder } from "@/types/order";
import {
  CheckCheck,
  ChevronLeft,
  CircleAlert,
  CreditCard,
  Hourglass,
  Loader2,
  MessageCircleWarning,
  Package,
  SquareArrowDown,
  SquareCheckBig,
} from "lucide-react";
import { Trans } from "react-i18next";
import { NavLink } from "react-router-dom";
import { buttonVariants } from "./ui/button";

import { addCommas } from "@persian-tools/persian-tools";
import _ from "lodash";
type TProps = {
  data: TOrder;
};

export default function CardOrder(props: TProps) {
  const { data } = props;

  const STATUS_LABEL = {
    [ORDER_STATUS.WAITING_FOR_PAYMENT]: (
      <div className="text-amber-500 flex items-center gap-1 text-sm border-amber-500 border-r-2 p-2.5 flex-1 bg-white/5">
        <Hourglass size={16} />
        <span>
          <Trans i18nKey="orders.status.waitingForPayment" />
        </span>
      </div>
    ),
    [ORDER_STATUS.PAID]: (
      <div className="text-green-600 flex items-center gap-1 text-sm border-green-600 border-r-2 p-2.5 flex-1 bg-white/5">
        <SquareCheckBig size={16} />
        <span>
          <Trans i18nKey="orders.status.paid" />
        </span>
      </div>
    ),
    [ORDER_STATUS.SENDING]: (
      <div className="text-blue-600 flex items-center gap-1 text-sm border-blue-600 border-r-2 p-2.5 flex-1 bg-white/5">
        <Package size={16} />
        <span>
          <Trans i18nKey="orders.status.sending" />
        </span>
      </div>
    ),
    [ORDER_STATUS.DELIVERED]: (
      <div className="text-violet-400 flex items-center gap-1 text-sm border-violet-400 border-r-2 p-2.5 flex-1 bg-white/5">
        <CheckCheck size={16} />
        <span>
          <Trans i18nKey="orders.status.delivered" />
        </span>
      </div>
    ),
    [ORDER_STATUS.CANCELED]: (
      <div className="text-red-600 flex items-center gap-1 text-sm border-red-600 border-r-2 p-2.5 flex-1 bg-white/5">
        <CircleAlert size={16} />
        <span>
          <Trans i18nKey="orders.status.canceled" />
        </span>
      </div>
    ),
    [ORDER_STATUS.PENDING_FOR_RETURN]: (
      <div className="text-yellow-400 flex items-center gap-1 text-sm border-yellow-400 border-r-2 p-2.5 flex-1 bg-white/5">
        <MessageCircleWarning size={16} />
        <span>
          <Trans i18nKey="orders.status.pendingForReturn" />
        </span>
      </div>
    ),
    [ORDER_STATUS.PENDING_FOR_WITHDRAWAL]: (
      <div className="text-orange-400 flex items-center gap-1 text-sm border-orange-400 border-r-2 p-2.5 flex-1 bg-white/5">
        <CreditCard size={16} />
        <span>
          <Trans i18nKey="orders.status.pendingForWithdrawal" />
        </span>
      </div>
    ),
    [ORDER_STATUS.RETURNED]: (
      <div className="text-lime-600 flex items-center gap-1 text-sm border-lime-600 border-r-2 p-2.5 flex-1 bg-white/5">
        <SquareArrowDown size={16} />
        <span>
          <Trans i18nKey="orders.status.returned" />
        </span>
      </div>
    ),
  };

  return (
    <div className="p-4 bg-gray-scale-5">
      <div className="flex items-center gap-2">
        {STATUS_LABEL[data.status.status]}
        <NavLink to={`/orders/${data.id}`} className={buttonVariants({ variant: "secondary", size: "icon" })}>
          {({ isPending }) => (isPending ? <Loader2 className="animate-spin" /> : <ChevronLeft />)}
        </NavLink>
      </div>
      <div className="flex items-center py-4 text-sm border-b border-white/10">
        <div className="flex-1 flex items-center gap-2">
          {/* Date Order */}
          <div>
            <Trans
              i18nKey="orders.orderDate"
              components={[<span className="text-gray-scale-4" />]}
              values={{
                date: data.date
                  ? new Intl.DateTimeFormat("fa-IR", { year: "numeric", month: "long", day: "numeric" }).format(
                      new Date(data.date)
                    )
                  : "----",
              }}
            />
          </div>

          {/* Oder Code */}
          <div>
            <span className="text-gray-scale-4 font-dana">
              <Trans i18nKey="orders.orderCode" />
            </span>
            &nbsp;<span className="font-montserrat font-bold">{data.custom_id}</span>
          </div>
          {/* Oder Total */}
          <div>
            <Trans
              i18nKey="orders.orderTotal"
              components={[<span className="text-gray-scale-4" />]}
              values={{
                total: addCommas(data.total_cost ?? "0"),
              }}
            />
          </div>
        </div>
        {!_.isEmpty(data.personal_info) ? (
          <div className="flex flex-col text-sm gap-1 text-gray-400 text-left">
            <span>
              {data.personal_info.first_name}&nbsp;{data.personal_info.last_name}
            </span>
            <span>{data.personal_info.phone_number}</span>
          </div>
        ) : (
          <span className="text-gray-400">
            <Trans i18nKey="common.unknown" />
          </span>
        )}
      </div>
      <div className="mt-4 flex items-center gap-2">
        {data.products.map((product) => (
          <a href={import.meta.env.VITE_SHOP_BASE_URL + "/product/" + product.slug} target="_blank" key={product.id}>
            <img className="w-16 bg-white" src={product.image_url} alt={`${product.brand.name.fa} ${product.name}`} />
          </a>
        ))}
      </div>
    </div>
  );
}
