import { StaticsAPI } from "@/api";
import Page from "@/components/page";
import { Button } from "@/components/ui/button";
import { Dialog, DialogClose, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "@/components/ui/use-toast";
import { TFAQContent } from "@/types/statics";
import { zodResolver } from "@hookform/resolvers/zod";
import { Edit2, Trash2 } from "lucide-react";
import React from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useLoaderData, useRevalidator } from "react-router-dom";
import { z } from "zod";

export default function FAQ() {
  const data = useLoaderData() as TFAQContent | null;
  const { t } = useTranslation();
  const revalidator = useRevalidator();
  const [isOpen, setIsOpen] = React.useState(false);
  const [deleting, setDeleting] = React.useState("");

  const formSchema = z.object({
    answer_fa: z.string().min(1, { message: t("common.isRequired") }),
    answer_en: z.string().min(1, { message: t("common.isRequired") }),
    question_fa: z.string().min(1, { message: t("common.isRequired") }),
    question_en: z.string().min(1, { message: t("common.isRequired") }),
    id: z.string().optional(),
    dialogTitle: z.string().optional(),
  });

  const getDefaultValues = () => {
    return {
      dialogTitle: t("faq.addNewFaq"),
      question_fa: "",
      question_en: "",
      answer_fa: "",
      answer_en: "",
      id: "",
    };
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: getDefaultValues(),
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      if (values.id) {
        const resp = await StaticsAPI.updateFAQById(values.id, {
          answer: { fa: values.answer_fa, en: values.answer_en },
          question: { fa: values.question_fa, en: values.question_en },
        });
        toast({ description: resp.data.data.message.fa });
      } else {
        const resp = await StaticsAPI.createNewFAQ({
          answer: { fa: values.answer_fa, en: values.answer_en },
          question: { fa: values.question_fa, en: values.question_en },
        });
        toast({ description: resp.data.data.message.fa });
      }
      revalidator.revalidate();
      setIsOpen(false);
      form.reset(getDefaultValues());
    } catch (err) {
      toast({ description: (err as Error).message, variant: "destructive" });
    }
  };

  const onDelete = async (id: string) => {
    try {
      setDeleting(id);
      const resp = await StaticsAPI.deleteFAQById(id);
      revalidator.revalidate();
      toast({ description: resp.data.data.message.fa });
    } catch (err) {
      toast({ description: (err as Error).message, variant: "destructive" });
    } finally {
      setDeleting("");
    }
  };

  // TODO: Implement Error Page
  if (!data) return <div>Error</div>;

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          form.reset(getDefaultValues());
        }
        setIsOpen(open);
      }}
    >
      <Page
        breadcrumbs={[
          { label: t("sidebar.home"), link: "/" },
          { label: t("website.title"), link: "/website" },
          { label: t("faq.title") },
        ]}
        title={t("faq.title")}
        action={
          <DialogTrigger asChild>
            <Button>
              <Trans i18nKey="common.addNew" />
            </Button>
          </DialogTrigger>
        }
      >
        {/* FAQ List */}
        <div className="flex flex-col gap-4">
          {data.faq_list.length ? (
            data.faq_list.map((item) => (
              <div key={item.id} className="bg-gray-scale-5 flex">
                <div className="flex-1 space-y-2 p-4">
                  <div>
                    <p>{item.question.fa}</p>
                    <p className="text-sm text-gray-scale-4 dir-ltr">{item.question.en}</p>
                  </div>

                  <div>
                    <p>{item.answer.fa}</p>
                    <p className="text-sm text-gray-scale-4 dir-ltr">{item.answer.en}</p>
                  </div>
                </div>
                <div className="flex flex-col justify-center gap-1 pl-2">
                  <DialogTrigger asChild>
                    <Button
                      size="icon"
                      variant="ghost"
                      onClick={() => {
                        form.reset({
                          question_fa: item.question.fa,
                          question_en: item.question.en,
                          answer_fa: item.answer.fa,
                          answer_en: item.answer.en,
                          id: item.id,
                        });
                      }}
                    >
                      <Edit2 size={16} />
                    </Button>
                  </DialogTrigger>
                  <Button
                    onClick={() => onDelete(item.id)}
                    loading={deleting === item.id}
                    size="icon"
                    variant="ghost-destructive"
                  >
                    <Trash2 size={20} />
                  </Button>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center text-gray-scale-4">
              <Trans i18nKey="common.isEmpty" />
            </div>
          )}
        </div>

        {/* FAQ Form */}
        <DialogContent className="max-w-4xl">
          <DialogHeader>
            <DialogTitle>{form.getValues("dialogTitle")}</DialogTitle>
          </DialogHeader>
          <Form {...form}>
            <form className="grid grid-cols-4 gap-4" onSubmit={form.handleSubmit(onSubmit)}>
              <FormField
                control={form.control}
                name="question_fa"
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormLabel>
                      <Trans i18nKey="faq.questionFa" />
                    </FormLabel>
                    <FormControl>
                      <Input placeholder={t("faq.questionFa")} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="question_en"
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormLabel>
                      <Trans i18nKey="faq.questionEn" />
                    </FormLabel>
                    <FormControl>
                      <Input
                        className="text-left dir-ltr font-montserrat placeholder:font-dana placeholder:text-right"
                        placeholder={t("faq.questionEn")}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="answer_fa"
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormLabel>
                      <Trans i18nKey="faq.answerFa" />
                    </FormLabel>
                    <FormControl>
                      <Textarea className="resize-none" placeholder={t("faq.answerFa")} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="answer_en"
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormLabel>
                      <Trans i18nKey="faq.answerEn" />
                    </FormLabel>
                    <FormControl>
                      <Textarea
                        className="resize-none text-left dir-ltr font-montserrat placeholder:font-dana placeholder:text-right"
                        placeholder={t("faq.answerEn")}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="flex justify-end col-span-4 gap-2">
                <DialogClose asChild>
                  <Button variant="outline" onClick={() => form.reset(getDefaultValues())}>
                    <Trans i18nKey="common.cancel" />
                  </Button>
                </DialogClose>
                <Button onClick={form.handleSubmit(onSubmit, console.log)} loading={form.formState.isSubmitting}>
                  <Trans i18nKey="common.submit" />
                </Button>
              </div>
            </form>
          </Form>
        </DialogContent>
      </Page>
    </Dialog>
  );
}
