import { BrandAPI, CategoryAPI, ModelAPI, ProductAPI, StaticsAPI, SubcategoryAPI } from "@/api";
import { PRODUCT_SEARCH_SORTS } from "@/consts/product.const";
import { getProfile, mapPermissionsToAllowedCategoryId } from "@/lib/utils";
import { TProductQuery } from "@/types/product";
import { TSliderName } from "@/types/slider";
import { TSubcategory } from "@/types/subcategory";
import _ from "lodash";
import qs from "qs";
import { LoaderFunctionArgs } from "react-router-dom";

export default async function loader({ params, request }: LoaderFunctionArgs) {
  const { name } = params as { name: TSliderName };
  const url = new URL(request.url);
  const profile = getProfile();
  const allowedCategories = mapPermissionsToAllowedCategoryId(profile?.permissions || []);
  const queryString: Partial<{
    query: string;
    page: number;
    limit: number;
    category: string;
    subcategory: string;
    brand: string;
  }> = qs.parse(url.searchParams.toString());

  const filters = JSON.parse(
    JSON.stringify({
      brand: queryString.brand as string,
      category: (queryString.category as string)?.length ? [queryString.category as string] : allowedCategories,
      subcategory: queryString.subcategory as string,
    })
  );
  const query: TProductQuery = {
    pagination: { page: Number(queryString.page) || 1, limit: Number(queryString.limit) || 12 },
    query: queryString.query as string,
    sorting: PRODUCT_SEARCH_SORTS.CREATED_AT_DSC,
    filter: _.isEmpty(filters) ? undefined : filters,
  };

  try {
    const resp = await Promise.all([
      ProductAPI.getList(query),
      CategoryAPI.getList(),
      BrandAPI.getList({ page: 1, limit: 5000 }),
      ModelAPI.getList(),
      StaticsAPI.getSlidersByName(name),
    ]);

    let subs: TSubcategory[] = [];

    if (query.filter?.category?.length) {
      const resp = await SubcategoryAPI.getListByCategoryId(query.filter!.category[0]);
      subs = resp.data.data.items;
    }

    return {
      products: resp[0].data.data,
      categories: resp[1].data.data.items.filter((category) => allowedCategories.includes(category.id)),
      subcategories: subs,
      brands: resp[2].data.data.items,
      models: resp[3].data.data.items,
      slider: resp[4].data.data,
    };
  } catch (err) {
    return null;
  }
}
