import { API } from "@/lib/axios";
import { TTranslatedText, TUsersList } from "@/types/general";
import { TUserAddress, TUserDetailed, TUserListQuery, TUserNewAddress, TUserProfile } from "@/types/user";
import { AxiosResponse } from "axios";

const ENTITY = "/users";

export const getList = async (query: TUserListQuery): Promise<AxiosResponse<{ data: TUsersList }>> =>
  API.post(`${ENTITY}/list`, { ...query, filter_status: [] });

export const getProfileById = async (id: string): Promise<AxiosResponse<{ data: TUserDetailed }>> =>
  API.get(`${ENTITY}/profile/${id}`);

export const getAddressesById = async (id: string): Promise<AxiosResponse<{ data: { items: TUserAddress[] } }>> =>
  API.get(`${ENTITY}/address/list/${id}`);

export const deleteUserAddressById = async (
  id: string
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> => API.delete(`${ENTITY}/address/delete/${id}`);

export const createUserAddress = async (
  id: string,
  address: TUserNewAddress
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.post(`${ENTITY}/address/add`, { user_id: id, ...address });

export const updateUserAddressById = async (
  id: string,
  address: TUserNewAddress
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.patch(`${ENTITY}/address/edit`, { ...address, address_id: id });

export const updateUser = async (data: TUserProfile): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.patch(`${ENTITY}/update`, data);
