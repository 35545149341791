import { cn } from "@/lib/utils";
import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useSearchParams } from "react-router-dom";
import { useDebounce } from "react-use";
import { Input } from "./ui/input";

export type TListFilter = {
  id: string | null;
  label: string;
  count: number;
};

type TProps = {
  children: React.ReactNode;
  filters?: TListFilter[];
  actions?: React.ReactNode;
  actionsClassNames?: string;
  label?: React.ReactNode;
  classes?: {
    header?: string;
  };
};

export default function ListData(props: TProps) {
  const { children, filters, actions, actionsClassNames, classes, label } = props;
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = React.useState(searchParams.get("query") ?? "");

  const getQueryedURL = (key: string | null, value: string | null) => {
    const params = new URLSearchParams(searchParams);
    if (!key) return params.toString();

    if (!value) {
      params.delete(key);
      return params.toString();
    }
    params.set("page", "1");
    params.set(key, value);

    return params.toString();
  };

  useDebounce(
    () => {
      if (query.length) {
        setSearchParams((prev) => {
          prev.set("query", query);
          return prev;
        });
      } else {
        setSearchParams((prev) => {
          prev.delete("query");
          return prev;
        });
      }
    },
    300,
    [query]
  );

  return (
    <div>
      {label ? <div className="mb-4">{label}</div> : null}
      <div className={cn("flex items-center gap-2 justify-between mb-4", classes?.header)}>
        <Input
          placeholder={t("orders.search")}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          className="max-w-lg"
        />

        <div className={actionsClassNames}>{actions}</div>
      </div>
      {filters?.length ? (
        <div className="flex items-center overflow-x-auto mb-4">
          {filters.map((status) => (
            <NavLink
              end
              to={`/orders?${getQueryedURL("status", status.id)}`}
              key={status.id}
              className={({ isActive }) =>
                cn("px-8 py-2 bg-gray-scale-5 text-nowrap scroll-m-1 text-sm", {
                  "bg-white/25 text-primary": status.id === searchParams.get("status") && isActive,
                })
              }
            >
              {status.label}&nbsp;({status.count})
            </NavLink>
          ))}
        </div>
      ) : null}
      {children}
    </div>
  );
}
