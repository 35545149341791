import { API } from "@/lib/axios";
import { TTagsList, TTranslatedText } from "@/types/general";
import { TNewTag, TTagDetailed, TTagNewFAQItem } from "@/types/tag";
import { AxiosResponse } from "axios";
import _omit from "lodash/omit";

const ENTITY = "/tag";

export const getList = async (query: {
  page: number;
  limit: number;
  query?: string;
  category?: string;
  subcategory?: string;
}): Promise<AxiosResponse<{ data: TTagsList }>> =>
  API.post(`${ENTITY}/list`, {
    pagination: { page: query.page, limit: query.limit },
    filters: {
      query: query.query,
      category: query.category,
      subcategory: query.subcategory,
    },
  });

export const deleteById = async (id: string): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.delete(`${ENTITY}/${id}`);

export const addQAById = async (
  tagId: string,
  data: TTagNewFAQItem
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.post(`${ENTITY}/faq-list/add`, { tag_id: tagId, ...data });

export const deleteQAById = async (
  id: string,
  tagId: string
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.delete(`${ENTITY}/faq-list/delete`, { data: { tag_id: tagId, id } });

export const updateQAbyId = async (
  faqId: string,
  tagId: string,
  data: { question: TTranslatedText; answer: TTranslatedText }
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.patch(`${ENTITY}/faq-list/update`, { tag_id: tagId, id: faqId, ...data });

export const create = async (data: TNewTag): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.post(ENTITY, data);

export const update = async (
  id: string,
  data: TNewTag
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> => {
  const d = _omit(data, "sub_category_id");
  return API.patch(ENTITY, { id, ...d });
};

export const getById = async (id: string): Promise<AxiosResponse<{ data: TTagDetailed }>> => API.get(`${ENTITY}/${id}`);
