import Page from "@/components/page";
import { SLIDERS_NAMES } from "@/consts/slider.const";
import { TSliderName } from "@/types/slider";
import { Trans, useTranslation } from "react-i18next";
import { Link, useLoaderData } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

type TCatItem = { title: TSliderName; cat: string; sub: string };

export default function SliderManagement() {
  const data = useLoaderData() as Record<TSliderName, TSliderName> | null;

  const { t } = useTranslation();

  const VALUE: Record<TSliderName, TCatItem> = {
    // bass
    [SLIDERS_NAMES.BASS_GUITARS]: {
      title: SLIDERS_NAMES.BASS_GUITARS,
      cat: "649f178bdb04e1fd6acf19c7",
      sub: "649f178bdb04e1fd6acf19fd",
    },
    [SLIDERS_NAMES.BASS_AMPLIFIERS]: {
      title: SLIDERS_NAMES.BASS_AMPLIFIERS,
      cat: "649f178bdb04e1fd6acf19c7",
      sub: "649f178bdb04e1fd6acf1a0b",
    },
    [SLIDERS_NAMES.BASS_PEDALS_MULTI_EFFECTS]: {
      title: SLIDERS_NAMES.BASS_PEDALS_MULTI_EFFECTS,
      cat: "649f178bdb04e1fd6acf19c7",
      sub: "649f178bdb04e1fd6acf1a04",
    },

    // guitar
    [SLIDERS_NAMES.ACOUSTIC_GUITARS]: {
      title: SLIDERS_NAMES.ACOUSTIC_GUITARS,
      cat: "649f178bdb04e1fd6acf19c3",
      sub: "649f178bdb04e1fd6acf19e1",
    },
    [SLIDERS_NAMES.CLASSICAL_GUITARS]: {
      title: SLIDERS_NAMES.CLASSICAL_GUITARS,
      cat: "649f178bdb04e1fd6acf19c3",
      sub: "649f178bdb04e1fd6acf19e8",
    },
    [SLIDERS_NAMES.ELECTRIC_GUITARS]: {
      title: SLIDERS_NAMES.ELECTRIC_GUITARS,
      cat: "649f178bdb04e1fd6acf19c3",
      sub: "649f178bdb04e1fd6acf19da",
    },
    [SLIDERS_NAMES.GUITAR_AMPLIFIERS]: {
      title: SLIDERS_NAMES.GUITAR_AMPLIFIERS,
      cat: "649f178bdb04e1fd6acf19c3",
      sub: "649f178bdb04e1fd6acf19f6",
    },
    [SLIDERS_NAMES.PEDALS_MULTI_EFFECTS]: {
      title: SLIDERS_NAMES.PEDALS_MULTI_EFFECTS,
      cat: "649f178bdb04e1fd6acf19c3",
      sub: "649f178bdb04e1fd6acf19ef",
    },

    // studio
    [SLIDERS_NAMES.HEADPHONES]: {
      title: SLIDERS_NAMES.HEADPHONES,
      cat: "649f178bdb04e1fd6acf19cb",
      sub: "649f178bdb04e1fd6acf1a27",
    },
    [SLIDERS_NAMES.MICROPHONE]: {
      title: SLIDERS_NAMES.MICROPHONE,
      cat: "649f178bdb04e1fd6acf19cb",
      sub: "649f178bdb04e1fd6acf1a43",
    },
    [SLIDERS_NAMES.AUDIO_INTERFACES]: {
      title: SLIDERS_NAMES.AUDIO_INTERFACES,
      cat: "649f178bdb04e1fd6acf19cb",
      sub: "649f178bdb04e1fd6acf1a12",
    },
    [SLIDERS_NAMES.AUDIO_INTERFACES_TEST]: {
      title: SLIDERS_NAMES.AUDIO_INTERFACES_TEST,
      cat: "649f178bdb04e1fd6acf19cb",
      sub: "649f178bdb04e1fd6acf1a12",
    },
    [SLIDERS_NAMES.MONITOR_SPEAKERS]: {
      title: SLIDERS_NAMES.MONITOR_SPEAKERS,
      cat: "649f178bdb04e1fd6acf19cb",
      sub: "649f178bdb04e1fd6acf1a19",
    },

    // keyboard
    [SLIDERS_NAMES.MIDI_CONTROLLERS_KEYBOARD]: {
      title: SLIDERS_NAMES.MIDI_CONTROLLERS_KEYBOARD,
      cat: "649f178bdb04e1fd6acf19cf",
      sub: "649f178bdb04e1fd6acf1a20",
    },

    // accessories
    [SLIDERS_NAMES.GUITAR_BASS_ACCESSORIES]: {
      title: SLIDERS_NAMES.GUITAR_BASS_ACCESSORIES,
      cat: "649f178bdb04e1fd6acf19d3",
      sub: "649f178bdb04e1fd6acf1a35",
    },
    [SLIDERS_NAMES.GUITAR_BASS_SPARE_PARTS]: {
      title: SLIDERS_NAMES.GUITAR_BASS_SPARE_PARTS,
      cat: "649f178bdb04e1fd6acf19d3",
      sub: "649f178bdb04e1fd6acf1a3c",
    },
    [SLIDERS_NAMES.STRINGS]: {
      title: SLIDERS_NAMES.STRINGS,
      cat: "649f178bdb04e1fd6acf19d3",
      sub: "649f178bdb04e1fd6acf1a2e",
    },

    // redundant - not used
    [SLIDERS_NAMES.POPULAR_GUITARS]: {
      title: SLIDERS_NAMES.POPULAR_GUITARS,
      cat: "",
      sub: "",
    },
    [SLIDERS_NAMES.POPULAR_STUDIO]: {
      title: SLIDERS_NAMES.POPULAR_STUDIO,
      cat: "",
      sub: "",
    },
    [SLIDERS_NAMES.DISCOUNT_GUITARS]: {
      title: SLIDERS_NAMES.DISCOUNT_GUITARS,
      cat: "",
      sub: "",
    },
    [SLIDERS_NAMES.DISCOUNT_STUDIO]: {
      title: SLIDERS_NAMES.DISCOUNT_STUDIO,
      cat: "",
      sub: "",
    },
    [SLIDERS_NAMES.NEWEST_GUITARS]: {
      title: SLIDERS_NAMES.NEWEST_GUITARS,
      cat: "",
      sub: "",
    },
    [SLIDERS_NAMES.NEWEST_STUDIO]: {
      title: SLIDERS_NAMES.NEWEST_STUDIO,
      cat: "",
      sub: "",
    },
  };

  const getURL = (name: TSliderName) => {
    console.log(VALUE, name);
    
    return `${VALUE[name].title}?category=${VALUE[name].cat}&subcategory=${VALUE[name].sub}`;
  };
  //  TODO: Implement Error Page
  if (!data) return <div>Error</div>;

  return (
    <Page
      breadcrumbs={[
        { label: t("sidebar.home"), link: "/" },
        {
          label: t("website.title"),
          link: "/website",
        },
        { label: t("sliders.title") },
      ]}
      title={t("sliders.title")}
    >
      <div className="grid grid-cols-2 gap-4 mb-8">
        <Link
          key={uuidv4()}
          to={data.popular_studio}
          className="h-[60px] bg-gray-scale-6 hover:bg-gray-scale-5 flex items-center justify-center hover:text-primary"
        >
          <Trans i18nKey={`sliders.sections.${data.popular_studio}`} />
        </Link>
        <Link
          key={uuidv4()}
          to={data.popular_guitars}
          className="h-[60px] bg-gray-scale-6 hover:bg-gray-scale-5 flex items-center justify-center hover:text-primary"
        >
          <Trans i18nKey={`sliders.sections.${data.popular_guitars}`} />
        </Link>
      </div>
      <span className="text-lg inline-block mb-2">
        <Trans i18nKey="sliders.guitars" />
      </span>
      <div className="grid grid-cols-2 gap-4 mb-8">
        <Link
          key={uuidv4()}
          to={data.discount_guitars}
          className="h-[60px] bg-gray-scale-6 hover:bg-gray-scale-5 flex items-center justify-center hover:text-primary"
        >
          <Trans i18nKey={`sliders.sections.${data.discount_guitars}`} />
        </Link>
        <Link
          key={uuidv4()}
          to={data.newest_guitars}
          className="h-[60px] bg-gray-scale-6 hover:bg-gray-scale-5 flex items-center justify-center hover:text-primary"
        >
          <Trans i18nKey={`sliders.sections.${data.newest_guitars}`} />
        </Link>
      </div>

      <span className="text-lg inline-block mb-2">
        <Trans i18nKey="sliders.studio" />
      </span>
      <div className="grid grid-cols-2 gap-4 mb-8">
        <Link
          key={uuidv4()}
          to={data.discount_studio}
          className="h-[60px] bg-gray-scale-6 hover:bg-gray-scale-5 flex items-center justify-center hover:text-primary"
        >
          <Trans i18nKey={`sliders.sections.${data.discount_studio}`} />
        </Link>
        <Link
          key={uuidv4()}
          to={data.newest_studio}
          className="h-[60px] bg-gray-scale-6 hover:bg-gray-scale-5 flex items-center justify-center hover:text-primary"
        >
          <Trans i18nKey={`sliders.sections.${data.newest_studio}`} />
        </Link>
      </div>

      <span className="text-2xl text-gray-scale-4 inline-block mb-4">
        <Trans i18nKey="sliders.categories" />
      </span>
      {/* guitars */}
      <div className="mb-8">
        <span className="text-lg inline-block mb-2">
          <Trans i18nKey="sliders.sections.guitar" />
        </span>
        <div className="grid grid-cols-2 gap-4">
          <Link
            key={uuidv4()}
            to={getURL(data.electric_guitars)}
            className="h-[60px] bg-gray-scale-6 hover:bg-gray-scale-5 flex items-center justify-center hover:text-primary"
          >
            <Trans i18nKey={`sliders.sections.${data.electric_guitars}`} />
          </Link>
          <Link
            key={uuidv4()}
            to={getURL(data.acoustic_guitars)}
            className="h-[60px] bg-gray-scale-6 hover:bg-gray-scale-5 flex items-center justify-center hover:text-primary"
          >
            <Trans i18nKey={`sliders.sections.${data.acoustic_guitars}`} />
          </Link>
          <Link
            key={uuidv4()}
            to={getURL(data.classical_guitars)}
            className="h-[60px] bg-gray-scale-6 hover:bg-gray-scale-5 flex items-center justify-center hover:text-primary"
          >
            <Trans i18nKey={`sliders.sections.${data.classical_guitars}`} />
          </Link>
          <Link
            key={uuidv4()}
            to={getURL(data.pedals_multi_effects)}
            className="h-[60px] bg-gray-scale-6 hover:bg-gray-scale-5 flex items-center justify-center hover:text-primary"
          >
            <Trans i18nKey={`sliders.sections.${data.pedals_multi_effects}`} />
          </Link>
          <Link
            key={uuidv4()}
            to={getURL(data.guitar_amplifiers)}
            className="h-[60px] bg-gray-scale-6 hover:bg-gray-scale-5 flex items-center justify-center hover:text-primary"
          >
            <Trans i18nKey={`sliders.sections.${data.guitar_amplifiers}`} />
          </Link>
        </div>
      </div>

      {/* Bass */}
      <div className="mb-8">
        <span className="text-lg inline-block mb-2">
          <Trans i18nKey="sliders.sections.bass" />
        </span>
        <div className="grid grid-cols-2 gap-4">
          <Link
            key={uuidv4()}
            to={getURL(data.bass_guitars)}
            className="h-[60px] bg-gray-scale-6 hover:bg-gray-scale-5 flex items-center justify-center hover:text-primary"
          >
            <Trans i18nKey={`sliders.sections.${data.bass_guitars}`} />
          </Link>
          <Link
            key={uuidv4()}
            to={getURL(data.bass_pedals_multi_effects)}
            className="h-[60px] bg-gray-scale-6 hover:bg-gray-scale-5 flex items-center justify-center hover:text-primary"
          >
            <Trans i18nKey={`sliders.sections.${data.bass_pedals_multi_effects}`} />
          </Link>
          <Link
            key={uuidv4()}
            to={getURL(data.bass_amplifiers)}
            className="h-[60px] bg-gray-scale-6 hover:bg-gray-scale-5 flex items-center justify-center hover:text-primary"
          >
            <Trans i18nKey={`sliders.sections.${data.bass_amplifiers}`} />
          </Link>
        </div>
      </div>

      {/* Studio */}
      <div className="mb-8">
        <span className="text-lg inline-block mb-2">
          <Trans i18nKey="sliders.sections.studio" />
        </span>
        <div className="grid grid-cols-2 gap-4">
          <Link
            key={uuidv4()}
            to={getURL(data.audio_interfaces)}
            className="h-[60px] bg-gray-scale-6 hover:bg-gray-scale-5 flex items-center justify-center hover:text-primary"
          >
            <Trans i18nKey={`sliders.sections.${data.audio_interfaces}`} />
          </Link>
          <Link
            key={uuidv4()}
            to={getURL(data.monitor_speakers)}
            className="h-[60px] bg-gray-scale-6 hover:bg-gray-scale-5 flex items-center justify-center hover:text-primary"
          >
            <Trans i18nKey={`sliders.sections.${data.monitor_speakers}`} />
          </Link>
          <Link
            key={uuidv4()}
            to={getURL(data.headphones)}
            className="h-[60px] bg-gray-scale-6 hover:bg-gray-scale-5 flex items-center justify-center hover:text-primary"
          >
            <Trans i18nKey={`sliders.sections.${data.headphones}`} />
          </Link>
          <Link
            key={uuidv4()}
            to={getURL(data.microphone)}
            className="h-[60px] bg-gray-scale-6 hover:bg-gray-scale-5 flex items-center justify-center hover:text-primary"
          >
            <Trans i18nKey={`sliders.sections.${data.microphone}`} />
          </Link>
        </div>
      </div>

      {/* Keys */}
      <div className="mb-8">
        <span className="text-lg inline-block mb-2">
          <Trans i18nKey="sliders.sections.keys" />
        </span>
        <div className="grid grid-cols-2 gap-4">
          <Link
            key={uuidv4()}
            to={getURL(data.midi_controllers_keyboard)}
            className="h-[60px] bg-gray-scale-6 hover:bg-gray-scale-5 flex items-center justify-center hover:text-primary"
          >
            <Trans i18nKey={`sliders.sections.${data.midi_controllers_keyboard}`} />
          </Link>
        </div>
      </div>

      {/* Accessories */}
      <div className="mb-8">
        <span className="text-lg inline-block mb-2">
          <Trans i18nKey="sliders.sections.accessories" />
        </span>
        <div className="grid grid-cols-2 gap-4">
          <Link
            key={uuidv4()}
            to={getURL(data.strings)}
            className="h-[60px] bg-gray-scale-6 hover:bg-gray-scale-5 flex items-center justify-center hover:text-primary"
          >
            <Trans i18nKey={`sliders.sections.${data.strings}`} />
          </Link>
          <Link
            key={uuidv4()}
            to={getURL(data.guitar_bass_accessories)}
            className="h-[60px] bg-gray-scale-6 hover:bg-gray-scale-5 flex items-center justify-center hover:text-primary"
          >
            <Trans i18nKey={`sliders.sections.${data.guitar_bass_accessories}`} />
          </Link>
          <Link
            key={uuidv4()}
            to={getURL(data.guitar_bass_spare_parts)}
            className="h-[60px] bg-gray-scale-6 hover:bg-gray-scale-5 flex items-center justify-center hover:text-primary"
          >
            <Trans i18nKey={`sliders.sections.${data.guitar_bass_spare_parts}`} />
          </Link>
        </div>
      </div>
    </Page>
  );
}
