import { BrandAPI } from "@/api";
import ListData from "@/components/list-data";
import Page from "@/components/page";
import { useAuth } from "@/components/providers/auth-provider";
import { Button, buttonVariants } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";
import { ADMIN_PERMISSIONS } from "@/consts/admin.const";
import { cn, hasPermissions } from "@/lib/utils";
import { TBrand } from "@/types/brand";
import { isAxiosError } from "axios";
import { Edit2, FileText, Loader2, Trash2 } from "lucide-react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, NavLink, useLoaderData, useRevalidator } from "react-router-dom";

export default function ProductsBrands() {
  const data = useLoaderData() as TBrand[];
  const { t } = useTranslation();
  const revalidator = useRevalidator();
  const { profile } = useAuth();

  const [deletingTag, setDeletingTag] = React.useState("");

  const deleteTag = async (id: string) => {
    try {
      setDeletingTag(id);
      const resp = await BrandAPI.deleteById(id);
      revalidator.revalidate();
      toast({
        title: resp.data.data.message.fa,
      });
    } catch (error) {
      if (isAxiosError(error)) {
        toast({
          variant: "destructive",
          description: error.response?.data.message,
        });
        return;
      }
      if (error instanceof Error) {
        toast({
          variant: "destructive",
          description: error.message,
        });
      }
    } finally {
      setDeletingTag("");
    }
  };

  // TODO: Implement pagination
  if (!data) return <div>Error</div>;
  return (
    <Page
      breadcrumbs={[
        { label: t("sidebar.home"), link: "/" },
        { label: t("productsManagement.title"), link: "/products" },
        { label: t("brands.title") },
      ]}
      title={t("brands.title")}
    >
      <ListData
        actions={
          <NavLink
            to="add"
            className={buttonVariants({
              className: { hidden: !hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_BRAND]) },
            })}
          >
            <Trans i18nKey="common.addNew" />
          </NavLink>
        }
      >
        {data.length ? (
          <ul className="grid grid-cols-2 gap-4">
            {data.map((brand) => (
              <li key={brand.id}>
                <div className="flex items-center gap-4 bg-gray-scale-5 p-2">
                  <img className="w-[70px] h-[70px] object-cover bg-black/50" src={brand.logo} />
                  <div className="flex-1">
                    <h3 className="font-montserrat font-bold">{brand.name.en}</h3>
                  </div>
                  <div>
                    <Link
                      to={`/logs?entity=${brand.id}`}
                      className={buttonVariants({ variant: "ghost", size: "icon" })}
                    >
                      <FileText size={18} />
                    </Link>
                    <NavLink
                      to={brand.id}
                      className={buttonVariants({
                        size: "icon",
                        variant: "ghost",
                      })}
                    >
                      {({ isPending }) =>
                        isPending ? <Loader2 className="animate-spin text-primary" size={18} /> : <Edit2 size={18} />
                      }
                    </NavLink>
                    <Button
                      loading={deletingTag === brand.id}
                      onClick={() => deleteTag(brand.id)}
                      size="icon"
                      variant="ghost-destructive"
                      className={cn({ hidden: !hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_BRAND]) })}
                    >
                      <Trash2 size={20} />
                    </Button>
                  </div>
                  <div>
                    <Link
                      to={`/products/list?brand=${brand.id}`}
                      className={buttonVariants({
                        variant: "secondary",
                        className: cn({
                          hidden: !hasPermissions(profile, [ADMIN_PERMISSIONS.READ_PRODUCT]),
                        }),
                      })}
                    >
                      <Trans i18nKey="products.viewProducts" />
                    </Link>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="text-center text-gray-scale-4">
            <Trans i18nKey="common.isEmpty" />
          </div>
        )}
      </ListData>
    </Page>
  );
}
