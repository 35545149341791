import { Button } from "@/components/ui/button";
import { LockKeyhole } from "lucide-react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function Forbidden() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-center h-full space-y-8 mt-16">
      <LockKeyhole size={96} className="text-gray-scale-4" />
      <p className="text-gray-scale-4 text-xl">
        <Trans i18nKey="common.lockText" />
      </p>
      <Button variant="secondary" onClick={() => navigate(-1)}>
        <Trans i18nKey="common.backToPrev" />
      </Button>
    </div>
  );
}
