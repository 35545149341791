import { BrandAPI, CategoryAPI, ColorAPI, ModelAPI, ProductAPI, TagAPI } from "@/api";
import { LoaderFunctionArgs } from "react-router-dom";

export default async function loader({ params }: LoaderFunctionArgs) {
  const { productId } = params as { productId?: string };
  return Promise.all([
    BrandAPI.getList({ page: 1, limit: 500 }),
    CategoryAPI.getList(),
    TagAPI.getList({ page: 1, limit: 500 }),
    ColorAPI.getList(),
    ModelAPI.getList(),
    productId ? ProductAPI.getById(productId) : undefined,
  ])
    .then(([brands, categories, tags, colors, models, product]) => {
      return {
        brands: brands.data.data.items,
        categories: categories.data.data.items,
        tags: tags.data.data.items,
        colors: colors.data.data.items,
        models: models.data.data.items,
        subcategories: [],
        product: product?.data.data ? { ...product?.data.data, name: `new ${product?.data.data.name}` } : undefined,
      };
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
}
