import { API } from "@/lib/axios";
import { TOrderList, TTranslatedText } from "@/types/general";
import { TOrderDetailed, TOrderFilters, TOrderStatus, TOrderStatusCount } from "@/types/order";
import { AxiosResponse } from "axios";

const ENTITY = "/order";

export const getList = async (filters: TOrderFilters): Promise<AxiosResponse<{ data: TOrderList }>> =>
  API.post(`${ENTITY}/list`, {
    pagination: { page: filters.page, limit: filters.limit },
    query: filters.query,
    status: filters.status,
  });

export const getById = async (id: string): Promise<AxiosResponse<{ data: TOrderDetailed }>> =>
  API.get(`${ENTITY}/detail/${id}`);

export const updateStatus = async (
  id: string,
  status: TOrderStatus
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.post(`${ENTITY}/change-status`, { status, order_id: id });

export const updateOrder = async (
  data: Partial<{
    order_id: string;
    address_id: string;
    user_first_name: string;
    user_last_name: string;
    user_national_id: string;
    user_phone_number: string;
    user_email: string;
    product: {
      product_id: string;
      count: number;
    };
  }>
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> => API.patch(`${ENTITY}/update`, data);

export const getStatusCounts = async (): Promise<AxiosResponse<{ data: { items: TOrderStatusCount[] } }>> =>
  API.get(`${ENTITY}/status-count`);
