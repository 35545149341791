import { SubcategoryAPI } from "@/api";
import CategoryForm from "@/components/form-category";
import Page from "@/components/page";
import { toast } from "@/components/ui/use-toast";
import { TNewSubcategory, TSubcategoryDetaield, TSubcategoryFAQItem } from "@/types/subcategory";
import { isAxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useLoaderData, useNavigate, useParams, useRevalidator } from "react-router-dom";

export default function SubcategoryEdit() {
  const data = useLoaderData() as TSubcategoryDetaield | null;
  const { t } = useTranslation();
  const params = useParams() as { categoryId: string; subcategoryId: string };
  const navigate = useNavigate();
  const revalidator = useRevalidator();

  const onSubmitForm = async (subcategory: TNewSubcategory) => {
    try {
      const resp = await SubcategoryAPI.update(params.subcategoryId, subcategory);

      toast({ description: resp.data.data.message.fa });
      navigate(-1);
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          description: err.response?.data.message,
          variant: "destructive",
        });
        return;
      }
      if (err instanceof Error) {
        toast({
          description: err.message,
          variant: "destructive",
        });
      }
    }
  };
  const onAddFAQ = async (data: Omit<TSubcategoryFAQItem, "id">) => {
    try {
      const resp = await SubcategoryAPI.addQAById(params.subcategoryId, data);
      revalidator.revalidate();
      toast({ description: resp.data.data.message.fa });
    } catch (error) {
      toast({ description: (error as Error).message, variant: "destructive" });
    }
  };
  const onDeleteFAQ = async (id: string) => {
    try {
      const resp = await SubcategoryAPI.deleteQAById(id, params.subcategoryId);
      revalidator.revalidate();
      toast({
        description: resp.data.data.message.fa,
      });
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          description: err.response?.data.message,
          variant: "destructive",
        });
        return;
      }
      if (err instanceof Error) {
        toast({
          description: err.message,
          variant: "destructive",
        });
      }
    }
  };
  const onEditFAQ = async (data: TSubcategoryFAQItem) => {
    try {
      const resp = await SubcategoryAPI.updateQAbyId(data.id, params.subcategoryId, {
        question: { fa: data.question.fa, en: data.question.en },
        answer: { fa: data.answer.fa, en: data.answer.en },
      });
      revalidator.revalidate();
      toast({ description: resp.data.data.message.fa });
    } catch (error) {
      toast({ description: (error as Error).message, variant: "destructive" });
    }
  };

  // TODO: Implement Error Page
  if (!data) return <div>Error</div>;

  return (
    <Page
      breadcrumbs={[
        { label: t("sidebar.home"), link: "/" },
        { label: t("productsManagement.title"), link: "/products" },
        { label: t("subcategories.title"), link: "/products/categories/subcategories" },
        { label: t("subcategories.editLabel") },
      ]}
      title={t("subcategories.edit", { title: data.title.fa })}
    >
      <CategoryForm
        initData={data}
        onEditFAQ={onEditFAQ}
        onDeleteFAQ={onDeleteFAQ}
        onSubmitForm={onSubmitForm}
        onAddFAQ={onAddFAQ}
      />
    </Page>
  );
}
