export default {
  title: "مدیریت کاربران",
  userName: "نام کاربر",
  edit: "ویرایش کاربر",
  userPhone: "شماره موبایل",
  registerDate: "تاریخ ثبت‌نام",
  status: "وضعیت",
  statusActive: "فعال",
  statusInactive: "غیرفعال",
  totalUsers: "تعداد کاربران",
  totalUsersInMonth: "تعداد کاربران این ماه",
  totalCustomers: "تعداد کاربران خریدار",
  editUser: "ویرایش کاربر - {{name}}",
  addresses: "آدرس ها",
  address: "{{address}} پلاک {{no}}, طبقه {{floor}} - کد پستی: {{postalCode}}",
  profile: "اطلاعات کاربر",
  userStatus: "وضعیت کاربر",
  emailVerification: "وضعیت اعتبار ایمیل",
  phoneVerification: "وضعیت اعتبار موبایل",
  verified: "تایید شده",
  unverified: "تایید نشده",
  firstName: "نام",
  lastName: "نام خانوادگی",
  nationalCode: "کد ملی",
  email: "ایمیل",
  phone: "شماره تماس",
  memeberDate: "تاریخ عضویت: {{date}}",
  sureUpdate: "آیا از تغییر اطلاعات کاربر اطمینان دارید؟",
  warning: "هشدار",
  showDetail: "مشاهده جزییات",
  addressDialog: {
    newAddress: "افزودن آدرس",
    editAddress: "ویرایش آدرس",
    addressTitle: "عنوان آدرس",
    fullAddress: "آدرس کامل",
    postalCode: "کدپستی",
    floor: "طبقه",
    no: "پلاک",
  },
};
