import CardOrder from "@/components/card-order";
import CountCard from "@/components/card-count";
import ListData, { TListFilter } from "@/components/list-data";
import Page from "@/components/page";
import Pagination from "@/components/pagination";
import { TOrderList } from "@/types/general";
import { TOrderStatus } from "@/types/order";
import { Trans, useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";

export default function OrdersPage() {
  const data = useLoaderData() as { list: TOrderList; counts: Record<TOrderStatus, number> } | null;
  const { t } = useTranslation();

  // TODO: implement Error Page
  if (!data) return <div>Error</div>;

  const filters: TListFilter[] = [
    { count: data.list.total_orders, label: t("orders.status.all"), id: null },
    { count: data.counts["0"], label: t("orders.status.waitingForPayment"), id: "0" },
    { count: data.counts["1"], label: t("orders.status.paid"), id: "1" },
    { count: data.counts["2"], label: t("orders.status.sending"), id: "2" },
    { count: data.counts["3"], label: t("orders.status.delivered"), id: "3" },
    { count: data.counts["4"], label: t("orders.status.canceled"), id: "4" },
    { count: data.counts["5"], label: t("orders.status.pendingForReturn"), id: "5" },
    { count: data.counts["6"], label: t("orders.status.pendingForWithdrawal"), id: "6" },
    { count: data.counts["7"], label: t("orders.status.returned"), id: "7" },
  ];

  return (
    <Page
      breadcrumbs={[{ label: t("sidebar.home"), link: "/" }, { label: t("orders.title") }]}
      title={t("orders.title")}
    >
      <div className="grid grid-cols-4 gap-4 mb-4">
        <CountCard title={t("orders.totalCount")} count={data.list.total_orders} />
        <CountCard title={t("orders.totalMonthCount")} count={data.list.total_orders_of_month} />
        <CountCard title={t("orders.totalSale")} count={data.list.total_sales} />
        <CountCard title={t("orders.totalMonthSale")} count={data.list.total_sales_of_month} />
      </div>
      <ListData filters={filters}>
        {data.list.items.length ? (
          <ul className="flex flex-col gap-4">
            {data.list.items.map((order) => (
              <li key={order.id}>
                <CardOrder data={order} />
              </li>
            ))}
          </ul>
        ) : (
          <div className="text-center font-black text-lg py-8 text-gray-scale-4">
            <Trans i18nKey="common.isEmpty" />
          </div>
        )}
      </ListData>
      {data.list.items.length ? (
        <Pagination
          path={"/orders"}
          currentPage={data.list.pagination.current_page}
          totalPage={data.list.pagination.total_pages}
        />
      ) : null}
    </Page>
  );
}
