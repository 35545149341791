import { cn } from "@/lib/utils";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { Loader2 } from "lucide-react";
import * as React from "react";

const buttonVariants = cva(
  "relative inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 duration-150",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90 [&>.loading]:hover:bg-primary",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90 [&>.loading]:hover:bg-destructive",
        outline: "border border-input bg-background hover:text-primary [&>.loading]:hover:bg-background",
        secondary:
          "bg-white/20 hover:bg-white/15 text-secondary-foreground hover:text-primary [&>.loading]:bg-white/5 [&>.loading]:text-primary",
        ghost: "hover:text-primary",
        "ghost-destructive": "text-destructive hover:text-red-700",
        link: "underline-offset-4 hover:text-primary hover:underline",
        tertiary: "bg-gray-scale-4 text-primary-foreground hover:bg-gray-scale-5 [&>.loading]:hover:bg-gray-scla-4",
        input:
          "bg-gray-scale-5 flex h-10 w-full rounded-md border border-white/10 px-3 py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
  wrapperClass?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, loading, children, wrapperClass: innerClass, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    const type = props.type || "button";
    return (
      <Comp type={type} className={buttonVariants({ variant, size, className })} ref={ref} {...props}>
        {loading && (
          <div className="w-full h-full bg-inherit absolute top-0 right-0 flex items-center justify-center loading">
            <Loader2 className="animate-spin" />
          </div>
        )}
        <div className={cn("flex w-full justify-center gap-2 items-center", { "opacity-0": loading }, innerClass)}>
          {children}
        </div>
      </Comp>
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
