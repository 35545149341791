import React from "react";

type TProps = {
  title: React.ReactNode;
  count: React.ReactNode;
};
export default function CountCard(props: TProps) {
  const { title, count } = props;
  return (
    <div className="bg-gray-scale-5 p-4">
      <h2 className="text-lg mb-12 text-gray-scale-4 text-center">{title}</h2>
      <p className="text-center text-xl text-primary font-bold">{count}</p>
    </div>
  );
}
