import { Link } from "react-router-dom";

type TProps = {
  bgImage: string;
  title: string;
  route: string;
  className?: string;
};

export default function HoverCard(props: TProps) {
  const { title, bgImage, route, className } = props;
  return (
    <Link to={route} className={className}>
      <div
        style={{
          background: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="group w-full h-[250px] bg-[#1f1f1f] flex items-center p-5 cursor-pointer transition-all duration-300 ease-out"
      >
        <span className="text-white text-right text-[28px] group-hover:text-primary transition-all duration-300 ease-out">
          {title}
        </span>
        <div className="h-px w-[40px] bg-white mr-5 group-hover:bg-primary group-hover:w-20 transition-all duration-300 ease-out" />
      </div>
    </Link>
  );
}
