import { AdminsAPI } from "@/api";
import { ADMIN_PERMISSIONS } from "@/consts/admin.const";
import { apiExecByPermission } from "@/lib/utils";
import { TAdminsQuery } from "@/types/admin";
import qs from "qs";
import { LoaderFunctionArgs } from "react-router-dom";

export default async function loader({ request }: LoaderFunctionArgs) {
  try {
    const url = new URL(request.url);
    const queryString: Partial<{ query: string; page: number; limit: number }> = qs.parse(url.searchParams.toString());
    const filters: TAdminsQuery = {
      page: Number(queryString.page) || 1,
      limit: Number(queryString.limit) || 10,
      query: queryString.query as string,
    };

    const resp = await apiExecByPermission([ADMIN_PERMISSIONS.READ_ADMIN], () => AdminsAPI.getList(filters));
    return resp.data.data;
  } catch (err) {
    return null;
  }
}
