import { API } from "@/lib/axios";
import { TCommentsQuery } from "@/types/comment";
import { TCommentsList, TTranslatedText } from "@/types/general";
import { AxiosResponse } from "axios";

const ENTITY = "/product/comments";

export const getList = async (query: TCommentsQuery): Promise<AxiosResponse<{ data: TCommentsList }>> =>
  API.post(`${ENTITY}/list`, {
    pagination: {
      page: query.page,
      limit: query.limit,
    },
    filter: query.status,
  });

export const confirmById = async (id: string): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.patch(`${ENTITY}/confirm`, { id });

export const rejectById = async (id: string): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.patch(`${ENTITY}/reject`, { id });

export const deleteById = async (id: string): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.delete(`${ENTITY}/${id}`);
