export default {
  title: "مدیریت اسلایدر",
  productsList: "لیست محصولات",
  guitars: "گیتار و بیس",
  studio: "استودیو",
  categories: "دسته‌بندی‌ها",
  sections: {
    newest_studio: "تازه ترین ها - استودیو",
    discount_studio: "پیشنهادات ویژه - استودیو",
    newest_guitars: "تازه‌ترین ها - گیتار و بیس",
    discount_guitars: "پیشنهادات ویژه - گیتار و بیس",
    popular_studio: "برگزیده محصولات استودیویی",
    popular_guitars: "برگزیده گیتار و بیس",
    electric_guitars: "گیتار الکتریک",
    bass_guitars: "گیتار بیس",
    bass_amplifiers: "آمپلی فایر بیس",
    acoustic_guitars: "گیتار آکوستیک",
    guitar_amplifiers: "آمپلی فایر گیتار",
    classical_guitars: "گیتار کلاسیک",
    monitor_speakers: "اسپیکر مانیتورینگ",
    midi_controllers_keyboard: "میدی کنترلر و کیبورد",
    headphones: "هدفون",
    audio_interfaces: "کارت صدا",
    audio_interfaces_test: "کارت صدا",
    strings: "سیم گیتار و بیس",
    guitar_bass_accessories: "تجهیزات جانبی گیتار و بیس",
    guitar_bass_spare_parts: "قطعات گیتار و بیس",
    microphone: "میکروفون",
    bass_pedals_multi_effects: "پدال و مولی افکت بیس",
    pedals_multi_effects: "پدال و مولتی افکت",
    guitar: "گیتار",
    bass: "بیس",
    studio: "استودیو",
    keys: "کیبورد و پیانو",
    accessories: "تجهیزات جانبی",
  },
  add6Latest: "افزودن آخرین محصولات",
  edit: "ویرایش اسلایدر",
};
