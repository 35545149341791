import PriceProductCard from "@/components/card-product-price";
import ListData from "@/components/list-data";
import Page from "@/components/page";
import Pagination from "@/components/pagination";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { TBrand } from "@/types/brand";
import { TCategory } from "@/types/category";
import { TPaginatedList } from "@/types/general";
import { TProduct } from "@/types/product";
import { TSubcategory } from "@/types/subcategory";
import { Loader2 } from "lucide-react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLoaderData, useNavigation, useSearchParams } from "react-router-dom";

export default function ProductsPricesPage() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigation = useNavigation();
  const [category, setCategory] = React.useState(searchParams.get("category") ?? "");
  const [subcategory, setSubcategory] = React.useState(searchParams.get("subcategory") ?? "");
  const [brand, setBrand] = React.useState(searchParams.get("brand") ?? "");

  const data = useLoaderData() as {
    products?: TPaginatedList<TProduct>;
    brands?: TBrand[];
    categories?: TCategory[];
    subcategories?: TSubcategory[];
  } | null;

  // TODO: implement Error Page
  if (!data) return <div>Error</div>;

  return (
    <Page
      breadcrumbs={[
        { label: t("sidebar.home"), link: "/" },
        { label: t("productsManagement.title"), link: "/products" },
        { label: t("prices.docTitle") },
      ]}
      title={t("prices.docTitle")}
    >
      <ListData
        actionsClassNames="flex items-center gap-2"
        actions={
          <>
            {navigation.state === "loading" ? <Loader2 className="text-primary animate-spin" /> : null}
            {/* Category Select */}
            <Select
              value={category}
              onValueChange={(value) => {
                setCategory(value);
                setSearchParams((prevParams) => {
                  prevParams.set("category", value);
                  return prevParams;
                });
              }}
            >
              <SelectTrigger className="w-[160px]">
                <SelectValue placeholder={t("prices.categories")} />
              </SelectTrigger>
              <SelectContent>
                {data.categories?.map((category) => (
                  <SelectItem key={category.id} value={category.id}>
                    {category.title.fa}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            {/* Subcategory Select */}
            <Select
              value={subcategory}
              disabled={data.subcategories?.length === 0}
              onValueChange={(value) => {
                setSubcategory(value);
                setSearchParams((prevParams) => {
                  prevParams.set("subcategory", value);
                  return prevParams;
                });
              }}
            >
              <SelectTrigger className="w-[160px]">
                <SelectValue placeholder={t("prices.subcategories")} />
              </SelectTrigger>
              <SelectContent>
                {data.subcategories?.map((subcategory) => (
                  <SelectItem key={subcategory.id} value={subcategory.id}>
                    {subcategory.title.fa}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            {/* Brand Select */}
            <Select
              value={brand}
              onValueChange={(value) => {
                setBrand(value);
                setSearchParams((prevParams) => {
                  prevParams.set("brand", value);
                  return prevParams;
                });
              }}
            >
              <SelectTrigger className="w-[160px]">
                <SelectValue placeholder={t("prices.brands")} />
              </SelectTrigger>
              <SelectContent>
                {data.brands?.map((brand) => (
                  <SelectItem key={brand.id} value={brand.id}>
                    {brand.name.fa}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            <Button
              variant="ghost"
              disabled={!category && !subcategory && !brand}
              onClick={() => {
                setBrand("");
                setCategory("");
                setSubcategory("");
                setSearchParams({});
              }}
            >
              <Trans i18nKey="prices.clearSearch"></Trans>
            </Button>
          </>
        }
      >
        {data.products?.items.length ? (
          <ul className="grid grid-cols-2 gap-4">
            {data.products?.items.map((product) => (
              <li key={product.id}>
                <PriceProductCard product={product} />
              </li>
            ))}
          </ul>
        ) : (
          <div className="text-gray-scale-4 text-center">
            <Trans i18nKey="common.isEmpty"></Trans>
          </div>
        )}
      </ListData>
      {data.products?.items.length ? (
        <Pagination
          className="mt-4"
          path="/products/prices"
          currentPage={data.products?.pagination.current_page}
          totalPage={data.products?.pagination.total_pages}
        />
      ) : null}
    </Page>
  );
}
