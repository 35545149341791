import { API } from "@/lib/axios";
import { TLogsList } from "@/types/general";
import { TLog, TLogQuery } from "@/types/log";
import { AxiosResponse } from "axios";

const ENTITY = "/logs";

export const getList = async (query: TLogQuery): Promise<AxiosResponse<{ data: TLogsList }>> =>
  API.get(ENTITY, { params: query });

export const getById = async (id: string): Promise<AxiosResponse<{ data: TLog }>> => API.get(`${ENTITY}/${id}`);
