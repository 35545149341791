import { AuthAPI } from "@/api";
import Mexico from "@/assets/images/mexico.svg";
import ListData from "@/components/list-data";
import Page from "@/components/page";
import { useAuth } from "@/components/providers/auth-provider";
import { Button, buttonVariants } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTrigger } from "@/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { toast } from "@/components/ui/use-toast";
import { ADMIN_PERMISSIONS } from "@/consts/admin.const";
import { cn, hasPermissions } from "@/lib/utils";
import { TAdminsList } from "@/types/admin";
import { zodResolver } from "@hookform/resolvers/zod";
import { ChevronLeft, FileText } from "lucide-react";
import React from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Link, useLoaderData, useRevalidator } from "react-router-dom";
import { z } from "zod";

export default function AdminsManagement() {
  const data = useLoaderData() as TAdminsList | null;
  const { profile } = useAuth();

  const { t } = useTranslation();
  const revalidator = useRevalidator();
  const [isOpen, setIsOpen] = React.useState(false);

  const formSchema = z.object({
    first_name: z.string().min(1, t("common.isRequired")),
    last_name: z.string().min(1, t("common.isRequired")),
    email: z.string().email(t("common.isRequired")),
    password: z.string().min(8, t("admins.passwordLenError")),
  });
  const getDefaultValues = () => {
    return {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
    };
  };
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: getDefaultValues(),
  });
  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      const resp = await AuthAPI.register(values);
      toast({ description: resp.data.data.message.fa });
      revalidator.revalidate();
      setIsOpen(false);
    } catch (error) {
      toast({ variant: "destructive", description: (error as Error).message });
    }
  };

  // TODO: implement Error Page
  if (!data) return <div>Error</div>;

  return (
    <Page
      breadcrumbs={[{ label: t("sidebar.home"), link: "/" }, { label: t("admins.title") }]}
      title={t("admins.title")}
    >
      <Dialog
        open={isOpen}
        onOpenChange={(open) => {
          if (!open) form.reset(getDefaultValues());
          setIsOpen(open);
        }}
      >
        <ListData
          actions={
            <DialogTrigger
              asChild
              className={cn({ hidden: !hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_ADMIN]) })}
            >
              <Button>
                <Trans i18nKey="common.addNew" />
              </Button>
            </DialogTrigger>
          }
        >
          {data?.items.length ? (
            <div className="grid grid-cols-2 gap-4">
              {data?.items.map((admin) => (
                <div key={admin.id} className="p-4 bg-gray-scale-5 flex gap-4">
                  <img src={Mexico} className="border border-white/10 w-16 h-16" />
                  <div className="flex-1 flex justify-between items-center">
                    <div className="flex flex-col gap-2">
                      <span className="text-xl">
                        {admin.first_name}&nbsp;{admin.last_name}
                      </span>
                      <span className="text-xs text-gray-scale-1">{admin.role}</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Link
                        to={`/logs?user=${admin.id}`}
                        className={buttonVariants({ variant: "ghost", size: "icon" })}
                      >
                        <FileText size={18} />
                      </Link>
                      <Link to={admin.id} className={buttonVariants({ size: "icon", variant: "ghost" })}>
                        <ChevronLeft />
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center text-gray-scale-4">
              <Trans i18nKey="common.isEmpty" />
            </div>
          )}
        </ListData>
        <DialogContent>
          <DialogHeader>
            <Trans i18nKey="admins.addNew" />
          </DialogHeader>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit, console.log)} className="flex flex-col gap-4">
              <FormField
                control={form.control}
                name="first_name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <Trans i18nKey="admins.firstName" />
                    </FormLabel>
                    <FormControl>
                      <Input placeholder={t("admins.firstNamePlaceholder")} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="last_name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <Trans i18nKey="admins.lastName" />
                    </FormLabel>
                    <FormControl>
                      <Input placeholder={t("admins.lastNamePlaceholder")} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <Trans i18nKey="admins.password" />
                    </FormLabel>
                    <FormControl>
                      <Input
                        className="text-left dir-ltr placeholder:text-right font-montserrat placeholder:font-dana"
                        placeholder={t("admins.passwordPlaceholder")}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <Trans i18nKey="admins.email" />
                    </FormLabel>
                    <FormControl>
                      <Input
                        className="text-left dir-ltr placeholder:text-right font-montserrat placeholder:font-dana"
                        placeholder={t("admins.emailPlaceholder")}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <DialogFooter className="gap-2">
                <Button type="submit" className="w-full" loading={form.formState.isSubmitting}>
                  <Trans i18nKey="common.submit" />
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </Page>
  );
}
