import { cn } from "@/lib/utils";
import Star from "./svg/star";

export default function Rating(props: { stars: number; className?: string }) {
  const { stars, className } = props;
  return (
    <div className={cn("flex", className)}>
      {new Array(5).fill(0).map((_, index) => (
        <Star
          key={index}
          className={cn("w-4 text-black/20 dark:text-white/20", {
            "text-primary dark:text-primary": index + 1 <= stars,
          })}
        />
      ))}
    </div>
  );
}
