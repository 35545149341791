import CountCard from "@/components/card-count";
import Page from "@/components/page";
import { useAuth } from "@/components/providers/auth-provider";
import { ADMIN_PERMISSIONS } from "@/consts/admin.const";
import { hasAllGivenPermissions } from "@/lib/utils";
import { TPaginatedList } from "@/types/general";
import { TProduct, TProductCount } from "@/types/product";
import { Trans, useTranslation } from "react-i18next";
import { Link, useLoaderData } from "react-router-dom";
import { v4 as uuid_v4 } from "uuid";
import HoverCard from "./hover-card";
import { Badge } from "@/components/ui/badge";

export default function ProductsManagementPage() {
  const data = useLoaderData() as null | { products?: TPaginatedList<TProduct>; counts?: TProductCount[] };
  const { t } = useTranslation();
  const { profile } = useAuth();
  const listItems = [
    {
      id: uuid_v4(),
      bgImage: "/images/categories.png",
      title: t("productsManagement.categoriesManagement"),
      route: "categories",
      permissions: [ADMIN_PERMISSIONS.READ_CATEGORY],
    },
    {
      id: uuid_v4(),
      bgImage: "/images/subcategories.png",
      title: t("productsManagement.subCategoriesManagement"),
      route: "categories/subcategories",
      permissions: [ADMIN_PERMISSIONS.READ_SUB_CATEGORY],
    },
    {
      id: uuid_v4(),
      bgImage: "/images/tags.png",
      title: t("productsManagement.tagsManagement"),
      route: "tags",
      permissions: [ADMIN_PERMISSIONS.READ_TAG],
    },
    {
      id: uuid_v4(),
      bgImage: "/images/brands.png",
      title: t("productsManagement.brandsManagement"),
      route: "brands",
      permissions: [ADMIN_PERMISSIONS.READ_BRAND],
    },
    {
      id: uuid_v4(),
      bgImage: "/images/models.png",
      title: t("productsManagement.modelsManagement"),
      route: "models",
      permissions: [ADMIN_PERMISSIONS.READ_MODEL],
    },
    {
      id: uuid_v4(),
      bgImage: "/images/price.png",
      title: t("productsManagement.priceManagement"),
      route: "prices",
      permissions: [ADMIN_PERMISSIONS.READ_PRODUCT, ADMIN_PERMISSIONS.READ_CATEGORY, ADMIN_PERMISSIONS.READ_BRAND],
    },
    {
      id: uuid_v4(),
      bgImage: "/images/products.png",
      title: t("productsManagement.productsManagement"),
      route: "list",
      permissions: [ADMIN_PERMISSIONS.READ_PRODUCT],
    },
  ];
  // TODO: Implement Error Page
  if (!data) return <div>Error</div>;
  return (
    <Page
      breadcrumbs={[{ label: t("sidebar.home"), link: "/" }, { label: t("productsManagement.title") }]}
      title={t("productsManagement.title")}
    >
      <div className={`grid grid-cols-3 gap-4`}>
        {data.counts?.map((item) => (
          <CountCard key={item.name} title={t(`productsManagement.totals.${item.name}`)} count={item.count} />
        ))}
      </div>

      <div className="grid grid-cols-3 gap-4 mt-4">
        {listItems
          .filter((item) => hasAllGivenPermissions(profile, item.permissions))
          .map((item) => (
            <HoverCard key={item.id} bgImage={item.bgImage} title={item.title} route={item.route} />
          ))}
      </div>
      {data?.products?.items.length ? (
        <div className="mt-4 bg-gray-scale-5 p-4">
          <h3 className="text-lg mb-4">
            <Trans i18nKey="productsManagement.latestProducts" />
          </h3>
          <div className="grid grid-cols-2 gap-4">
            {data?.products?.items.map((item) => (
              <Link key={item.id} to={`/products/${item.id}`}>
                <div className="p-2 bg-gray-scale-6 border border-gray-scale-5 rounded dir-ltr text-left flex gap-2 h-full">
                  <img src={item.image} className="w-[70px] object-cover bg-white" />
                  <div className="flex-1 flex flex-col gap-1 font-montserrat">
                    <div className="flex flex-row-reverse items-center justify-between">
                      <span className="text-gray-scale-4 text-xs">{item.brand.name.en}</span>
                      {item.is_used ? (
                        <Badge variant="secondary" className="text-[10px] font-dana font-bold px-2 rounded-none">
                          <Trans i18nKey="common.used" />
                        </Badge>
                      ) : null}
                    </div>
                    <span>{item.name}</span>
                    <div className="text-xs text-gray-scale-4 divide-x">
                      <span>{item.category.title.en}</span>
                      &nbsp;|&nbsp;
                      <span>{item.subcategory.title.en}</span>
                      &nbsp;|&nbsp;
                      <span>{item.model.name}</span>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      ) : null}
    </Page>
  );
}
