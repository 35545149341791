import { AdminsAPI } from "@/api";
import Page from "@/components/page";
import { useAuth } from "@/components/providers/auth-provider";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { toast } from "@/components/ui/use-toast";
import { ADMIN_PERMISSIONS, ADMIN_STATUS } from "@/consts/admin.const";
import { cn, hasPermissions } from "@/lib/utils";
import { TAdminDetailed, TAdminPermissions } from "@/types/admin";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";
import { z } from "zod";

export default function AdminEdit() {
  const data = useLoaderData() as TAdminDetailed | null;
  const { t } = useTranslation();
  const { profile } = useAuth();

  const formSchema = z.object({
    first_name: z.string().min(1, t("common.isRequired")),
    last_name: z.string().min(1, t("common.isRequired")),
    email: z.string().email(t("common.isRequired")),
    status: z.boolean(),
    permissions: z.array(z.string()),
    id: z.string(),
  });
  const getDefaultValues = () => {
    return {
      id: data?.id ?? "",
      first_name: data?.first_name ?? "",
      last_name: data?.last_name ?? "",
      email: data?.email ?? "",
      status: data?.status === ADMIN_STATUS.ACTIVE,
      permissions: data?.permissions.filter((p) => p.value).map((p) => p.key) ?? [],
    };
  };
  const form = useForm<z.infer<typeof formSchema>>({
    defaultValues: getDefaultValues(),
    resolver: zodResolver(formSchema),
  });
  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      const { id, ...rest } = values;
      const resp = await AdminsAPI.updateProfile({
        admin_id: id,
        email: rest.email,
        first_name: rest.first_name,
        last_name: rest.last_name,
        permissions: rest.permissions as TAdminPermissions[],
        status: values.status ? ADMIN_STATUS.ACTIVE : ADMIN_STATUS.INACTIVE,
      });
      toast({ description: resp.data.data.message.fa });
    } catch (err) {
      console.log(err);
      toast({ description: (err as Error).message, variant: "destructive" });
    }
  };

  // TODO: implement Error Page
  if (!data) return <div>Error</div>;
  return (
    <Page
      breadcrumbs={[
        { label: t("sidebar.home"), link: "/" },
        { label: t("admins.title"), link: "/admins" },
        { label: t("admins.edit") },
      ]}
      title={t("admins.title")}
    >
      <Form {...form}>
        <form className="bg-gray-scale-5 p-4" onSubmit={form.handleSubmit(onSubmit, console.log)}>
          <input {...form.register("id")} className="hidden" />
          <div className="flex items-center justify-between">
            <span className="text-xl">
              <Trans i18nKey="admins.personalInfo" />
            </span>
            <Button
              className={cn({ hidden: !hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_ADMIN]) })}
              variant="secondary"
              type="submit"
              loading={form.formState.isSubmitting}
            >
              <Trans i18nKey="common.edit" />
            </Button>
          </div>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <FormField
              control={form.control}
              name="first_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <Trans i18nKey="admins.firstName" />
                  </FormLabel>
                  <FormControl>
                    <Input
                      disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_ADMIN])}
                      placeholder={t("admins.firstNamePlaceholder")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="last_name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <Trans i18nKey="admins.lastName" />
                  </FormLabel>
                  <FormControl>
                    <Input
                      disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_ADMIN])}
                      placeholder={t("admins.lastNamePlaceholder")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <Trans i18nKey="admins.email" />
                  </FormLabel>
                  <FormControl>
                    <Input
                      disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_ADMIN])}
                      className="text-left dir-ltr placeholder:text-right font-montserrat placeholder:font-dana"
                      placeholder={t("admins.emailPlaceholder")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="status"
              render={({ field }) => (
                <FormItem className="flex flex-col justify-end">
                  <FormLabel>
                    <Trans i18nKey="admins.status" />
                  </FormLabel>
                  <FormControl>
                    <div className="flex items-center gap-2 text-gray-scale-4">
                      <span className={cn("text-xs", { "text-primary": field.value })}>
                        <Trans i18nKey="admins.statusActive" />
                      </span>
                      <Switch
                        disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_ADMIN])}
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                      <span className={cn("text-xs", { "text-primary": !field.value })}>
                        <Trans i18nKey="admins.statusInactive" />
                      </span>
                    </div>
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
          <div className="space-y-2">
            <span className="text-xl">
              <Trans i18nKey="admins.permissions" />
            </span>
            <FormField
              control={form.control}
              name="permissions"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <>
                      <div className="flex items-center gap-4 border-b border-white/10 pb-4 mb-4">
                        <FormLabel>
                          <Trans i18nKey="admins.superAdmin" />
                        </FormLabel>
                        <Switch
                          disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_ADMIN])}
                          checked={Object.values(ADMIN_PERMISSIONS).length === field.value.length}
                          onCheckedChange={() => {
                            const hasAll = field.value.length === Object.values(ADMIN_PERMISSIONS).length;
                            field.onChange(hasAll ? [] : Object.values(ADMIN_PERMISSIONS));
                          }}
                        />
                      </div>
                      <div className="grid grid-cols-2 gap-x-14">
                        {Object.values(ADMIN_PERMISSIONS).map((permission) => (
                          <div
                            key={permission}
                            className="flex items-center justify-between gap-4 border-b border-white/10 py-2"
                          >
                            <FormLabel>
                              <Trans i18nKey={`admins.permissionLabels.${permission}`} />
                            </FormLabel>
                            <Switch
                              disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_ADMIN])}
                              checked={field.value.includes(permission)}
                              onCheckedChange={(checked) => {
                                if (checked) {
                                  field.onChange([...field.value, permission]);
                                  return;
                                } else {
                                  field.onChange(field.value.filter((perm) => perm !== permission));
                                }
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </form>
      </Form>
    </Page>
  );
}
