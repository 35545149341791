import { ProductAPI, StaticsAPI } from "@/api";
import ListData from "@/components/list-data";
import Page from "@/components/page";
import Pagination from "@/components/pagination";
import Price from "@/components/price";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { toast } from "@/components/ui/use-toast";
import { PRODUCT_SEARCH_SORTS } from "@/consts/product.const";
import { cn } from "@/lib/utils";
import { TBrand } from "@/types/brand";
import { TCategory } from "@/types/category";
import { TPaginatedList } from "@/types/general";
import { TProduct } from "@/types/product";
import { TSliderDetailed } from "@/types/slider";
import { TSubcategory } from "@/types/subcategory";
import { Check, Loader2, Plus, X } from "lucide-react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLoaderData, useNavigation, useParams, useRevalidator, useSearchParams } from "react-router-dom";

export default function SliderEdit() {
  const data = useLoaderData() as {
    products: TPaginatedList<TProduct>;
    slider: TSliderDetailed;
    brands: TBrand[];
    categories: TCategory[];
    subcategories: TSubcategory[];
  } | null;

  const { t } = useTranslation();
  const revalidator = useRevalidator();
  const navigation = useNavigation();
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();

  const [addingProduct, setAddingProduct] = React.useState("");
  const [deletingProduct, setDeletingProduct] = React.useState("");
  const [category, setCategory] = React.useState(searchParams.get("category") ?? "");
  const [subcategory, setSubcategory] = React.useState(searchParams.get("subcategory") ?? "");
  const [brand, setBrand] = React.useState(searchParams.get("brand") ?? "");
  const [loadingAddLatest, setLoadingAddLatest] = React.useState(false);

  const onAdd = async (productId: string) => {
    try {
      setAddingProduct(productId);
      const resp = await StaticsAPI.addProductToSlider(data!.slider.name, [productId]);
      revalidator.revalidate();
      toast({ description: resp.data.data.message.fa });
    } catch (err) {
      toast({ variant: "destructive", description: (err as Error).message });
    } finally {
      setAddingProduct("");
    }
  };

  const onDelete = async (productId: string) => {
    try {
      setDeletingProduct(productId);
      const resp = await StaticsAPI.deleteProductFromSlider(data!.slider.name, productId);
      revalidator.revalidate();
      toast({ description: resp.data.data.message.fa });
    } catch (err) {
      toast({ variant: "destructive", description: (err as Error).message });
    } finally {
      setDeletingProduct("");
    }
  };

  const currentSelectedProduct = data?.slider.products.map((i) => i.id);

  const onAddLatestProducts = async () => {
    try {
      setLoadingAddLatest(true);
      const latestProducts = await ProductAPI.getList({
        pagination: { page: 1, limit: 6 },
        sorting: PRODUCT_SEARCH_SORTS.CREATED_AT_DSC,
      });
      const resp = await StaticsAPI.addProductToSlider(
        data!.slider.name,
        latestProducts.data.data.items.map((i) => i.id)
      );

      revalidator.revalidate();
      toast({ description: resp.data.data.message.fa });
    } catch (err) {
      toast({ variant: "destructive", description: (err as Error).message });
    } finally {
      setLoadingAddLatest(false);
    }
  };

  // TODO: Implement Error Page
  if (!data) return <div>Error</div>;

  return (
    <Page
      breadcrumbs={[
        { label: t("sidebar.home"), link: "/" },
        { label: t("website.title"), link: "/website" },
        { label: t("sliders.title"), link: "/website/slider" },
        { label: t("sliders.edit") },
      ]}
      title={t("sliders.title")}
    >
      <div className="flex flex-col gap-4 min-h-32 mb-4">
        <div className="flex items-center justify-between">
          <span className="text-xl">
            <Trans i18nKey={`sliders.sections.${data.slider.name}`} />
          </span>
          {params.name === "newest" ? (
            <Button loading={loadingAddLatest} onClick={onAddLatestProducts}>
              <Trans i18nKey="sliders.add6Latest" />
            </Button>
          ) : null}
        </div>
        <div>
          {data.slider.products.length ? (
            <div className="grid grid-cols-3 gap-2">
              {data.slider.products.map((product) => (
                <div key={product.id} className="p-2 border border-white/10 bg-gray-scale-5 flex gap-2">
                  <img src={product.thumbnail} className="w-[80px] min-h-[80px] bg-white object-cover" />
                  <div className="flex-1 relative flex flex-col justify-between">
                    <span className="inline-block text-gray-scale-2 font-montserrat ">{product.name}</span>
                    <span className="text-gray-scale-4 text-sm">
                      <Price
                        call_to_buy={product.call_to_buy}
                        price={product.price}
                        is_available={product.is_available}
                        is_on_sale={product.is_on_sale}
                        price_to_pay={product.price_to_pay}
                      />
                    </span>
                    <div className="absolute -bottom-2.5 -left-2.5">
                      <Button
                        onClick={() => onDelete(product.id)}
                        loading={deletingProduct === product.id}
                        variant="secondary"
                        size="icon"
                        className="!text-gray-scale-4 hover:!text-primary"
                      >
                        <X />
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center text-gray-scale-4">
              <Trans i18nKey="common.isEmpty" />
            </div>
          )}
        </div>
      </div>
      <div>
        <ListData
          label={
            <span className="text-xl">
              <Trans i18nKey="sliders.productsList" />
            </span>
          }
          actionsClassNames="flex items-center gap-2"
          actions={
            <>
              {navigation.state === "loading" ? <Loader2 className="text-primary animate-spin" /> : null}
              {/* Category Select */}
              <Select
                value={category}
                onValueChange={(value) => {
                  setCategory(value);
                  setSearchParams((prevParams) => {
                    prevParams.set("category", value);
                    return prevParams;
                  });
                }}
              >
                <SelectTrigger className="w-[160px]">
                  <SelectValue placeholder={t("prices.categories")} />
                </SelectTrigger>
                <SelectContent>
                  {data.categories?.map((category) => (
                    <SelectItem key={category.id} value={category.id}>
                      {category.title.fa}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>

              {/* Subcategory Select */}
              <Select
                value={subcategory}
                disabled={data.subcategories?.length === 0}
                onValueChange={(value) => {
                  setSubcategory(value);
                  setSearchParams((prevParams) => {
                    prevParams.set("subcategory", value);
                    return prevParams;
                  });
                }}
              >
                <SelectTrigger className="w-[160px]">
                  <SelectValue placeholder={t("prices.subcategories")} />
                </SelectTrigger>
                <SelectContent>
                  {data.subcategories?.map((subcategory) => (
                    <SelectItem key={subcategory.id} value={subcategory.id}>
                      {subcategory.title.fa}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>

              {/* Brand Select */}
              <Select
                value={brand}
                onValueChange={(value) => {
                  setBrand(value);
                  setSearchParams((prevParams) => {
                    prevParams.set("brand", value);
                    return prevParams;
                  });
                }}
              >
                <SelectTrigger className="w-[160px]">
                  <SelectValue placeholder={t("prices.brands")} />
                </SelectTrigger>
                <SelectContent>
                  {data.brands?.map((brand) => (
                    <SelectItem key={brand.id} value={brand.id}>
                      {brand.name.fa}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>

              <Button
                variant="ghost"
                disabled={!category && !subcategory && !brand}
                onClick={() => {
                  setBrand("");
                  setCategory("");
                  setSubcategory("");
                  setSearchParams({});
                }}
              >
                <Trans i18nKey="prices.clearSearch"></Trans>
              </Button>
            </>
          }
        >
          {data.products.items.length ? (
            <div className="grid grid-cols-3 gap-2">
              {data.products.items.map((product) => (
                <div key={product.id} className="p-2 border border-white/10 bg-gray-scale-5 flex gap-2">
                  <img src={product.image} className="w-[80px] min-h-[80px] bg-white object-cover" />
                  <div className="flex-1 relative flex flex-col justify-between">
                    <span className="inline-block text-gray-scale-2 font-montserrat ">{product.name}</span>
                    <Price
                      call_to_buy={product.call_to_buy}
                      price={product.price}
                      is_available={product.is_available}
                      is_on_sale={product.is_on_sale}
                      price_to_pay={product.price_to_pay}
                    />
                    <div className="absolute -bottom-2.5 -left-2.5">
                      <Button
                        onClick={() => onAdd(product.id)}
                        loading={addingProduct === product.id}
                        variant={currentSelectedProduct?.includes(product.id) ? "default" : "secondary"}
                        size="icon"
                        className={cn({
                          "!text-gray-scale-2 pointer-events-none": currentSelectedProduct?.includes(product.id),
                          "!text-gray-scale-4 hover:!text-primary": !currentSelectedProduct?.includes(product.id),
                        })}
                      >
                        {currentSelectedProduct?.includes(product.id) ? <Check /> : <Plus />}
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-gray-scale-4 text-center">
              <Trans i18nKey="common.isEmpty" />
            </div>
          )}
        </ListData>
        {data.products?.items.length ? (
          <Pagination
            limit={12}
            className="mt-4"
            path={`/website/slider/${data.slider.name}`}
            currentPage={data.products?.pagination.current_page}
            totalPage={data.products?.pagination.total_pages}
          />
        ) : null}
      </div>
    </Page>
  );
}
