import { CategoryAPI, TagAPI } from "@/api";
import { LoaderFunctionArgs } from "react-router-dom";

export default async function loader({ params }: LoaderFunctionArgs) {
  const { id } = params as { id: string };
  if (!id) return null;
  return Promise.all([CategoryAPI.getList(), TagAPI.getById(id)])
    .then(([categories, tag]) => ({ categories: categories.data.data.items, tag: tag.data.data }))
    .catch(() => null);
}
