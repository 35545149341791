export default {
  title: "نظرات کاربران",
  all: "همه",
  pending: "در انتظار",
  confirmed: "تاییدشده",
  rejected: "ردشده",
  purchasedUser: "خریدار معتبر",
  isUsed: "دست دوم",
  confirm: "تایید",
  reject: "رد",
};
