export default {
  title: "جزییات سفارش {{code}}",
  products: "محصولات",
  info: "اطلاعات",
  label: "جزییات سفارش",
  changeReceiver: "تغییر گیرنده",
  code: "کد سفارش",
  postalAddress: "<0>آدرس پستی: {{address}} - پلاک {{no}} - واحد {{floor}}</0>",
  postalCode: "<0>کد پستی: {{code}}</0>",
  phone: "<0>شماره تماس: {{phone}}</0>",
  email: "<0>ایمیل: </0><1>{{email}}</1>",
  nationalCode: "<0>کد ملی: {{code}}</0>",
  requestDate: "تاریخ ثبت سفارش:",
  receivedDate: "تاریخ دریافت احتمالی:",
  bankTransaction: "تراکنش بانکی",
};
