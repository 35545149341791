import { cn } from "@/lib/utils";
import { addCommas } from "@persian-tools/persian-tools";
import { Trans } from "react-i18next";

type TProps = {
  price: number;
  price_to_pay: number;
  is_on_sale: boolean;
  is_available: boolean;
  call_to_buy: boolean;
  className?: string;
};
export default function Price(props: TProps) {
  const { call_to_buy, is_available, is_on_sale, price, price_to_pay, className } = props;
  return (
    <>
      {is_available ? (
        call_to_buy ? (
          <span className={cn("text-sm text-gray-scale-4 mt-2", className)}>
            <Trans i18nKey="products.priceText.callToBuy" />
          </span>
        ) : price >= 1 ? (
          <div className={cn("flex items-center w-full", className)}>
            <div className="flex gap-2.5">
              {is_on_sale ? (
                <span className="text-primary text-xs line-through">
                  <Trans i18nKey="common.toman" values={{ value: addCommas(price) }} />
                </span>
              ) : null}
              <span className="text-sm text-gray-scale-4">
                <Trans i18nKey="common.toman" values={{ value: addCommas(price_to_pay) }} />
              </span>
            </div>
          </div>
        ) : (
          <span className={cn("text-sm text-gray-scale-4", className)}>
            <Trans i18nKey="products.priceText.unavailable" />
          </span>
        )
      ) : (
        <span className="text-sm text-gray-scale-4">
          <Trans i18nKey="products.priceText.unavailable" />
        </span>
      )}
    </>
  );
}
