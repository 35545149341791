import Page from "@/components/page";
import { useTranslation } from "react-i18next";
import HoverCard from "./hover-card";

export default function WebsiteManagement() {
  const { t } = useTranslation();
  return (
    <Page
      title={t("website.title")}
      breadcrumbs={[{ label: t("sidebar.home"), link: "/" }, { label: t("website.title") }]}
    >
      <div className="grid grid-cols-2 gap-4">
        <HoverCard bgImage="/images/aboutus.webp" title={t("website.aboutUs")} route="about-us" />
        <HoverCard bgImage="/images/termspolicy.webp" title={t("website.policy")} route="policy" />
        <HoverCard bgImage="/images/carousel.webp" title={t("website.carousel")} route="carousel" />
        <HoverCard bgImage="/images/faq.webp" title={t("website.faq")} route="faq" />
        <HoverCard bgImage="/images/slider.png" title={t("website.slider")} route="slider" />
        <HoverCard bgImage="/images/used-products.jpg" title={t("website.usedProducts")} route="used-products" />
      </div>
    </Page>
  );
}
