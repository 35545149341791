import { TagAPI } from "@/api";
import TagForm from "@/components/form-tag";
import Page from "@/components/page";
import { toast } from "@/components/ui/use-toast";
import { TCategory } from "@/types/category";
import { TNewTag, TTagDetailed, TTagFAQItem, TTagNewFAQItem } from "@/types/tag";
import { isAxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useLoaderData, useNavigate, useParams, useRevalidator } from "react-router-dom";

export default function TagEdit() {
  const { t } = useTranslation();
  const data = useLoaderData() as { categories: TCategory[]; tag: TTagDetailed } | null;
  const params = useParams() as { id: string };
  const revalidator = useRevalidator();
  const navigate = useNavigate();

  const onAddFAQ = async (data: TTagNewFAQItem) => {
    try {
      const resp = await TagAPI.addQAById(params.id, data);
      revalidator.revalidate();
      toast({ description: resp.data.data.message.fa });
    } catch (error) {
      toast({ description: (error as Error).message, variant: "destructive" });
    }
  };
  const onDeleteFAQ = async (id: string) => {
    try {
      const resp = await TagAPI.deleteQAById(id, params.id);
      revalidator.revalidate();
      toast({
        description: resp.data.data.message.fa,
      });
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          description: err.response?.data.message,
          variant: "destructive",
        });
        return;
      }
      if (err instanceof Error) {
        toast({
          description: err.message,
          variant: "destructive",
        });
      }
    }
  };
  const onEditFAQ = async (data: TTagFAQItem) => {
    try {
      const resp = await TagAPI.updateQAbyId(data.id, params.id, {
        question: { fa: data.question.fa, en: data.question.en },
        answer: { fa: data.answer.fa, en: data.answer.en },
      });
      revalidator.revalidate();
      toast({ description: resp.data.data.message.fa });
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          description: err.response?.data.message,
          variant: "destructive",
        });
        return;
      }
      if (err instanceof Error) {
        toast({
          description: err.message,
          variant: "destructive",
        });
      }
    }
  };
  const onSubmitForm = async (tag: TNewTag) => {
    try {
      const resp = await TagAPI.update(params.id, tag);
      toast({ description: resp.data.data.message.fa });
      navigate(-1);
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          description: err.response?.data.message,
          variant: "destructive",
        });
        return;
      }
      if (err instanceof Error) {
        toast({
          description: err.message,
          variant: "destructive",
        });
      }
    }
  };

  // TODO: implement Error Page
  if (!data) return <div>Error</div>;

  return (
    <Page
      breadcrumbs={[
        { label: t("sidebar.home"), link: "/" },
        { label: t("productsManagement.title"), link: "/products" },
        { label: t("productsTags.title"), link: "/products/tags" },
        { label: t("productsTags.editLabel") },
      ]}
      title={t("productsTags.edit", { title: data.tag.title.fa })}
    >
      <TagForm
        onSubmitForm={onSubmitForm}
        categories={data.categories}
        initData={data.tag}
        onEditFAQ={onEditFAQ}
        onDeleteFAQ={onDeleteFAQ}
        onAddFAQ={onAddFAQ}
      />
    </Page>
  );
}
