import { AdminsAPI } from "@/api";
import { cn } from "@/lib/utils";
import { isAxiosError } from "axios";
import React from "react";
import { Trans } from "react-i18next";
import { Button } from "../ui/button";
import { Checkbox } from "../ui/checkbox";
import { toast } from "../ui/use-toast";

type TValue = {
  url: string;
  thumbnail: string;
  is_primary: boolean;
};

type TProp = {
  value?: TValue;
  className?: string;
  onChange: (value: Partial<TValue>) => void; // TODO: call it after upload
  onDelete?: (url: string) => void; //  TODO: delete the image with this url from the images array
  onTogglePrimary?: (url: string, state: boolean) => void; // TODO: set the is_primary to true with this url and the others to false
  disabled?: boolean;
  watermark?: boolean;
};

export default function ImageGallery(props: TProp) {
  const { value, className, onChange, onDelete, onTogglePrimary, disabled, watermark } = props;
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [uploading, setUploading] = React.useState<"url" | "thumbnail" | undefined>();

  const uploadHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      setUploading(undefined);
      return;
    }

    try {
      const fd = new FormData();
      fd.append("image", e.target.files[0]);
      fd.append("watermark", (!!watermark).toString());
      const resp = await AdminsAPI.upload(fd);
      const data = { url: value?.url ?? "", thumbnail: value?.thumbnail ?? "", is_primary: value?.is_primary ?? false };
      data[uploading!] = resp.data.data.url;
      onChange(data);
    } catch (err) {
      console.log(err);
      if (isAxiosError(err)) {
        return toast({
          description: err.response?.data.message,
          variant: "destructive",
        });
      }
      if (err instanceof Error) {
        return toast({
          description: err.message,
          variant: "destructive",
        });
      }
    } finally {
      if (inputRef.current) {
        inputRef.current.value = "";
        inputRef.current.files = null;
      }
      setUploading(undefined);
    }
  };

  return (
    <div
      style={{ backgroundImage: `url(${value?.url})` }}
      className={cn(
        "relative bg-cover w-full h-[300px] flex items-center justify-center bg-white/10",
        {
          "bg-white": !!value?.url,
        },
        className
      )}
    >
      <div
        style={{ backgroundImage: `url(${value?.thumbnail})` }}
        className={cn(
          "absolute top-0 right-0 border-l-2 border-b-2 bg-cover w-32 h-32 flex items-center justify-center border-white/30",
          {
            "border-border": !!value?.thumbnail,
          }
        )}
      >
        {!value?.thumbnail ? (
          <Button
            variant="tertiary"
            loading={uploading === "thumbnail"}
            className={cn({ hidden: disabled })}
            onClick={() => {
              setUploading("thumbnail");
              inputRef.current?.click();
            }}
          >
            <Trans i18nKey="products.uploadThumbnail" />
          </Button>
        ) : null}
      </div>

      {!value?.url ? (
        <Button
          variant="tertiary"
          loading={uploading === "url"}
          className={cn({ hidden: disabled })}
          onClick={() => {
            setUploading("url");
            inputRef.current?.click();
          }}
        >
          <Trans i18nKey="products.uploadPreview" />
        </Button>
      ) : null}
      {value?.url ? (
        <div className={cn("absolute bottom-0 left-0 flex justify-between w-full", { hidden: disabled })}>
          <div className="flex items-center py-1 px-2 gap-2 bg-gray-scale-3">
            <Checkbox
              checked={value.is_primary}
              onCheckedChange={(check: boolean) => onTogglePrimary!(value.url, check)}
            />
            <span className="text-xs text-black">
              <Trans i18nKey="products.primaryImage" />
            </span>
          </div>
          <Button size="sm" variant="tertiary" onClick={() => onDelete!(value.url)}>
            <Trans i18nKey="common.delete" />
          </Button>
        </div>
      ) : null}
      <input ref={inputRef} type="file" className="hidden" onChange={uploadHandler} />
    </div>
  );
}
