export default {
  docTitle: "ادمین تنزوشاپ",
  select: "انتخاب کنید",
  unknown: "نامشخص",
  discount: "تخفیف",
  isEmpty: "آیتمی موجود نیست",
  isRequired: "این فیلد الزامی است",
  firstName: "نام",
  notFound: "موردی یافت نشد",
  search: "جستجو کنید",
  fa: "فارسی",
  used: "دست دوم",
  en: "انگلیسی",
  edit: "ویرایش",
  lastName: "نام خانوادگی",
  submit: "تایید",
  cancel: "لغو",
  address: "آدرس",
  receiver: "گیرنده",
  date: "تاریخ",
  toman: "{{value}} تومان",
  addNew: "افزودن",
  close: "بستن",
  uploadImage: "بارگذاری عکس",
  usedItem: "دست دوم",
  save: "ذخیره",
  incorrectInput: "ورودی نامعتبر",
  delete: "حذف",
  successMessage: "عملیات با موفقیت انجام شد",
  lockText: "شما به این صفحه دسترسی ندارید",
  backToPrev: "برگشت به صفحه قبلی",
  time: "ساعت",
  admin: "ادمین",
  pagination: {
    pageOf: "صفحه {{page}} از {{total}}",
    of: "از",
    goToPage: "برو به صفحه",
    limit: "تعداد",
    ofTotal: "از {{total}}",
  },
};
