import { OrderAPI } from "@/api";
import { useAuth } from "@/components/providers/auth-provider";
import { Button, buttonVariants } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";
import { ADMIN_PERMISSIONS } from "@/consts/admin.const";
import { cn, hasPermissions } from "@/lib/utils";
import { TOrderProduct } from "@/types/order";
import { Edit2, Minus, Plus } from "lucide-react";
import React from "react";
import { Trans } from "react-i18next";
import { useParams, useRevalidator } from "react-router-dom";

type TProps = {
  list: TOrderProduct[];
};

function ProductCard({ data, allowEdit, orderId }: { orderId: string; data: TOrderProduct; allowEdit: boolean }) {
  const revalidator = useRevalidator();
  const [countUpdateMode, setCountUpdateMode] = React.useState<"increment" | "decrement" | null>(null);

  async function updateCount(count: number, mode: "increment" | "decrement") {
    try {
      setCountUpdateMode(mode);
      const resp = await OrderAPI.updateOrder({ order_id: orderId, product: { product_id: data.id, count } });
      revalidator.revalidate();
      toast({
        description: resp.data.data.message.fa,
      });
    } catch (err) {
      if (err instanceof Error) {
        toast({
          variant: "destructive",
          description: err.message,
        });
      }
    } finally {
      setCountUpdateMode(null);
    }
  }

  return (
    <div className="p-4 bg-gray-scale-5 flex">
      <div className="w-[70px] bg-white">
        <img src={data.image_url} alt={data.name} />
      </div>
      <div className="px-2 flex-1">
        <h3 className="font-montserrat">{data.name}</h3>
        <div className="flex items-center gap-2 text-xs text-gray-400 mt-1">
          <span>{data.category_title.fa}</span>
          |
          <img className="h-8 w-auto" src={data.brand.logo} alt={data.brand.name.en} />
          <span className="font-montserrat">{data.brand.name.en}</span>
        </div>
      </div>
      {allowEdit ? (
        <div className="flex items-center">
          <Button
            loading={countUpdateMode === "increment"}
            variant="secondary"
            size="icon"
            onClick={() => updateCount(data.count + 1, "increment")}
          >
            <Plus />
          </Button>
          <span className={buttonVariants({ size: "icon", variant: "secondary" })}>{data.count}</span>
          <Button
            loading={countUpdateMode === "decrement"}
            variant="secondary"
            size="icon"
            onClick={() => updateCount(data.count - 1, "decrement")}
          >
            <Minus />
          </Button>
        </div>
      ) : null}
    </div>
  );
}

export default function ProductsList(props: TProps) {
  const { list } = props;
  const [allowEdit, setAllowEdit] = React.useState(false);
  const params = useParams() as { id: string };
  const { profile } = useAuth();
  return (
    <>
      <div className="flex items-center justify-between">
        <h2>
          <Trans i18nKey="ordersDetail.products" />
        </h2>
        <Button
          size="icon"
          variant="ghost"
          onClick={() => setAllowEdit((prev) => !prev)}
          className={cn({ hidden: !hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_ORDER]) })}
        >
          <Edit2 size={16} />
        </Button>
      </div>
      <ul className="flex flex-col gap-1">
        {list.map((product) => (
          <li key={product.id}>
            <ProductCard data={product} allowEdit={allowEdit} orderId={params.id} />
          </li>
        ))}
      </ul>
    </>
  );
}
