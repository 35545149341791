import { AdminsAPI } from "@/api";
import { cn } from "@/lib/utils";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import "draft-js/dist/Draft.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { useEffect } from "react";
import { Editor as WYSIWYGEditor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

type TProps = { data: string; onChange: (data: string) => void; en?: boolean };

function Editor(props: TProps) {
  const { data, onChange, en } = props;

  const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
  const [updated, setUpdated] = React.useState(false);

  const onEditorStateChange = (editorState: EditorState) => {
    setUpdated(true);
    setEditorState(editorState);

    return onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  function onUploadImageCallback(file: File) {
    const data = new FormData();
    data.append("image", file);
    data.append("watermark", "false");
    return AdminsAPI.upload(data).then((resp) => ({ data: { link: resp.data.data.url } }));
  }

  useEffect(() => {
    if (!updated) {
      const defaultValue = data ? data : "";
      const blocksFromHtml = htmlToDraft(defaultValue);
      const contentState = ContentState.createFromBlockArray(blocksFromHtml.contentBlocks, blocksFromHtml.entityMap);
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }
  }, [data]);

  return (
    <WYSIWYGEditor
      toolbar={{
        options: ["inline", "blockType", "fontSize", "link", "emoji", "history", "image"],
        image: {
          popupClassName:
            "dir-ltr text-left [&_*]:dir-ltr font-montserrat [&_.rdw-image-modal-upload-option-label]:text-xs [&_.rdw-image-modal-upload-option]:outline-offset-0",
          uploadCallback: onUploadImageCallback,
        },
      }}
      stripPastedStyles
      editorClassName={cn("bg-gray-scale-6/50 px-4 h-96", { "text-left dir-ltr font-montserrat": en })}
      toolbarClassName="text-black dir-ltr [&_*]:dir-ltr"
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
    />
  );
}

export default Editor;
