import { TColor } from "@/types/color";
import { useTranslation } from "react-i18next";
import { Input } from "../ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import React from "react";
import { TProductForm } from "./schema";
import { cn } from "@/lib/utils";

type TProps = {
  list: TColor[];
  value: TProductForm["color"];
  onChange: (color: TProductForm["color"]) => void;
  disabled?: boolean;
};

export default function ColorPicker(props: TProps) {
  const { list, value, onChange, disabled } = props;
  const { t } = useTranslation();

  const [color, setColor] = React.useState("");
  const [nameFa, setNameFa] = React.useState("");
  const [nameEn, setNameEn] = React.useState("");

  React.useEffect(() => {
    if (value) {
      setColor(list.find((c) => c.id === value.base_color)?.id ?? "");
      setNameFa(value.product_color_name_fa ?? "");
      setNameEn(value.product_color_name_en ?? "");
    }
  }, [list, value]);

  React.useEffect(() => {
    onChange({
      base_color: color,
      has_color: !!value?.has_color,
      product_color_name_en: nameEn,
      product_color_name_fa: nameFa,
    });
  }, [color, nameFa, nameEn]);

  return (
    <div
      className={cn(
        "flex items-end ring-offset-background focus-within:outline-none focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2",
        {
          "cursor-not-allowed": disabled,
        }
      )}
    >
      <Select disabled={disabled} onValueChange={setColor} value={color}>
        <SelectTrigger className="focus:ring-transparent focus:ring-offset-transparent group-focus-within:ring-y-2 w-48">
          <SelectValue placeholder={t("products.selectColor")} />
        </SelectTrigger>
        <SelectContent className="min-w-[var(--radix-select-trigger-width)]">
          {list.map((color) => (
            <SelectItem className="font-montserrat flex justify-center" key={color.id} value={color.id}>
              <img src={color.link} className="w-6 h-6 rounded-full border border-white" />
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      <Input
        className="focus-visible:ring-transparent focus-visible:ring-offset-transparent focus:ring-transparent focus:ring-offset-transparent group-focus-within:ring-y-2"
        placeholder={t("products.nameFa")}
        value={nameFa}
        disabled={disabled}
        onChange={(e) => setNameFa(e.target.value)}
      />
      <Input
        className="text-left dir-ltr placeholder:text-right placeholder:dir-rtl focus-visible:ring-transparent focus-visible:ring-offset-transparent focus:ring-transparent focus:ring-offset-transparent group-focus-within:ring-y-2"
        placeholder={t("products.nameEn")}
        value={nameEn}
        disabled={disabled}
        onChange={(e) => setNameEn(e.target.value)}
      />
    </div>
  );
}
