import { StaticsAPI } from "@/api";
import { ADMIN_PERMISSIONS } from "@/consts/admin.const";
import { apiExecByPermission } from "@/lib/utils";

export default async function loader() {
  try {
    const resp = await apiExecByPermission([ADMIN_PERMISSIONS.READ_STATICS], () => StaticsAPI.getAboutUsContent());
    return resp.data.data;
  } catch (err) {
    return null;
  }
}
