import { API } from "@/lib/axios";
import { TTranslatedText } from "@/types/general";
import { TSliderDetailed, TSliderName, TSlidersList } from "@/types/slider";
import {
  TAboutUs,
  TCarousel,
  TCarouselNew,
  TFAQContent,
  TFAQNew,
  TPolicy,
  TUsedContent,
  TUsedNewContent,
} from "@/types/statics";
import { AxiosResponse } from "axios";

const ENTITY = "/statics";

export const getAboutUsContent = async (): Promise<AxiosResponse<{ data: TAboutUs }>> =>
  API.get(`${ENTITY}/about-us-content/get`);

export const updateAboutUsContent = async (
  data: TTranslatedText
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.post(`${ENTITY}/about-us-content/set`, { content: data });

export const getPolicyContent = async (): Promise<AxiosResponse<{ data: TPolicy }>> =>
  API.get(`${ENTITY}/policy-content/get`);

export const updatePolicyContent = async (
  data: TTranslatedText
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.post(`${ENTITY}/policy-content/set`, { content: data });

export const getCarouselContent = async (): Promise<AxiosResponse<{ data: TCarousel[] }>> =>
  API.get(`${ENTITY}/carousel/list`);

export const createCarouselContent = async (
  data: TCarouselNew
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> => API.post(`${ENTITY}/carousel`, data);

export const updateCarouselContent = async (
  id: string,
  data: TCarouselNew
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.patch(`${ENTITY}/carousel`, { carousel_id: id, ...data });

export const deleteCarouselContentById = async (
  id: string
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> => API.delete(`${ENTITY}/carousel/${id}`);

export const getFAQ = async (): Promise<AxiosResponse<{ data: TFAQContent }>> => API.get(`${ENTITY}/faq-content/get`);

export const UpdateFAQContent = async (
  data: TFAQContent["content"]
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.post(`${ENTITY}/faq-content/set`, { content: data });

export const createNewFAQ = async (data: TFAQNew): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.post(`${ENTITY}/faq-list/add`, data);

export const updateFAQById = async (
  id: string,
  data: TFAQNew
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.post(`${ENTITY}/faq-list/update`, { id, ...data });

export const deleteFAQById = async (id: string): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.post(`${ENTITY}/faq-list/delete`, { id });

export const getSlidersList = async (): Promise<AxiosResponse<{ data: TSlidersList }>> =>
  API.get(`${ENTITY}/slider/list`);

export const getSlidersByName = async (name: TSliderName): Promise<AxiosResponse<{ data: TSliderDetailed }>> =>
  API.get(`${ENTITY}/slider/detail/${name}`);

export const addProductToSlider = async (
  sliderName: TSliderName,
  products: string[]
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.post(`${ENTITY}/slider/add`, { name: sliderName, product_ids: products });

export const deleteProductFromSlider = async (
  sliderName: TSliderName,
  productId: string
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.delete(`${ENTITY}/slider/delete`, { data: { name: sliderName, product_id: productId } });

export const setUsedProductsContent = async (
  data: TUsedNewContent
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> => API.post(`${ENTITY}/used/set`, data);

export const getUsedProductsContent = async (): Promise<AxiosResponse<{ data: TUsedContent }>> =>
  API.get(`${ENTITY}/used`);

export const addUsedProductsFAQ = async (
  data: TFAQNew
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> => API.post(`${ENTITY}/used/faq/add`, data);

export const deleteUsedProductsFAQ = async (
  id: string
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.delete(`${ENTITY}/used/faq/delete`, { data: { id } });

export const updateUsedProductsFAQ = async (
  id: string,
  data: TFAQNew
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.patch(`${ENTITY}/used/faq/update`, { id, ...data });
