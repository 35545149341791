import Page from "@/components/page";
import { cn } from "@/lib/utils";
import { TCategory } from "@/types/category";
import { Loader2 } from "lucide-react";
import { Trans, useTranslation } from "react-i18next";
import { NavLink, Outlet, useLoaderData } from "react-router-dom";

export default function ProductsSubcategories() {
  const { t } = useTranslation();
  const data = useLoaderData() as TCategory[] | null;

  // TODO: Implement Error Page
  if (!data) return <div>Error</div>;

  return (
    <Page
      breadcrumbs={[
        { label: t("sidebar.home"), link: "/" },
        { label: t("productsManagement.title"), link: "/products" },
        { label: t("subcategories.title") },
      ]}
      title={t("subcategories.title")}
    >
      <span className="inline-block mb-2">
        <Trans i18nKey="subcategories.categories" />
      </span>
      <div className={`flex items-center flex-wrap gap-2`}>
        {data.map((category) => (
          <NavLink
            to={`${category.id}`}
            key={category.id}
            className={({ isActive }) =>
              cn(
                "flex flex-col items-center justify-center bg-gray-scale-5 border border-transparent relative hover:border-primary/50",
                {
                  "border-primary": isActive,
                }
              )
            }
          >
            {({ isPending }) => (
              <>
                <img className="w-24 h-16 text-white object-cover" src={category.thumbnail} alt={category.title.fa} />
                <span className="text-sm inline-block py-2">{category.title.fa}</span>
                {isPending ? (
                  <div className="absolute inset-0 bg-black/10 backdrop-blur flex items-center justify-center">
                    <Loader2 className="text-primary animate-spin" size={28} />
                  </div>
                ) : null}
              </>
            )}
          </NavLink>
        ))}
      </div>
      <div className="py-4">
        <Outlet />
      </div>
    </Page>
  );
}
