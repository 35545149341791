import { OrderAPI } from "@/api";
import { useAuth } from "@/components/providers/auth-provider";
import { Button } from "@/components/ui/button";
import { Dialog, DialogClose, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { toast } from "@/components/ui/use-toast";
import { ADMIN_PERMISSIONS } from "@/consts/admin.const";
import { cn, hasPermissions } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { Edit2 } from "lucide-react";
import React from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useParams, useRevalidator } from "react-router-dom";
import { z } from "zod";

type TProps = {
  firstName: string;
  lastName: string;
};

export default function UserInfo(props: TProps) {
  const { firstName, lastName } = props;
  const { t } = useTranslation();
  const params = useParams() as { id: string };
  const revalidator = useRevalidator();
  const [isOpen, setIsOpen] = React.useState(false);
  const { profile } = useAuth();

  const formSchema = z.object({
    firstName: z.string().min(2, { message: t("common.isRequired") }),
    lastName: z.string().min(2, { message: t("common.isRequired") }),
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: firstName,
      lastName: lastName,
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      const resp = await OrderAPI.updateOrder({
        order_id: params.id,
        user_first_name: values.firstName,
        user_last_name: values.lastName,
      });
      revalidator.revalidate();

      toast({
        description: resp.data.data.message.fa,
      });
    } catch (err) {
      if (err instanceof Error) {
        toast({
          variant: "destructive",
          description: err.message,
        });
      }
    } finally {
      setIsOpen(false);
    }
  }

  return (
    <div>
      <div className="flex items-center justify-between">
        <h2>
          <Trans i18nKey="ordersDetail.info" />
        </h2>
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
          <DialogTrigger asChild className={cn({ hidden: !hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_ORDER]) })}>
            <Button size="icon" variant="ghost">
              <Edit2 size={16} />
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>
                <Trans i18nKey="ordersDetail.changeReceiver" />
              </DialogTitle>
            </DialogHeader>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
                <FormField
                  control={form.control}
                  name="firstName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        <Trans i18nKey="common.firstName" />
                      </FormLabel>
                      <FormControl>
                        <Input placeholder={t("common.firstName")} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="lastName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        <Trans i18nKey="common.lastName" />
                      </FormLabel>
                      <FormControl>
                        <Input placeholder={t("common.lastName")} {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="flex items-center gap-2">
                  <Button type="submit" className="w-full" loading={form.formState.isSubmitting}>
                    <Trans i18nKey="common.submit" />
                  </Button>
                  <DialogClose asChild>
                    <Button type="button" className="w-full" variant="outline">
                      <Trans i18nKey="common.cancel" />
                    </Button>
                  </DialogClose>
                </div>
              </form>
            </Form>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
}
