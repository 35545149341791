import { API } from "@/lib/axios";
import { TPaginatedList, TTranslatedText } from "@/types/general";
import { TProduct, TProductCount, TProductDetailed, TProductNew, TProductQuery } from "@/types/product";
import { AxiosResponse } from "axios";

const ENTITY = "/product";

export const getList = (query: TProductQuery): Promise<AxiosResponse<{ data: TPaginatedList<TProduct> }>> =>
  API.post(`${ENTITY}/list`, query);

export const getCounts = (): Promise<AxiosResponse<{ data: { items: TProductCount[] } }>> => API.get(`${ENTITY}/count`);

export const updatePriceById = (
  id: string,
  data: Partial<{ price: number; is_competitive: boolean; call_to_buy: boolean; total_count: number }>
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.patch(`${ENTITY}/update-price`, { products: [{ id, ...data }] });

export const getById = async (id: string): Promise<AxiosResponse<{ data: TProductDetailed }>> =>
  API.post(`${ENTITY}/detail`, { id });

export const create = async (data: TProductNew): Promise<AxiosResponse<{ data: { id: string } }>> =>
  API.post(ENTITY, data);

export const draft = async (productId: string): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.post(`${ENTITY}/draft`, { product_id: productId });

export const publish = async (productId: string): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.post(`${ENTITY}/publish`, { product_id: productId });

export const update = async (
  productId: string,
  data: TProductNew
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> => API.patch(`${ENTITY}`, { id: productId, ...data });

export const deleteById = async (productId: string): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.delete(`${ENTITY}/${productId}`);
