import * as React from "react";

import { cn } from "@/lib/utils";
import { Eye, EyeOff } from "lucide-react";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const InputPassword = React.forwardRef<HTMLInputElement, InputProps>(({ className, ...props }, ref) => {
  const [show, setShow] = React.useState(false);
  return (
    <div className="relative">
      <input
        type={show ? "text" : "password"}
        className={cn(
          "flex h-10 w-full rounded-md border border-white/10 bg-gray-scale-5 px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        ref={ref}
        {...props}
      />
      {props.value?.toString().length ? (
        <button
          type="button"
          className="absolute top-1/2 right-3 -translate-y-1/2 text-gray-scale-4"
          onClick={() => setShow((prev) => !prev)}
        >
          {show ? <EyeOff /> : <Eye />}
        </button>
      ) : null}
    </div>
  );
});
InputPassword.displayName = "InputPassword";

export { InputPassword };
