import CommentCard from "@/components/card-comment";
import Page from "@/components/page";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { TComment } from "@/types/comment";
import { TCommentsList } from "@/types/general";
import { Loader2 } from "lucide-react";
import { Trans, useTranslation } from "react-i18next";
import { useLoaderData, useNavigate, useNavigation, useSearchParams } from "react-router-dom";

export default function Comments() {
  const data = useLoaderData() as TCommentsList | null;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigation = useNavigation();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("status") ?? "null";

  // TODO: Implement Error Page
  if (!data) return <div>Error</div>;

  return (
    <Page
      breadcrumbs={[{ label: t("sidebar.home"), link: "/" }, { label: t("comments.title") }]}
      title={t("comments.title")}
      action={<div>{navigation.state === "loading" ? <Loader2 className="animate-spin text-primary" /> : null}</div>}
    >
      <Tabs defaultValue={status}>
        <TabsList className="grid grid-cols-4">
          <TabsTrigger value="null" onClick={() => navigate("/comments", { preventScrollReset: true })}>
            <Trans i18nKey="comments.all" />
            &nbsp;({data.total_comments})
          </TabsTrigger>
          <TabsTrigger value="0" onClick={() => navigate("/comments?status=0", { preventScrollReset: true })}>
            <Trans i18nKey="comments.pending" />
            &nbsp;({data.total_comments - (data.total_confirmed_comments + data.total_rejected_comments)})
          </TabsTrigger>
          <TabsTrigger value="1" onClick={() => navigate("/comments?status=1", { preventScrollReset: true })}>
            <Trans i18nKey="comments.confirmed" />
            &nbsp;({data.total_confirmed_comments})
          </TabsTrigger>
          <TabsTrigger value="2" onClick={() => navigate("/comments?status=2", { preventScrollReset: true })}>
            <Trans i18nKey="comments.rejected" />
            &nbsp;({data.total_rejected_comments})
          </TabsTrigger>
        </TabsList>
      </Tabs>
      {data.items.length ? (
        <>
          <ul className="flex flex-col gap-4 mt-4">
            {data.items.map((comment) => (
              <li key={comment.id}>
                <CommentCard data={comment as TComment} />
              </li>
            ))}
          </ul>
          {/* <Pagination
            currentPage={data.pagination.current_page}
            path="/comments"
            totalPage={data.pagination.total_pages}
            limit={data.pagination.limit}
          /> */}
        </>
      ) : (
        <div className="text-gray-scale-4 text-center pt-4">
          <Trans i18nKey="common.isEmpty" />
        </div>
      )}
    </Page>
  );
}
