import { StaticsAPI } from "@/api";
import Editor from "@/components/editor";
import Page from "@/components/page";
import { Button } from "@/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { toast } from "@/components/ui/use-toast";
import { TPolicy } from "@/types/statics";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";

export default function Policy() {
  const data = useLoaderData() as TPolicy | null;

  const { t } = useTranslation();
  const [content, setContent] = React.useState({ en: data?.content.en ?? "", fa: data?.content.fa ?? "" });
  const [isLoading, setIsLoading] = React.useState(false);

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const resp = await StaticsAPI.updatePolicyContent(content);
      toast({ description: resp.data.data.message.fa });
    } catch (err) {
      toast({ description: (err as Error).message, variant: "destructive" });
    } finally {
      setIsLoading(false);
    }
  };

  //TODO: Implement Error Page
  if (!data) return <div>Error</div>;

  return (
    <Page
      breadcrumbs={[
        { label: t("sidebar.home"), link: "/" },
        { label: t("website.title"), link: "/website" },
        { label: t("policy.title") },
      ]}
      title={t("policy.title")}
    >
      <Tabs defaultValue="fa">
        <div className="flex items-center justify-between">
          <TabsList>
            <TabsTrigger value="fa">
              <Trans i18nKey="common.fa" />
            </TabsTrigger>
            <TabsTrigger value="en">
              <Trans i18nKey="common.en" />
            </TabsTrigger>
          </TabsList>
          <Button loading={isLoading} onClick={onSubmit}>
            <Trans i18nKey="common.submit" />
          </Button>
        </div>
        <TabsContent value="fa">
          <Editor data={content.fa} onChange={(e) => setContent((prev) => ({ ...prev, fa: e }))} />
        </TabsContent>
        <TabsContent value="en">
          <Editor en data={content.en} onChange={(e) => setContent((prev) => ({ ...prev, en: e }))} />
        </TabsContent>
      </Tabs>
    </Page>
  );
}
