export const SLIDERS_NAMES = {
  NEWEST_STUDIO: "newest_studio",
  NEWEST_GUITARS: "newest_guitars",
  DISCOUNT_STUDIO: "discount_studio",
  DISCOUNT_GUITARS: "discount_guitars",
  POPULAR_STUDIO: "popular_studio",
  POPULAR_GUITARS: "popular_guitars",

  ELECTRIC_GUITARS: "electric_guitars",
  BASS_GUITARS: "bass_guitars",
  BASS_AMPLIFIERS: "bass_amplifiers",
  ACOUSTIC_GUITARS: "acoustic_guitars",
  GUITAR_AMPLIFIERS: "guitar_amplifiers",
  CLASSICAL_GUITARS: "classical_guitars",
  AUDIO_INTERFACES_TEST: "audio_interfaces_test",
  AUDIO_INTERFACES: "audio_interfaces",
  MONITOR_SPEAKERS: "monitor_speakers",
  MIDI_CONTROLLERS_KEYBOARD: "midi_controllers_keyboard",
  HEADPHONES: "headphones",
  STRINGS: "strings",
  GUITAR_BASS_ACCESSORIES: "guitar_bass_accessories",
  GUITAR_BASS_SPARE_PARTS: "guitar_bass_spare_parts",
  MICROPHONE: "microphone",
  BASS_PEDALS_MULTI_EFFECTS: "bass_pedals_multi_effects",
  PEDALS_MULTI_EFFECTS: "pedals_multi_effects",
} as const;
