import { SubcategoryAPI } from "@/api";
import { ADMIN_PERMISSIONS } from "@/consts/admin.const";
import { apiExecByPermission } from "@/lib/utils";
import { LoaderFunctionArgs } from "react-router-dom";

export default async function loader(args: LoaderFunctionArgs) {
  const id = args.params.categoryId;
  if (!id) return null;
  try {
    const resp = await apiExecByPermission([ADMIN_PERMISSIONS.READ_SUB_CATEGORY], () =>
      SubcategoryAPI.getListByCategoryId(id)
    );
    return resp.data.data.items;
  } catch (err) {
    return null;
  }
}
