import { BrandAPI, ModelAPI } from "@/api";
import { ADMIN_PERMISSIONS } from "@/consts/admin.const";
import { apiExecByPermission } from "@/lib/utils";
import qs from "qs";
import { LoaderFunctionArgs } from "react-router-dom";

export default async function loader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const queryString: Partial<{ query: string; brand: string }> = qs.parse(url.searchParams.toString());
  return Promise.all([
    BrandAPI.getList({ page: 1, limit: 500 }),
    queryString.brand
      ? apiExecByPermission([ADMIN_PERMISSIONS.READ_MODEL], () =>
          ModelAPI.getList({ brand_id: queryString.brand as string, query: queryString.query as string })
        )
      : undefined,
  ])
    .then(([brands, models]) => ({ models: models?.data.data.items ?? [], brands: brands.data.data.items ?? [] }))
    .catch(() => null);
}
