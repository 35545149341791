const ProductNeck = (props: { className?: string }) => (
  <svg width={40} height={40} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" className={props.className}>
    <path d="M15 9H12V10H15V9Z" className="fill-white" />
    <path d="M14 8H12V11H14V8Z" className="fill-white" />
    <path d="M28 11H25V12H28V11Z" className="fill-white" />
    <path d="M28 10H26V13H28V10Z" className="fill-white" />
    <path d="M15 14H12V15H15V14Z" className="fill-white" />
    <path d="M14 13H12V16H14V13Z" className="fill-white" />
    <path d="M28 16H25V17H28V16Z" className="fill-white" />
    <path d="M28 15H26V18H28V15Z" className="fill-white" />
    <path d="M15 19H12V20H15V19Z" className="fill-white" />
    <path d="M14 18H12V21H14V18Z" className="fill-white" />
    <path d="M28 21H25V22H28V21Z" className="fill-white" />
    <path d="M28 20H26V23H28V20Z" className="fill-white" />
    <path d="M25 40H24V6H22V40H21V6H19V40H18V6H16V40H15V5H25V40Z" fill="#E25317" />
  </svg>
);
export default ProductNeck;
