import { CategoryAPI } from "@/api";
import { ADMIN_PERMISSIONS } from "@/consts/admin.const";
import { apiExecByPermission } from "@/lib/utils";
import qs from "qs";
import { LoaderFunctionArgs } from "react-router-dom";

export default async function loader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const queryString: Partial<{ query: string }> = qs.parse(url.searchParams.toString());
  const query = {
    query: queryString.query as string,
  };
  try {
    const resp = await apiExecByPermission([ADMIN_PERMISSIONS.READ_CATEGORY], () => CategoryAPI.getList(query));
    return resp.data.data.items;
  } catch (err) {
    return null;
  }
}
