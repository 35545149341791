import { BrandAPI, CategoryAPI, ColorAPI, ModelAPI, ProductAPI, SubcategoryAPI, TagAPI } from "@/api";
import { TCategoryHealthCheck } from "@/types/category";
import { TProductDetailedCheckListItem } from "@/types/product";
import _ from "lodash";
import { LoaderFunctionArgs } from "react-router-dom";

export default async function loader({ params }: LoaderFunctionArgs) {
  const { id } = params as { id: string };
  if (!id) return null;

  const getCheckList = (
    availableValues: TCategoryHealthCheck[],
    currentValues: TProductDetailedCheckListItem[]
  ): TProductDetailedCheckListItem[] => {
    const mappedList = availableValues.map((item) => ({
      title: item.title,
      reference_id: item.id,
      standard_value: item.value,
      product_value: "",
      score: "",
    }));

    const concatenatedList = [...currentValues, ...mappedList];
    const uniqList = _.unionBy(concatenatedList, "reference_id").map((item) => ({
      ...item,
      product_value: item.product_value ?? "",
      score: item.score ?? "",
    }));

    return uniqList;
  };

  return Promise.all([
    ProductAPI.getById(id),
    BrandAPI.getList({ page: 1, limit: 500 }),
    CategoryAPI.getList(),
    TagAPI.getList({ page: 1, limit: 500 }),
    ColorAPI.getList(),
  ])
    .then(([product, brands, categories, tags, colors]) => {
      return Promise.all([
        ModelAPI.getList({
          brand_id: product.data.data.brand.id,
        }),
        SubcategoryAPI.getListByCategoryId(product.data.data.category.id),
        CategoryAPI.getHealthChecksListById(product.data.data.category.id),
      ])
        .then(([models, subcategories, healthCheckList]) => {
          return {
            product: {
              ...product.data.data,
              health_check_list: getCheckList(healthCheckList.data.data.items, product.data.data.health_check_list),
            },
            brands: brands.data.data.items,
            models: models.data.data.items,
            categories: categories.data.data.items,
            subcategories: subcategories.data.data.items,
            tags: tags.data.data.items,
            colors: colors.data.data.items,
          };
        })
        .catch((err) => err);
    })
    .catch((err) => {
      console.log(err);
      return null;
    });
}
