import { TagAPI } from "@/api";
import { ADMIN_PERMISSIONS } from "@/consts/admin.const";
import { apiExecByPermission } from "@/lib/utils";
import qs from "qs";
import { LoaderFunctionArgs } from "react-router-dom";

export default async function loader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const queryString: Partial<{ query: string; page: number; limit: number }> = qs.parse(url.searchParams.toString());
  try {
    const resp = await apiExecByPermission([ADMIN_PERMISSIONS.READ_TAG], () =>
      TagAPI.getList({
        page: Number(queryString.page) || 1,
        limit: Number(queryString.limit) || 500,
        query: queryString.query as string,
      })
    );
    return resp.data.data;
  } catch (err) {
    return null;
  }
}
