import { API } from "@/lib/axios";
import { TCategory, TCategoryDetailed, TCategoryHealthCheck, TNewCategory } from "@/types/category";
import { TTranslatedText } from "@/types/general";
import { AxiosResponse } from "axios";

const ENTITY = "/category";

export const getList = async (query?: { query: string }): Promise<AxiosResponse<{ data: { items: TCategory[] } }>> =>
  API.get(`${ENTITY}/list`, { params: { ...query } });

export const getById = async (id: string): Promise<AxiosResponse<{ data: TCategoryDetailed }>> =>
  API.get(`${ENTITY}/${id}`);

export const deleteById = async (id: string): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.delete(`${ENTITY}/${id}`);

export const addQAById = async (
  categoryId: string,
  data: { question: TTranslatedText; answer: TTranslatedText }
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.post(`${ENTITY}/faq-list/add`, { category_id: categoryId, ...data });

export const deleteQAById = async (
  id: string,
  categoryId: string
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.delete(`${ENTITY}/faq-list/delete`, { data: { category_id: categoryId, id } });

export const updateQAbyId = async (
  id: string,
  categoryId: string,
  data: { question: TTranslatedText; answer: TTranslatedText }
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.patch(`${ENTITY}/faq-list/update`, { category_id: categoryId, id, ...data });

export const create = async (data: TNewCategory): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.post(ENTITY, data);

export const update = async (
  id: string,
  data: TNewCategory
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> => API.patch(ENTITY, { id, ...data });

export const getHealthChecksListById = async (
  category: string
): Promise<AxiosResponse<{ data: { items: TCategoryHealthCheck[] } }>> => API.get(`${ENTITY}/checklist/${category}`);
