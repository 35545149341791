import { TAdminDetailed } from "@/types/admin";
import React from "react";

type TState = {
  profile: null | TAdminDetailed;
  setProfile: React.Dispatch<React.SetStateAction<null | TAdminDetailed>>;
  isLoggedIn: boolean;
  setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
};

const initStat: TState = {
  profile: null,
  setProfile: () => {},
  isLoggedIn: false,
  setIsLoggedIn: () => {},
};

export const AuthContext = React.createContext<TState>(initStat);

const getInitialState = (key: "profile" | "isLoggedIn") => {
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : initStat[key];
};

export function AuthProvider({ children }: { children: React.ReactNode }) {
  // States
  const [profile, setProfile] = React.useState<null | TAdminDetailed>(() => getInitialState("profile"));
  const [isLoggedIn, setIsLoggedIn] = React.useState<boolean>(() => getInitialState("isLoggedIn"));

  // Save States to local storage
  React.useEffect(() => {
    localStorage.setItem("profile", JSON.stringify(profile));
  }, [profile]);

  React.useEffect(() => {
    localStorage.setItem("isLoggedIn", JSON.stringify(isLoggedIn));
  }, [isLoggedIn]);

  // Values
  const value = { profile, setProfile, isLoggedIn, setIsLoggedIn };

  // Provider
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  return React.useContext(AuthContext);
}
