import { API } from "@/lib/axios";
import { TNewAdmin, TToken } from "@/types/auth";
import { TTranslatedText } from "@/types/general";
import { AxiosResponse } from "axios";

const ENTITY = "/auth";

export const login = async (email: string, password: string): Promise<AxiosResponse<{ data: TToken }>> =>
  API.post(`${ENTITY}/sign-in`, { email, password });

export const refreshToken = async (refreshToken: string): Promise<AxiosResponse<{ data: TToken }>> =>
  API.post(`${ENTITY}/refresh`, { refresh_token: refreshToken });

export const register = async (data: TNewAdmin): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.post(`${ENTITY}/sign-up`, data);
