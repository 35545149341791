import { API } from "@/lib/axios";
import { TTranslatedText } from "@/types/general";
import { TModel, TNewModel } from "@/types/model";
import { AxiosResponse } from "axios";

const ENTITY = "/model";

export const getList = async (query?: {
  brand_id?: string;
  query?: string;
}): Promise<AxiosResponse<{ data: { items: TModel[] } }>> => API.post(`${ENTITY}/list`, query);

export const create = async (data: TNewModel): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.post(`${ENTITY}`, data);

export const deleteById = async (id: string): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.delete(`${ENTITY}/${id}`);

export const updateById = async (
  id: string,
  data: TNewModel
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.patch(`${ENTITY}`, { model_id: id, name: data.name, brand: data.brand });
