import { TagAPI } from "@/api";
import ListData from "@/components/list-data";
import Page from "@/components/page";
import { useAuth } from "@/components/providers/auth-provider";
import { Button, buttonVariants } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";
import { ADMIN_PERMISSIONS } from "@/consts/admin.const";
import { cn, hasPermissions } from "@/lib/utils";
import { TTagsList } from "@/types/general";
import { isAxiosError } from "axios";
import { Edit2, FileText, Loader2, Trash2 } from "lucide-react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, NavLink, useLoaderData, useRevalidator } from "react-router-dom";

export default function ProductsTags() {
  const { t } = useTranslation();
  const data = useLoaderData() as TTagsList | null;
  const [deletingTag, setDeletingTag] = React.useState("");
  const revalidator = useRevalidator();
  const { profile } = useAuth();

  const deleteTag = async (id: string) => {
    try {
      setDeletingTag(id);
      const resp = await TagAPI.deleteById(id);
      revalidator.revalidate();
      toast({
        title: resp.data.data.message.fa,
      });
    } catch (error) {
      if (isAxiosError(error)) {
        toast({
          variant: "destructive",
          description: error.response?.data.message,
        });
        return;
      }
      if (error instanceof Error) {
        toast({
          variant: "destructive",
          description: error.message,
        });
      }
    } finally {
      setDeletingTag("");
    }
  };

  // TODO: implement Error Page
  if (!data) return <div>Error</div>;

  return (
    <Page
      breadcrumbs={[
        { label: t("sidebar.home"), link: "/" },
        { label: t("productsManagement.title"), link: "/products" },
        { label: t("productsTags.title") },
      ]}
      title={t("productsTags.title")}
    >
      <ListData
        actions={
          <NavLink
            to="add"
            className={buttonVariants({
              className: cn({ hidden: !hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_TAG]) }),
            })}
          >
            <Trans i18nKey="common.addNew" />
          </NavLink>
        }
      >
        {data.items.length ? (
          <ul className="grid grid-cols-2 gap-4">
            {data.items.map((tag) => (
              <li key={tag.id}>
                <div className="flex items-center gap-4 bg-gray-scale-5 p-2">
                  <div>
                    <img className="w-[70px] h-[70px] object-cover bg-white" src={tag.thumbnail} alt={tag.title.fa} />
                  </div>
                  <div className="flex-1">
                    <h3>{tag.title.fa}</h3>
                  </div>
                  <div>
                    <Link to={`/logs?entity=${tag.id}`} className={buttonVariants({ variant: "ghost", size: "icon" })}>
                      <FileText size={18} />
                    </Link>
                    <NavLink to={tag.id} className={buttonVariants({ size: "icon", variant: "ghost" })}>
                      {({ isPending }) =>
                        isPending ? <Loader2 className="animate-spin text-primary" size={18} /> : <Edit2 size={18} />
                      }
                    </NavLink>
                    <Button
                      className={cn({ hidden: !hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_TAG]) })}
                      loading={deletingTag === tag.id}
                      onClick={() => deleteTag(tag.id)}
                      size="icon"
                      variant="ghost-destructive"
                    >
                      <Trash2 size={20} />
                    </Button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="text-center text-gray-scale-4">
            <Trans i18nKey="common.isEmpty" />
          </div>
        )}
      </ListData>
    </Page>
  );
}
