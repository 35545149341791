import { TBrandDetailed, TBrandFAQItem, TBrandNewFAQItem } from "@/types/brand";
import { zodResolver } from "@hookform/resolvers/zod";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@radix-ui/react-collapsible";
import _ from "lodash";
import { ChevronDown, Edit2, Trash2 } from "lucide-react";
import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { z } from "zod";
import Editor from "./editor";
import ImageUploader from "./image-upload";
import { Button, buttonVariants } from "./ui/button";
import { Dialog, DialogClose, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "./ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "./ui/form";
import { Input } from "./ui/input";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import { Textarea } from "./ui/textarea";
import { useAuth } from "./providers/auth-provider";
import { hasPermissions } from "@/lib/utils";
import { ADMIN_PERMISSIONS } from "@/consts/admin.const";

type TProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: any; //(data: DeepPartial<TNewBrand>) => Promise<void>;
  initData?: TBrandDetailed;
  onDeleteFAQ?: (id: string) => Promise<void>;
  onEditFAQ?: (data: TBrandFAQItem) => Promise<void>;
  onAddFAQ?: (data: TBrandNewFAQItem) => Promise<void>;
};
export default function BrandForm(props: TProps) {
  const { initData, onSubmit, onAddFAQ, onDeleteFAQ, onEditFAQ } = props;
  const { t } = useTranslation();
  const params = useParams() as { id: string };
  const { profile } = useAuth();

  const [isOpenFAQ, setIsOpenFAQ] = React.useState(false);
  const [openEditFAQ, setEditOpenFAQ] = React.useState(false);
  const [loadingDeleteFAQ, setLoadingDeleteFAQ] = React.useState("");

  const brandFormRef = useRef<HTMLFormElement>(null);

  const optionalSchema = z.object({
    name_fa: z.string().optional(),
    name_en: z.string().optional(),
    image: z.string().optional(),
    canonical: z.string().optional(),
    logo: z.string().optional(),
    description_fa: z.string().optional(),
    description_en: z.string().optional(),
    faq_title_fa: z.string().optional(),
    faq_title_en: z.string().optional(),
    seo_title_fa: z.string().optional(),
    seo_title_en: z.string().optional(),
    seo_description_fa: z.string().optional(),
    seo_description_en: z.string().optional(),
  });

  const brandFormSchema = () => {
    if (params.id) {
      return optionalSchema;
    } else {
      return z.object({
        name_fa: z.string().min(1, { message: t("common.isRequired") }),
        name_en: z.string().min(1, { message: t("common.isRequired") }),
        image: z.string().min(1, { message: t("common.isRequired") }),
        canonical: z.string().min(1, { message: t("common.isRequired") }),
        logo: z.string().min(1, { message: t("common.isRequired") }),
        description_fa: z.string().min(1, t("common.isRequired")),
        description_en: z.string().min(1, t("common.isRequired")),
        faq_title_fa: z.string().min(1, t("common.isRequired")),
        faq_title_en: z.string().min(1, t("common.isRequired")),
        seo_title_fa: z.string().min(1, t("common.isRequired")),
        seo_title_en: z.string().min(1, t("common.isRequired")),
        seo_description_fa: z.string().min(1, t("common.isRequired")),
        seo_description_en: z.string().min(1, t("common.isRequired")),
      });
    }
  };

  const getInitData = (): z.infer<typeof optionalSchema> => {
    if (initData) {
      return {
        name_fa: initData.name.fa,
        name_en: initData.name.en,
        image: initData.logo,
        canonical: initData.canonical ?? "",
        logo: initData.logo,
        description_fa: initData.description?.fa ?? "",
        description_en: initData.description?.en ?? "",
        faq_title_fa: initData.faq_title?.fa ?? "",
        faq_title_en: initData.faq_title?.en ?? "",
        seo_title_fa: initData.seo_title?.fa ?? "",
        seo_title_en: initData.seo_title?.en ?? "",
        seo_description_fa: initData.seo_description?.fa ?? "",
        seo_description_en: initData.seo_description?.en ?? "",
      };
    }
    return {
      name_fa: "",
      name_en: "",
      image: "",
      canonical: "",
      logo: "",
      description_fa: "",
      description_en: "",
      faq_title_fa: "",
      faq_title_en: "",
      seo_title_fa: "",
      seo_title_en: "",
      seo_description_fa: "",
      seo_description_en: "",
    };
  };

  const brandForm = useForm<z.infer<typeof optionalSchema>>({
    resolver: zodResolver(brandFormSchema()),
    defaultValues: getInitData(),
  });

  const faqFormSchema = z.object({
    id: z.string().optional(),
    question_fa: z.string().min(1, t("common.isRequired")),
    question_en: z.string().min(1, t("common.isRequired")),
    answer_fa: z.string().min(1, t("common.isRequired")),
    answer_en: z.string().min(1, t("common.isRequired")),
  });

  const faqForm = useForm<z.infer<typeof faqFormSchema>>({
    resolver: zodResolver(faqFormSchema),
    defaultValues: {
      question_fa: "",
      question_en: "",
      answer_fa: "",
      answer_en: "",
    },
  });

  const handleSubmitBrand = async (values: z.infer<typeof optionalSchema>) => {
    try {
      const desc = JSON.parse(
        JSON.stringify({
          fa: values.description_fa?.trim().length ? values.description_fa.trim() : undefined,
          en: values.description_en?.trim().length ? values.description_en.trim() : undefined,
        })
      );
      const faqTitle = JSON.parse(
        JSON.stringify({
          fa: values.faq_title_fa?.length ? values.faq_title_fa : undefined,
          en: values.faq_title_en?.length ? values.faq_title_en : undefined,
        })
      );
      const seoTitle = JSON.parse(
        JSON.stringify({
          fa: values.seo_title_fa?.length ? values.seo_title_fa.trim() : undefined,
          en: values.seo_title_en?.length ? values.seo_title_en.trim() : undefined,
        })
      );
      const seoDesc = JSON.parse(
        JSON.stringify({
          fa: values.seo_description_fa?.length ? values.seo_description_fa : undefined,
          en: values.seo_description_en?.length ? values.seo_description_en : undefined,
        })
      );
      await onSubmit(
        JSON.parse(
          JSON.stringify({
            name_fa: values.name_fa,
            name_en: values.name_en,
            description: _.isEmpty(desc) ? undefined : desc,
            image: values.logo,
            logo: values.logo,
            descriptions: [],
            canonical: values.canonical,
            faq_title: _.isEmpty(faqTitle) ? undefined : faqTitle,
            seo_title: _.isEmpty(seoTitle) ? undefined : seoTitle,
            seo_description: _.isEmpty(seoDesc) ? undefined : seoDesc,
          })
        )
      );
    } catch (err) {
      Promise.reject(err);
    }
  };

  const handleSubmitFAQ = async (values: z.infer<typeof faqFormSchema>) => {
    try {
      await onAddFAQ!({
        question: { fa: values.question_fa, en: values.question_en },
        answer: { fa: values.answer_fa, en: values.answer_en },
      });
      faqForm.reset();
      setIsOpenFAQ(false);
    } catch (error) {
      Promise.reject(error);
    }
  };

  const handleSubmitEditFAQ = async (values: z.infer<typeof faqFormSchema>) => {
    try {
      await onEditFAQ!({
        id: values.id!,
        question: { fa: values.question_fa, en: values.question_en },
        answer: { fa: values.answer_fa, en: values.answer_en },
      });

      faqForm.reset();
      setEditOpenFAQ(false);
    } catch (error) {
      Promise.reject(error);
    }
  };

  const handleDeleteFAQ = async (id: string) => {
    try {
      setLoadingDeleteFAQ(id);
      await onDeleteFAQ!(id);
    } catch (err) {
      Promise.reject(err);
    } finally {
      setLoadingDeleteFAQ("");
    }
  };

  return (
    <Form {...brandForm}>
      <form
        ref={brandFormRef}
        className="grid grid-cols-2 gap-4 bg-gray-scale-5 p-4"
        onSubmit={brandForm.handleSubmit(handleSubmitBrand, console.log)}
      >
        <div className="flex flex-col gap-4">
          <FormField
            control={brandForm.control}
            name="name_fa"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <Trans i18nKey="brands.nameFa" />
                </FormLabel>
                <FormControl>
                  <Input
                    disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_BRAND])}
                    placeholder={t("brands.nameFa")}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={brandForm.control}
            name="name_en"
            render={({ field }) => (
              <FormItem>
                <FormLabel>
                  <Trans i18nKey="brands.nameEn" />
                </FormLabel>
                <FormControl>
                  <Input
                    disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_BRAND])}
                    className="text-left dir-ltr placeholder:text-right font-montserrat placeholder:font-dana"
                    placeholder={t("brands.nameEn")}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <FormField
          control={brandForm.control}
          name="logo"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <ImageUploader
                  disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_BRAND])}
                  value={field.value ?? ""}
                  onUpload={(url) => {
                    field.onChange(url ?? "");
                    brandForm.setValue("image", url ?? "", { shouldValidate: true });
                  }}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={brandForm.control}
          name="faq_title_fa"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <Trans i18nKey="brands.faqTitleFa" />
              </FormLabel>
              <FormControl>
                <Input
                  disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_BRAND])}
                  placeholder={t("brands.faqTitleFa")}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={brandForm.control}
          name="faq_title_en"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <Trans i18nKey="brands.faqTitleEn" />
              </FormLabel>
              <FormControl>
                <Input
                  disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_BRAND])}
                  className="text-left dir-ltr placeholder:text-right font-montserrat placeholder:font-dana"
                  placeholder={t("brands.faqTitleEn")}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={brandForm.control}
          name="canonical"
          render={({ field }) => (
            <FormItem className="col-span-2">
              <FormLabel>
                <Trans i18nKey="brands.canonical" />
              </FormLabel>
              <FormControl>
                <Input
                  disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_BRAND])}
                  className="text-left dir-ltr placeholder:text-right font-montserrat placeholder:font-dana"
                  placeholder={t("brands.canonical")}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={brandForm.control}
          name="seo_title_fa"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <Trans i18nKey="brands.seoTitleFa" />
              </FormLabel>
              <FormControl>
                <Input
                  disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_BRAND])}
                  placeholder={t("brands.seoTitleFa")}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={brandForm.control}
          name="seo_title_en"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <Trans i18nKey="brands.seoTitleEn" />
              </FormLabel>
              <FormControl>
                <Input
                  disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_BRAND])}
                  className="text-left dir-ltr placeholder:text-right font-montserrat placeholder:font-dana"
                  placeholder={t("brands.seoTitleEn")}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={brandForm.control}
          name="seo_description_fa"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <Trans i18nKey="brands.seoDescFa" />
              </FormLabel>
              <FormControl>
                <Input
                  disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_BRAND])}
                  placeholder={t("brands.seoDescFa")}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={brandForm.control}
          name="seo_description_en"
          render={({ field }) => (
            <FormItem>
              <FormLabel>
                <Trans i18nKey="brands.seoDescEn" />
              </FormLabel>
              <FormControl>
                <Input
                  disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_BRAND])}
                  className="text-left dir-ltr placeholder:text-right font-montserrat placeholder:font-dana"
                  placeholder={t("brands.seoDescEn")}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {/* Description's Editors */}
        <div className="flex flex-col gap-1 col-span-2">
          <span>
            <Trans i18nKey="brands.desc" />
          </span>
          <Tabs defaultValue="fa" className="w-full">
            <TabsList className="w-full grid grid-cols-2">
              <TabsTrigger value="en">
                <Trans i18nKey="brands.descEn" />
              </TabsTrigger>
              <TabsTrigger value="fa">
                <Trans i18nKey="brands.descFa" />
              </TabsTrigger>
            </TabsList>
            <TabsContent value="fa">
              <FormField
                control={brandForm.control}
                name="description_fa"
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormControl>
                      <Editor data={field.value ?? ""} onChange={field.onChange} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </TabsContent>
            <TabsContent value="en">
              <FormField
                control={brandForm.control}
                name="description_en"
                render={({ field }) => (
                  <FormItem className="col-span-2">
                    <FormControl>
                      <Editor en data={field.value ?? ""} onChange={field.onChange} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </TabsContent>
          </Tabs>
        </div>

        {/* FAQ */}
        {initData ? (
          <>
            <div className="col-span-2 flex items-center justify-between">
              <span>
                <Trans i18nKey="categories.faq" />
              </span>
              <Dialog open={isOpenFAQ} onOpenChange={setIsOpenFAQ}>
                <DialogTrigger
                  className={buttonVariants()}
                  disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_BRAND])}
                >
                  <Trans i18nKey="common.addNew" />
                </DialogTrigger>
                <DialogContent className="max-w-4xl">
                  <DialogHeader>
                    <DialogTitle>
                      <Trans i18nKey="brands.addFaq" />
                    </DialogTitle>
                  </DialogHeader>
                  <Form {...faqForm}>
                    <form className="grid grid-cols-4 gap-4">
                      <FormField
                        control={faqForm.control}
                        name="question_fa"
                        render={({ field }) => (
                          <FormItem className="col-span-2">
                            <FormLabel>
                              <Trans i18nKey="categories.questionFa" />
                            </FormLabel>
                            <FormControl>
                              <Input placeholder={t("categories.questionFa")} {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={faqForm.control}
                        name="question_en"
                        render={({ field }) => (
                          <FormItem className="col-span-2">
                            <FormLabel>
                              <Trans i18nKey="categories.questionEn" />
                            </FormLabel>
                            <FormControl>
                              <Input
                                className="text-left dir-ltr font-montserrat placeholder:font-dana placeholder:text-right"
                                placeholder={t("categories.questionEn")}
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={faqForm.control}
                        name="answer_fa"
                        render={({ field }) => (
                          <FormItem className="col-span-2">
                            <FormLabel>
                              <Trans i18nKey="categories.answerFa" />
                            </FormLabel>
                            <FormControl>
                              <Textarea placeholder={t("categories.answerFa")} {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={faqForm.control}
                        name="answer_en"
                        render={({ field }) => (
                          <FormItem className="col-span-2">
                            <FormLabel>
                              <Trans i18nKey="categories.answerEn" />
                            </FormLabel>
                            <FormControl>
                              <Textarea
                                className="text-left dir-ltr font-montserrat placeholder:font-dana placeholder:text-right"
                                placeholder={t("categories.answerEn")}
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <div className="flex justify-end col-span-4 gap-2">
                        <DialogClose asChild>
                          <Button variant="outline" onClick={() => faqForm.reset()}>
                            <Trans i18nKey="common.cancel" />
                          </Button>
                        </DialogClose>
                        <Button
                          onClick={faqForm.handleSubmit(handleSubmitFAQ)}
                          loading={faqForm.formState.isSubmitting}
                        >
                          <Trans i18nKey="common.submit" />
                        </Button>
                      </div>
                    </form>
                  </Form>
                </DialogContent>
              </Dialog>
            </div>
            <Collapsible className="col-span-2">
              <CollapsibleTrigger asChild>
                <div className="flex items-center justify-between border-b border-white/10 cursor-pointer">
                  <span>
                    <Trans i18nKey="categories.faqList" />
                  </span>
                  <Button variant="secondary" size="sm" className="w-9 p-0">
                    <ChevronDown />
                  </Button>
                </div>
              </CollapsibleTrigger>
              <CollapsibleContent className="pt-4 flex flex-col gap-4">
                {initData.faq_list.length ? (
                  initData.faq_list.map((item) => (
                    <div key={item.id} className="bg-gray-scale-6/50 p-2 flex">
                      <div className="flex-1 space-y-1">
                        <div>
                          <p>{item.question.fa}</p>
                          <p className="text-sm text-gray-scale-4 dir-ltr">{item.question.en}</p>
                        </div>

                        <div>
                          <p>{item.answer.fa}</p>
                          <p className="text-sm text-gray-scale-4 dir-ltr">{item.answer.en}</p>
                        </div>
                      </div>
                      <div className="flex flex-col justify-center gap-1">
                        <Dialog open={openEditFAQ} onOpenChange={setEditOpenFAQ}>
                          <DialogContent className="max-w-4xl">
                            <DialogHeader>
                              <DialogTitle>
                                <Trans i18nKey="categories.addNewFaq" />
                              </DialogTitle>
                            </DialogHeader>
                            <Form {...faqForm}>
                              <form className="grid grid-cols-4 gap-4">
                                <FormField
                                  control={faqForm.control}
                                  name="question_fa"
                                  render={({ field }) => (
                                    <FormItem className="col-span-2">
                                      <FormLabel>
                                        <Trans i18nKey="categories.questionFa" />
                                      </FormLabel>
                                      <FormControl>
                                        <Input placeholder={t("categories.questionFa")} {...field} />
                                      </FormControl>
                                      <FormMessage />
                                    </FormItem>
                                  )}
                                />
                                <FormField
                                  control={faqForm.control}
                                  name="question_en"
                                  render={({ field }) => (
                                    <FormItem className="col-span-2">
                                      <FormLabel>
                                        <Trans i18nKey="categories.questionEn" />
                                      </FormLabel>
                                      <FormControl>
                                        <Input
                                          className="text-left dir-ltr font-montserrat placeholder:font-dana placeholder:text-right"
                                          placeholder={t("categories.questionEn")}
                                          {...field}
                                        />
                                      </FormControl>
                                      <FormMessage />
                                    </FormItem>
                                  )}
                                />
                                <FormField
                                  control={faqForm.control}
                                  name="answer_fa"
                                  render={({ field }) => (
                                    <FormItem className="col-span-2">
                                      <FormLabel>
                                        <Trans i18nKey="categories.answerFa" />
                                      </FormLabel>
                                      <FormControl>
                                        <Textarea placeholder={t("categories.answerFa")} {...field} />
                                      </FormControl>
                                      <FormMessage />
                                    </FormItem>
                                  )}
                                />
                                <FormField
                                  control={faqForm.control}
                                  name="answer_en"
                                  render={({ field }) => (
                                    <FormItem className="col-span-2">
                                      <FormLabel>
                                        <Trans i18nKey="categories.answerEn" />
                                      </FormLabel>
                                      <FormControl>
                                        <Textarea
                                          className="text-left dir-ltr font-montserrat placeholder:font-dana placeholder:text-right"
                                          placeholder={t("categories.answerEn")}
                                          {...field}
                                        />
                                      </FormControl>
                                      <FormMessage />
                                    </FormItem>
                                  )}
                                />
                                <div className="flex justify-end col-span-4 gap-2">
                                  <DialogClose>
                                    <Button variant="outline" onClick={() => faqForm.reset()}>
                                      <Trans i18nKey="common.cancel" />
                                    </Button>
                                  </DialogClose>
                                  <Button
                                    onClick={faqForm.handleSubmit(handleSubmitEditFAQ)}
                                    loading={faqForm.formState.isSubmitting}
                                  >
                                    <Trans i18nKey="common.submit" />
                                  </Button>
                                </div>
                              </form>
                            </Form>
                          </DialogContent>
                        </Dialog>
                        <Button
                          size="icon"
                          variant="ghost"
                          onClick={() => {
                            faqForm.reset({
                              question_fa: item.question.fa,
                              question_en: item.question.en,
                              answer_fa: item.answer.fa,
                              answer_en: item.answer.en,
                              id: item.id,
                            });
                            setEditOpenFAQ(true);
                          }}
                        >
                          <Edit2 size={16} />
                        </Button>
                        <Button
                          onClick={() => handleDeleteFAQ(item.id)}
                          loading={loadingDeleteFAQ === item.id}
                          size="icon"
                          variant="ghost-destructive"
                        >
                          <Trash2 size={20} />
                        </Button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center text-gray-scale-4">
                    <Trans i18nKey="common.isEmpty" />
                  </div>
                )}
              </CollapsibleContent>
            </Collapsible>
          </>
        ) : null}

        {/* Submit */}
        <div className="col-span-2 flex justify-end">
          <Button
            disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_BRAND])}
            loading={brandForm.formState.isSubmitting}
            onClick={() => {
              if (brandFormRef.current) {
                brandFormRef.current.dispatchEvent(new Event("submit", { bubbles: true }));
              }
            }}
          >
            <Trans i18nKey="common.submit" />
          </Button>
        </div>
      </form>
    </Form>
  );
}
