import { BrandAPI } from "@/api";
import { ADMIN_PERMISSIONS } from "@/consts/admin.const";
import { apiExecByPermission } from "@/lib/utils";
import { LoaderFunctionArgs } from "react-router-dom";

export default async function loader({ params }: LoaderFunctionArgs) {
  const { id } = params as { id: string };
  try {
    const resp = await apiExecByPermission([ADMIN_PERMISSIONS.READ_BRAND], () => BrandAPI.getById(id));
    return resp.data.data;
  } catch (err) {
    return null;
  }
}
