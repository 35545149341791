export default {
  docTitle: "مدیریت قیمت‌ها/موجودی‌ها",
  categories: "دسته‌بندی‌ها",
  subcategories: "زیردسته‌بندی ها",
  brands: "برندها",
  clearSearch: "پاک کردن فیلترها",
  lastUpdate: "آخرین بروزرسانی: {{date}}",
  price: "قیمت(تومان)",
  count: "تعداد",
  callToBuy: "تماس برای خرید",
  currentSituation: "قیمت نمایشی",
};
