import { createControlComponent } from "@react-leaflet/core";
import L, { Control, LatLng } from "leaflet";
import "leaflet.locatecontrol";
import "leaflet.locatecontrol/dist/L.Control.Locate.css";
import MarkerIcon from "leaflet/dist/images/marker-icon.png";
import MarkerShadow from "leaflet/dist/images/marker-shadow.png";
import "leaflet/dist/leaflet.css";
import { useState } from "react";
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet";

function LocationMarker({
  onChange,
  position: initPosition = null,
}: {
  onChange: (lat: number, lng: number) => void;
  position?: LatLng | null;
}) {
  const [position, setPosition] = useState<LatLng | null>(initPosition);
  const map = useMapEvents({
    click(e) {
      setPosition(e.latlng);
      onChange(e.latlng.lat, e.latlng.lng);
      map.flyTo(e.latlng, map.getZoom());
    },
    locationfound(e) {
      setPosition(e.latlng);
      onChange(e.latlng.lat, e.latlng.lng);
      map.flyTo(e.latlng, map.getZoom());
    },
  });

  return position === null ? null : (
    <Marker
      draggable
      position={position}
      icon={
        new L.Icon({
          iconUrl: MarkerIcon,
          iconRetinaUrl: MarkerIcon,
          iconSize: [25, 41],
          iconAnchor: [12.5, 41],
          popupAnchor: [0, -41],
          shadowUrl: MarkerShadow,
          shadowSize: [41, 41],
        })
      }
    />
  );
}

type TProps = {
  onChange: (lat: number, lng: number) => void;
  position?: {
    lat: number;
    lng: number;
  };
};

const ZoomControl = createControlComponent((props) => new Control.Locate(props));

export default function Map(props: TProps) {
  const { onChange, position = { lat: 35.7219, lng: 51.3347 } } = props;
  return (
    <div className="h-56">
      <MapContainer
        center={{ lat: position.lat, lng: position.lng }}
        zoom={props.position ? 13 : 10}
        scrollWheelZoom
        style={{ width: "100%", height: "100%" }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <LocationMarker onChange={onChange} position={new LatLng(position.lat, position.lng)} />
        <ZoomControl position="topleft" />
      </MapContainer>
    </div>
  );
}
