export default {
  title: "مدیریت Carousel",
  slides: "اسلایدها",
  addNewSlide: "افزودن اسلاید جدید",
  editSlide: "ویرایش اسلاید",
  titleFa: "عنوان فارسی اسلاید",
  titleEn: "عنوان انگلیسی اسلاید",
  description: "متن اسلاید",
  descriptionFa: "متن فارسی اسلاید",
  descriptionEn: "متن انگلیسی اسلاید",
  belowTextFa: "متن فارسی زیر دکمه اسلاید",
  belowTextEn: "متن انگلیسی زیر دکمه اسلاید",
  slideLink: "لینک اسلاید",
  image: "تصویر",
};
