import { ADMIN_PERMISSIONS, CATEGORIES_PERMISSIONS_TO_CATEGORIES_ID } from "@/consts/admin.const";
import { TAdminDetailed, TAdminPermissionItem, TAdminPermissions } from "@/types/admin";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function hasPermissions(profile: TAdminDetailed | null, allowedPermissions: TAdminPermissions[]) {
  if (!profile) return false;

  const isAllowed = profile?.permissions
    .filter((permission) => permission.value)
    .find((permission) => allowedPermissions.includes(permission.key));

  return !!isAllowed;
}

export function hasAllGivenPermissions(profile: TAdminDetailed | null, allowedPermissions: TAdminPermissions[]) {
  if (!profile) return false;

  const allowed = profile?.permissions.filter(
    (permission) => permission.value && allowedPermissions.includes(permission.key)
  );

  return allowed.length === allowedPermissions.length;
}

export function getProfile(): TAdminDetailed | null {
  const profile = window.localStorage.getItem("profile");
  if (!profile) return null;
  return JSON.parse(profile) as TAdminDetailed;
}

export function apiExecByPermission<T>(permissions: TAdminPermissions[], api: () => Promise<T>): Promise<T> {
  const profile = getProfile();

  if (!profile) return Promise.reject(1009);
  if (!hasPermissions(profile, permissions)) return Promise.reject(1010);

  return api();
}

export function getRouteByPermission(permissions: TAdminPermissionItem[]): string {
  const routes: Partial<Record<TAdminPermissions, string>> = {
    [ADMIN_PERMISSIONS.READ_ORDER]: "/orders",
    [ADMIN_PERMISSIONS.READ_USER]: "/users",
    [ADMIN_PERMISSIONS.READ_ADMIN]: "/admins",
    [ADMIN_PERMISSIONS.READ_STATICS]: "/website",
    [ADMIN_PERMISSIONS.READ_PRODUCT]: "/products",
    [ADMIN_PERMISSIONS.READ_TAG]: "/products",
    [ADMIN_PERMISSIONS.READ_CATEGORY]: "/products",
    [ADMIN_PERMISSIONS.READ_SUB_CATEGORY]: "/products",
    [ADMIN_PERMISSIONS.READ_BRAND]: "/products",
    [ADMIN_PERMISSIONS.READ_MODEL]: "/products",
  } as const;

  let foundPermission: string = "/";
  for (const key in routes) {
    const found = permissions.find((perm) => perm.key === key);
    if (found) {
      foundPermission = routes[key as TAdminPermissions]!;
    }
  }

  return foundPermission;
}

export function mapPermissionsToAllowedCategoryId(permissions: TAdminPermissionItem[]): string[] {
  const activePermissions = permissions.filter((perm) => {
    if (perm.value) {
      return Object.keys(CATEGORIES_PERMISSIONS_TO_CATEGORIES_ID).includes(perm.key);
    }
    return false;
  });

  const categoryIds = activePermissions.map((perm) => CATEGORIES_PERMISSIONS_TO_CATEGORIES_ID[perm.key]!);

  return categoryIds;
}
