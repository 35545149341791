import { API } from "@/lib/axios";
import { TTranslatedText } from "@/types/general";
import { TNewSubcategory, TSubcategory, TSubcategoryDetaield } from "@/types/subcategory";
import { AxiosResponse } from "axios";

const ENTITY = "/subcategory";

export const getById = async (id: string): Promise<AxiosResponse<{ data: TSubcategoryDetaield }>> =>
  API.get(`${ENTITY}/${id}`);

export const deleteById = async (id: string): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.delete(`${ENTITY}/${id}`);

export const addQAById = async (
  subcategoryId: string,
  data: { question: TTranslatedText; answer: TTranslatedText }
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.post(`${ENTITY}/faq-list/add`, { sub_category_id: subcategoryId, ...data });

export const deleteQAById = async (
  id: string,
  subcategoryId: string
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.delete(`${ENTITY}/faq-list/delete`, { data: { sub_category_id: subcategoryId, id } });

export const updateQAbyId = async (
  id: string,
  subcategoryId: string,
  data: { question: TTranslatedText; answer: TTranslatedText }
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.patch(`${ENTITY}/faq-list/update`, { sub_category_id: subcategoryId, id, ...data });

export const create = async (
  categoryId: string,
  data: TNewSubcategory
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.post(ENTITY, { ...data, category_id: categoryId });

export const update = async (
  id: string,
  data: TNewSubcategory
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> => API.patch(ENTITY, { id, ...data });

export const getListByCategoryId = async (
  subcategoryId: string
): Promise<AxiosResponse<{ data: { items: TSubcategory[] } }>> => API.get(`${ENTITY}/list/${subcategoryId}`);
