export const ORDER_STATUS = {
  WAITING_FOR_PAYMENT: 0,
  PAID: 1,
  SENDING: 2,
  DELIVERED: 3,
  CANCELED: 4,
  PENDING_FOR_RETURN: 5,
  PENDING_FOR_WITHDRAWAL: 6,
  RETURNED: 7,
} as const;
