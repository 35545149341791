import { ProductAPI } from "@/api";
import { ADMIN_PERMISSIONS } from "@/consts/admin.const";
import { PRODUCT_SEARCH_SORTS } from "@/consts/product.const";
import { apiExecByPermission } from "@/lib/utils";

export default async function loader() {
  return Promise.all([
    apiExecByPermission([ADMIN_PERMISSIONS.READ_PRODUCT], () => ProductAPI.getCounts()),
    apiExecByPermission([ADMIN_PERMISSIONS.READ_PRODUCT], () =>
      ProductAPI.getList({ pagination: { page: 1, limit: 10 }, sorting: PRODUCT_SEARCH_SORTS.CREATED_AT_DSC })
    ),
  ])
    .then(([counts, products]) => {
      return { counts: counts.data.data.items, products: products.data.data };
    })
    .catch((err) => {
      if (err === 1009) {
        return {};
      }
      return null;
    });
}
