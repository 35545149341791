import CountCard from "@/components/card-count";
import ListData from "@/components/list-data";
import Page from "@/components/page";
import Pagination from "@/components/pagination";
import { buttonVariants } from "@/components/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { USER_STATUS } from "@/consts/user.const";
import { TUsersList } from "@/types/general";
import { TUserStatus } from "@/types/user";
import { Trans, useTranslation } from "react-i18next";
import { Link, useLoaderData } from "react-router-dom";

export default function UsersList() {
  const data = useLoaderData() as TUsersList | null;
  const { t } = useTranslation();

  const STATUS_LABEL: Record<TUserStatus, React.ReactNode> = {
    [USER_STATUS.active]: (
      <span className="text-green-600">
        <Trans i18nKey="users.statusActive" />
      </span>
    ),
    [USER_STATUS.deactivated]: (
      <span className="text-destructive">
        <Trans i18nKey="users.statusInactive" />
      </span>
    ),
  };

  // TODO: implement Error Page
  if (!data) return <div>Error</div>;

  return (
    <Page breadcrumbs={[{ label: t("sidebar.home"), link: "/" }, { label: t("users.title") }]} title={t("users.title")}>
      <div className="grid grid-cols-3 gap-2 mb-4">
        <CountCard count={data.total_users} title={t("users.totalUsers")} />
        <CountCard count={data.total_users_in_month} title={t("users.totalUsersInMonth")} />
        <CountCard count={data.total_customers} title={t("users.totalCustomers")} />
      </div>
      <ListData>
        {data.items.length ? (
          <Table className="bg-gray-scale-5">
            <TableHeader>
              <TableRow>
                <TableHead className="text-right">
                  <Trans i18nKey="users.userName" />
                </TableHead>
                <TableHead className="text-right">
                  <Trans i18nKey="users.userPhone" />
                </TableHead>
                <TableHead className="text-right">
                  <Trans i18nKey="users.registerDate" />
                </TableHead>
                <TableHead className="text-right">
                  <Trans i18nKey="users.status" />
                </TableHead>
                <TableHead />
              </TableRow>
            </TableHeader>
            <TableBody>
              {data.items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>
                    {item.first_name ?? "---"}&nbsp;{item.last_name ?? "---"}
                  </TableCell>
                  <TableCell className="dir-ltr">
                    {item.phone_number.replace(/(.{4})(.{3})(.{4})/, "$1 $2 $3")}
                  </TableCell>
                  <TableCell>
                    {new Intl.DateTimeFormat("fa-IR", { day: "2-digit", month: "long", year: "numeric" }).format(
                      new Date(item.register_date)
                    )}
                  </TableCell>
                  <TableCell>{STATUS_LABEL[item.status]}</TableCell>
                  <TableCell className="text-center">
                    <Link to={`/users/${item.id}`} className={buttonVariants({ variant: "secondary" })}>
                      <Trans i18nKey="users.showDetail" />
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <div className="text-center text-gray-scale-4">
            <Trans i18nKey="common.isEmpty" />
          </div>
        )}
      </ListData>
      {data.items.length ? (
        <Pagination className="mt-4" path={"/users"} currentPage={data.page} totalPage={data.total_pages} />
      ) : null}
    </Page>
  );
}
