import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./providers/auth-provider";

export default function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const auth = useAuth();
  const location = useLocation();

  // user doesn't have a token
  if (!auth.isLoggedIn) {
    return <Navigate to={`/auth/login?from=${location.pathname}`} replace />;
  }

  return children;
}
