import { CategoryAPI } from "@/api";

export default async function loader() {
  try {
    const resp = await CategoryAPI.getList();
    return resp.data.data.items;
  } catch (err) {
    {
      return [];
    }
  }
}
