import { SubcategoryAPI } from "@/api";
import { ADMIN_PERMISSIONS } from "@/consts/admin.const";
import { apiExecByPermission } from "@/lib/utils";
import { LoaderFunctionArgs } from "react-router-dom";

export default async function loader({ params }: LoaderFunctionArgs) {
  const { subcategoryId } = params as { categoryId: string; subcategoryId: string };

  try {
    const resp = await apiExecByPermission([ADMIN_PERMISSIONS.READ_SUB_CATEGORY], () =>
      SubcategoryAPI.getById(subcategoryId)
    );
    return resp.data.data;
  } catch (error) {
    return null;
  }
}
