import { API } from "@/lib/axios";
import { TAdminDetailed, TAdminForm, TAdminsList, TAdminsQuery } from "@/types/admin";
import { TTranslatedText } from "@/types/general";
import { AxiosResponse } from "axios";

const ENTITY = "/admins";

export const upload = async (
  fd: FormData
): Promise<AxiosResponse<{ data: { url: string; mim_type: string; size: number } }>> =>
  API.post(`${ENTITY}/upload-image`, fd);

export const getProfile = async (): Promise<AxiosResponse<{ data: TAdminDetailed }>> => API.get(`${ENTITY}/profile`);

export const getList = async (query: TAdminsQuery): Promise<AxiosResponse<{ data: TAdminsList }>> =>
  API.post(`${ENTITY}/list`, query);

export const getProfileById = async (id: string): Promise<AxiosResponse<{ data: TAdminDetailed }>> =>
  API.get(`${ENTITY}/profile/${id}`);

export const updateProfile = async (data: TAdminForm): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.patch(`${ENTITY}/update`, data);

export const deleleteById = async (id: string): Promise<AxiosResponse<{ data: TAdminDetailed }>> =>
  API.delete(`${ENTITY}/delete/${id}`);
