import { CategoryAPI } from "@/api";
import ListData from "@/components/list-data";
import Page from "@/components/page";
import { useAuth } from "@/components/providers/auth-provider";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button, buttonVariants } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";
import { ADMIN_PERMISSIONS } from "@/consts/admin.const";
import { cn, hasPermissions } from "@/lib/utils";
import { TCategory } from "@/types/category";
import { isAxiosError } from "axios";
import { Edit2, FileText, Trash2 } from "lucide-react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useLoaderData, useRevalidator } from "react-router-dom";

export default function ProductsCategoriesPage() {
  const data = useLoaderData() as TCategory[] | null;
  const { t } = useTranslation();
  const revalidator = useRevalidator();
  const { profile } = useAuth();

  const [isOpenDelete, setIsOpenDelete] = React.useState(false);
  const [deletingItem, setDeletingItem] = React.useState("");
  const [loadingDelete, setLoadingDelete] = React.useState(false);

  const onOpenDelete = (id: string) => {
    setIsOpenDelete(true);
    setDeletingItem(id);
  };

  const onCloseDelete = () => {
    setIsOpenDelete(false);
    setDeletingItem("");
  };

  const onDelete = async () => {
    try {
      setLoadingDelete(true);
      const resp = await CategoryAPI.deleteById(deletingItem);
      revalidator.revalidate();
      toast({ description: resp.data.data.message.fa });
    } catch (err) {
      if (isAxiosError(err)) {
        return toast({
          description: err.response?.data.message,
          variant: "destructive",
        });
      }
      if (err instanceof Error) {
        return toast({
          description: err.message,
          variant: "destructive",
        });
      }
    } finally {
      setLoadingDelete(false);
      onCloseDelete();
    }
  };

  // TODO: Implement Error Page
  if (!data) return <div>Error</div>;

  return (
    <Page
      breadcrumbs={[
        { label: t("sidebar.home"), link: "/" },
        { label: t("productsManagement.title"), link: "/products" },
        { label: t("categories.title") },
      ]}
      title={t("categories.title")}
    >
      <ListData
        actions={
          <Link
            to="add"
            className={buttonVariants({
              className: cn({ hidden: !hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_CATEGORY]) }),
            })}
          >
            <Trans i18nKey="common.addNew" />
          </Link>
        }
      >
        <div className="grid grid-cols-2 gap-4">
          {data.length ? (
            data.map((item) => (
              <div key={item.id} className="bg-gray-scale-5 p-2 flex items-center gap-2">
                <img src={item.thumbnail} alt={item.title.fa} className="bg-white w-[70px] h-[50px] object-cover" />
                <div className="flex-1">
                  <span>{item.title.fa}</span>
                </div>
                <div>
                  <Link to={`/logs?entity=${item.id}`} className={buttonVariants({ variant: "ghost", size: "icon" })}>
                    <FileText size={18} />
                  </Link>
                  <Link to={item.id} className={buttonVariants({ variant: "ghost", size: "icon" })}>
                    <Edit2 size={18} />
                  </Link>
                  <AlertDialog open={isOpenDelete}>
                    <AlertDialogTrigger
                      asChild
                      className={cn({ hidden: !hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_CATEGORY]) })}
                    >
                      <Button variant="ghost-destructive" size="icon" onClick={() => onOpenDelete(item.id)}>
                        <Trash2 size={20} />
                      </Button>
                    </AlertDialogTrigger>
                    <AlertDialogContent>
                      <AlertDialogHeader>
                        <AlertDialogTitle className="text-destructive">
                          <Trans i18nKey="categories.delete" />
                        </AlertDialogTitle>
                        <AlertDialogDescription>
                          <Trans i18nKey="categories.deleteDesc" />
                        </AlertDialogDescription>
                      </AlertDialogHeader>
                      <div className="flex justify-end gap-2">
                        <Button variant="outline" onClick={onCloseDelete}>
                          <Trans i18nKey="common.cancel" />
                        </Button>
                        <Button variant="destructive" onClick={onDelete} loading={loadingDelete}>
                          <Trans i18nKey="common.submit" />
                        </Button>
                      </div>
                    </AlertDialogContent>
                  </AlertDialog>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center text-gray-scale-4">
              <Trans i18nKey="common.isEmpty" />
            </div>
          )}
        </div>
      </ListData>
    </Page>
  );
}
