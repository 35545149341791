import { UsersAPI } from "@/api";
import { ADMIN_PERMISSIONS } from "@/consts/admin.const";
import { apiExecByPermission } from "@/lib/utils";
import { LoaderFunctionArgs } from "react-router-dom";

export default async function loader({ params }: LoaderFunctionArgs) {
  const { id } = params as { id: string };
  if (!id) return null;
  return Promise.all([
    apiExecByPermission([ADMIN_PERMISSIONS.READ_USER], () => UsersAPI.getProfileById(id)),
    apiExecByPermission([ADMIN_PERMISSIONS.READ_USER], () => UsersAPI.getAddressesById(id)),
  ])
    .then(([profile, addresses]) => {
      return { profile: profile.data.data, addresses: addresses.data.data.items };
    })
    .catch(() => null);
}
