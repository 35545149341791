import { ChevronRight } from "lucide-react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { buttonVariants } from "./ui/button";

type TProps = {
  children: React.ReactNode;
  title: string;
  action?: React.ReactNode;
  breadcrumbs: { label: string; link?: string }[];
  classes?: { container?: string };
};

export default function Page(props: TProps) {
  const { children, title, action, breadcrumbs, classes } = props;
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>
          {t("common.docTitle")}
          &nbsp;|&nbsp;{title}
        </title>
      </Helmet>
      <ul className="flex items-center dir-ltr text-left">
        {breadcrumbs.map((item, index) => (
          <li key={uuidv4()} className="flex items-center">
            {index < breadcrumbs.length - 1 ? (
              <>
                <ChevronRight size={16} />
                <Link to={item.link!} className={buttonVariants({ variant: "link" })}>
                  {item.label}
                </Link>
              </>
            ) : (
              <span className={buttonVariants({ variant: "link", className: "text-primary pointer-events-none" })}>
                {item.label}
              </span>
            )}
          </li>
        ))}
      </ul>
      <div className="flex justify-between items-center my-4">
        <h1 className="text-2xl font-bold">{title}</h1>
        {action ? <div className="flex-1 flex justify-end items-center gap-1">{action}</div> : null}
      </div>
      <div className={classes?.container}>{children}</div>
    </>
  );
}
