import { OrderAPI } from "@/api";
import Page from "@/components/page";
import { useAuth } from "@/components/providers/auth-provider";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { toast } from "@/components/ui/use-toast";
import { ADMIN_PERMISSIONS } from "@/consts/admin.const";
import { ORDER_STATUS } from "@/consts/order.const";
import { hasPermissions } from "@/lib/utils";
import { TOrderDetailed, TOrderStatus } from "@/types/order";
import { addCommas } from "@persian-tools/persian-tools";
import { Check, Loader2 } from "lucide-react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLoaderData, useRevalidator } from "react-router-dom";
import InfoCard from "./info-card";
import ProductsList from "./products-list";
import UserInfo from "./user-info";

export default function OrderDetailPage() {
  const data = useLoaderData() as TOrderDetailed | null;
  const { t } = useTranslation();
  const revalidator = useRevalidator();
  const [status, setStatus] = React.useState(data?.status.status ?? 0);
  const [loadingStatus, setLoadingStatus] = React.useState<TOrderStatus | null>(null);
  const { profile } = useAuth();

  const updateStatus = async (status: TOrderStatus) => {
    try {
      setLoadingStatus(status);
      const resp = await OrderAPI.updateStatus(data!.id, status);
      setStatus(status);
      revalidator.revalidate();
      toast({
        description: resp.data.data.message.fa,
      });
    } catch (err) {
      if (err instanceof Error) {
        toast({
          variant: "destructive",
          description: err.message,
        });
      }
    } finally {
      setLoadingStatus(null);
    }
  };

  const STATUS_OPTIONS = [
    {
      value: ORDER_STATUS.WAITING_FOR_PAYMENT.toString(),
      label: t("orders.status.waitingForPayment"),
    },
    {
      value: ORDER_STATUS.PAID.toString(),
      label: t("orders.status.paid"),
    },
    {
      value: ORDER_STATUS.SENDING.toString(),
      label: t("orders.status.sending"),
    },
    {
      value: ORDER_STATUS.DELIVERED.toString(),
      label: t("orders.status.delivered"),
    },
    {
      value: ORDER_STATUS.CANCELED.toString(),
      label: t("orders.status.canceled"),
    },
    {
      value: ORDER_STATUS.PENDING_FOR_RETURN.toString(),
      label: t("orders.status.pendingForReturn"),
    },
    {
      value: ORDER_STATUS.PENDING_FOR_WITHDRAWAL.toString(),
      label: t("orders.status.pendingForWithdrawal"),
    },
    {
      value: ORDER_STATUS.RETURNED.toString(),
      label: t("orders.status.returned"),
    },
  ];

  const getDiscountValue = () => {
    const discount = (data?.payment?.final_price ?? 0) - (data?.payment?.final_price_after_discount ?? 0);
    return discount;
  };

  const PAYMENT_TYPE = {
    bank_transaction: t("ordersDetail.bankTransaction"),
  };

  // TODO: implement Error Page
  if (!data) return <div>Error</div>;

  return (
    <Page
      breadcrumbs={[
        { label: t("sidebar.home"), link: "/" },
        { label: t("orders.title"), link: "/orders" },
        { label: t("ordersDetail.label") },
      ]}
      title={t("ordersDetail.title", { code: data.custom_id })}
    >
      <div>
        <ToggleGroup
          disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_ORDER])}
          className="dir-rtl bg-gray-scale-5 justify-start"
          type="single"
          value={status.toString()}
          onValueChange={(status) => updateStatus(+status as TOrderStatus)}
        >
          {STATUS_OPTIONS.map((option) => (
            <ToggleGroupItem key={option.value} value={option.value} className="gap-1">
              {loadingStatus?.toString() === option.value ? (
                <Loader2 size={14} className="animate-spin" />
              ) : data.status.status === +option.value ? (
                <Check size={14} />
              ) : null}

              {option.label}
            </ToggleGroupItem>
          ))}
        </ToggleGroup>
      </div>
      <div className="flex gap-8 py-4">
        {/* Products */}
        <div className="w-2/3">
          <div>
            <ProductsList list={data.products} />
          </div>
          <div className="mt-4 space-y-2">
            <UserInfo firstName={data.personal_info.first_name} lastName={data.personal_info.last_name} />
            {data.address ? (
              <InfoCard title={t("common.address")}>
                <div className="flex flex-col gap-1">
                  <span>{data.address.title}</span>
                  <Trans
                    i18nKey="ordersDetail.postalAddress"
                    components={[<p className="text-gray-400 text-sm" />]}
                    values={{ address: data.address.postal_address, no: data.address.no, floor: data.address.floor }}
                  />
                  <Trans
                    i18nKey="ordersDetail.postalCode"
                    components={[<p className="text-gray-400 text-sm" />]}
                    values={{ code: data.address.postal_code }}
                  />
                </div>
              </InfoCard>
            ) : null}
            <InfoCard title={t("common.receiver")}>
              <div className="flex flex-col gap-1">
                <span>
                  {data.personal_info.first_name}&nbsp;{data.personal_info.last_name}
                </span>
                <Trans
                  i18nKey="ordersDetail.phone"
                  components={[<p className="text-gray-400 text-sm" />]}
                  values={{ phone: data.personal_info.phone_number }}
                />
                <div className="flex gap-1">
                  <Trans
                    i18nKey="ordersDetail.email"
                    components={[
                      <p className="text-gray-400 text-sm" />,
                      <span className="font-montserrat text-gray-400 text-sm" />,
                    ]}
                    values={{ email: data.personal_info.email }}
                  />
                </div>
                {data.personal_info.national_id ? (
                  <Trans
                    i18nKey="ordersDetail.nationalCode"
                    components={[<p className="text-gray-400 text-sm" />]}
                    values={{ code: data.personal_info.national_id.trim() }}
                  />
                ) : null}
              </div>
            </InfoCard>
            <InfoCard title={t("common.date")}>
              <div className="grid grid-cols-2">
                {data.date ? (
                  <div className="flex flex-col gap-1">
                    <span className="text-gray-400 text-sm">
                      <Trans i18nKey="ordersDetail.requestDate" />
                    </span>
                    <span className="text-sm">{new Intl.DateTimeFormat("fa-IR").format(new Date(data.date))}</span>
                  </div>
                ) : null}
                {/* TODO: add received date*/}
                <div>
                  <div className="flex flex-col gap-1">
                    <span className="text-gray-400 text-sm">
                      <Trans i18nKey="ordersDetail.receivedDate" />
                    </span>
                    {/* {false ? (
                      <span className="text-sm">{new Intl.DateTimeFormat("fa-IR").format(new Date())}</span>
                    ) : (
                      <span>
                        <Trans i18nKey="common.unknown" />
                      </span>
                    )} */}
                  </div>
                </div>
              </div>
            </InfoCard>
            <InfoCard title={t("ordersDetail.code")}>
              <div className="h-full flex items-center text-gray-300 font-montserrat">{data.custom_id}</div>
            </InfoCard>
          </div>
        </div>

        {/* Price */}
        <div className="w-1/3 pt-10">
          <div className="p-4 bg-gray-scale-5 space-y-1">
            <div className="flex items-center justify-between">
              <span className="text-gray-400">
                <Trans i18nKey="ordersDetail.products" />
              </span>
              <span>
                <Trans i18nKey="common.toman" values={{ value: addCommas(data?.payment?.final_price ?? "0") }} />
              </span>
            </div>
            {getDiscountValue() ? (
              <div className="flex items-center justify-between">
                <span className="text-gray-400">
                  <Trans i18nKey="common.discount" />
                </span>
                <span>
                  <Trans i18nKey="common.toman" values={{ value: addCommas(getDiscountValue()) }} />
                </span>
              </div>
            ) : null}
            <div className="p-4">
              <div className="flex flex-col gap-2 items-center justify-center">
                <span className="text-2xl text-primary">
                  <Trans i18nKey="common.toman" values={{ value: addCommas(data?.payment?.final_price ?? "0") }} />
                </span>
                {data?.payment?.payment_type ? (
                  <span className="text-sm text-gray-400">{PAYMENT_TYPE[data.payment.payment_type]}</span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}
