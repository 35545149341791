import { CommentsAPI } from "@/api";
import { TComment } from "@/types/comment";
import { Trash2 } from "lucide-react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useRevalidator } from "react-router-dom";
import Rating from "./rating";
import { Button } from "./ui/button";
import { toast } from "./ui/use-toast";
import { COMMENT_STATUS } from "@/consts/comments.const";

type TProps = { data: TComment };
export default function CommentCard(props: TProps) {
  const { data } = props;
  const revalidator = useRevalidator();
  const { t } = useTranslation();
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [rejectLoading, setRejectLoading] = React.useState(false);
  const [deleteLoading, setDeleteLoading] = React.useState(false);

  const STATUS_LABELS = {
    [COMMENT_STATUS.CONFIRMED]: t("comments.confirmed"),
    [COMMENT_STATUS.REJECTED]: t("comments.rejected"),
  };

  const primaryProductImage = data.product.images.find((item) => item.is_primary);

  const onConfirm = async () => {
    try {
      setConfirmLoading(true);
      const resp = await CommentsAPI.confirmById(data.id);
      revalidator.revalidate();
      toast({ description: resp.data.data.message.fa });
    } catch (err) {
      toast({ description: (err as Error).message, variant: "destructive" });
    } finally {
      setConfirmLoading(false);
    }
  };

  const onReject = async () => {
    try {
      setRejectLoading(true);
      const resp = await CommentsAPI.rejectById(data.id);
      revalidator.revalidate();
      toast({ description: resp.data.data.message.fa });
    } catch (err) {
      toast({ description: (err as Error).message, variant: "destructive" });
    } finally {
      setRejectLoading(false);
    }
  };

  const onDelete = async () => {
    try {
      setDeleteLoading(true);
      const resp = await CommentsAPI.deleteById(data.id);
      revalidator.revalidate();
      toast({ description: resp.data.data.message.fa });
    } catch (err) {
      toast({ description: (err as Error).message, variant: "destructive" });
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <div className="bg-gray-scale-5 p-4">
      <header className="flex items-center">
        <div className="text-xl flex-1">
          {data.user.name}&nbsp;
          {data.user.purchased_before ? (
            <span className="text-xs inline-block p-2 text-primary bg-white/5">
              <Trans i18nKey="comments.purchasedUser" />
            </span>
          ) : null}
        </div>
        <div className="flex flex-col items-end">
          <div className="flex items-center gap-2 dir-ltr">
            <span className="text-sm text-gray-scale-3">{data.rating_star}</span>
            <Rating stars={data.rating_star} className="flex-row-reverse" />
          </div>
          <span className="text-sm text-gray-scale-3">
            {new Intl.DateTimeFormat("fa-IR", { day: "2-digit", month: "long", year: "numeric" }).format(
              new Date(data.date)
            )}
          </span>
        </div>
      </header>
      <div className="space-y-1 pb-2 border-b border-white/5">
        <h3>{data.title}</h3>
        <p>{data.body}</p>
      </div>
      <footer className="mt-2 flex items-center">
        <div className="flex items-center flex-1">
          {primaryProductImage ? (
            <img src={primaryProductImage.thumbnail} className="w-[58px] h-[58px] bg-white object-cover ml-4" />
          ) : null}
          <div>
            <h3 className="mb-2 font-montserrat">{data.product.name}</h3>
            <div className="flex items-center text-gray-scale-4 text-xs">
              <span>{data.product.category.title.fa}</span>&nbsp;|&nbsp;
              <div className="flex items-center gap-2">
                <img src={data.product.brand.logo} className="h-4" />
                <span className="font-montserrat">{data.product.brand.name.en}</span>
              </div>
              {data.product.price.is_used ? (
                <>
                  &nbsp;|&nbsp;
                  <span>
                    <Trans i18nKey="comments.isUsed" />
                  </span>
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2">
          {data.status === COMMENT_STATUS.PENDING ? (
            <div className="w-64 grid grid-cols-2 gap-2">
              <Button onClick={onConfirm} loading={confirmLoading} disabled={deleteLoading || rejectLoading}>
                <Trans i18nKey="comments.confirm" />
              </Button>
              <Button
                onClick={onReject}
                loading={rejectLoading}
                disabled={deleteLoading || confirmLoading}
                variant="secondary"
              >
                <Trans i18nKey="comments.reject" />
              </Button>
            </div>
          ) : (
            <div className="w-44 py-2 bg-gray-scale-5 text-gray-scale-4 flex items-center justify-center">
              {STATUS_LABELS[data.status]}
            </div>
          )}
          <Button
            onClick={onDelete}
            size="icon"
            variant="destructive"
            disabled={rejectLoading || confirmLoading}
            loading={deleteLoading}
          >
            <Trash2 size={20} />
          </Button>
        </div>
      </footer>
    </div>
  );
}
