import { ModelAPI } from "@/api";
import ListData from "@/components/list-data";
import Page from "@/components/page";
import { useAuth } from "@/components/providers/auth-provider";
import { Button, buttonVariants } from "@/components/ui/button";
import { Dialog, DialogClose, DialogContent, DialogHeader } from "@/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { toast } from "@/components/ui/use-toast";
import { ADMIN_PERMISSIONS } from "@/consts/admin.const";
import { cn, hasPermissions } from "@/lib/utils";
import { TBrand } from "@/types/brand";
import { TModel } from "@/types/model";
import { zodResolver } from "@hookform/resolvers/zod";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { FileText, Loader2, Pencil, Trash2 } from "lucide-react";
import React from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Link, useLoaderData, useNavigation, useRevalidator, useSearchParams } from "react-router-dom";
import { z } from "zod";

export default function ProductsModels() {
  const data = (useLoaderData() as { models: TModel[]; brands: TBrand[] }) || null;
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigation = useNavigation();
  const revalidator = useRevalidator();
  const [brand, setBrand] = React.useState(searchParams.get("brand") ?? "");
  const [deletingId, setDeletingId] = React.useState("");
  const [isOpen, setIsOpen] = React.useState("");
  const [openNew, setOpenNew] = React.useState(false);
  const { profile } = useAuth();

  const formSchema = z.object({
    name: z.string().min(1, { message: t("common.required") }),
    brand: z.string().min(1, { message: t("common.required") }),
    id: z.string().optional(),
  });
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      brand: "",
      name: "",
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      if (values.id) {
        const resp = await ModelAPI.updateById(values.id, {
          name: values.name,
          brand: values.brand,
        });
        revalidator.revalidate();
        toast({
          description: resp.data.data.message.fa,
        });
      } else {
        const resp = await ModelAPI.create({
          name: values.name,
          brand: values.brand,
        });
        revalidator.revalidate();
        toast({
          description: resp.data.data.message.fa,
        });
      }
      setOpenNew(false);
      setIsOpen("");
      form.reset({ name: "", brand: "", id: "" });
    } catch (err) {
      console.log(err);
      toast({
        variant: "destructive",
        description: (err as Error).message,
      });
    }
  };

  const onDelete = async (id: string) => {
    try {
      setDeletingId(id);
      const resp = await ModelAPI.deleteById(id);
      revalidator.revalidate();
      toast({
        description: resp.data.data.message.fa,
      });
    } catch (err) {
      console.log(err);
      toast({
        variant: "destructive",
        description: (err as Error).message,
      });
    } finally {
      setDeletingId("");
    }
  };

  // TODO: Implement Error page
  if (!data) return <div>Error</div>;

  return (
    <Page
      breadcrumbs={[
        { label: t("sidebar.home"), link: "/" },
        { label: t("productsManagement.title"), link: "/products" },
        { label: t("models.title") },
      ]}
      title={t("models.title")}
      action={
        <Dialog
          open={openNew}
          onOpenChange={(open) => {
            if (!open) {
              form.reset({ brand: "", id: "", name: "" });
            }
            setOpenNew(open);
          }}
        >
          <DialogTrigger asChild className={cn({ hidden: !hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_MODEL]) })}>
            <Button>
              <Trans i18nKey="common.addNew" />
            </Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <Trans i18nKey="models.addNew" />
            </DialogHeader>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                <FormField
                  control={form.control}
                  name="brand"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        <Trans i18nKey="models.brands" />
                      </FormLabel>
                      <FormControl>
                        <Select value={field.value} onValueChange={field.onChange}>
                          <SelectTrigger>
                            <SelectValue placeholder={t("models.brands")} />
                          </SelectTrigger>
                          <SelectContent>
                            {data.brands?.map((brand) => (
                              <SelectItem key={brand.id} value={brand.id}>
                                <div className="flex items-center gap-1">
                                  <img src={brand.logo} className="h-8 w-9" alt={brand.name.en} />
                                  <span className="font-montserrat">{brand.name.en}</span>
                                </div>
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        <Trans i18nKey="models.name" />
                      </FormLabel>
                      <FormControl>
                        <Input
                          placeholder={t("models.name")}
                          {...field}
                          className="font-montserrat text-left dir-ltr placeholder:text-right placeholder:dir-rtl"
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <div className="flex justify-end gap-2">
                  <DialogClose asChild>
                    <Button variant="secondary" onClick={() => form.reset({ name: "", brand: "" })}>
                      <Trans i18nKey="common.cancel" />
                    </Button>
                  </DialogClose>
                  <Button type="submit" loading={form.formState.isSubmitting}>
                    <Trans i18nKey="common.submit" />
                  </Button>
                </div>
              </form>
            </Form>
          </DialogContent>
        </Dialog>
      }
    >
      <ListData
        actionsClassNames="flex items-center gap-2"
        actions={
          <>
            {navigation.state === "loading" ? <Loader2 className="text-primary animate-spin" /> : null}
            {/* Brands Select */}
            <Select
              value={brand}
              onValueChange={(value) => {
                setBrand(value);
                setSearchParams((prevParams) => {
                  prevParams.set("brand", value);
                  return prevParams;
                });
              }}
            >
              <SelectTrigger className="w-[160px]">
                <SelectValue placeholder={t("models.brands")} />
              </SelectTrigger>
              <SelectContent>
                {data.brands?.map((brand) => (
                  <SelectItem key={brand.id} value={brand.id}>
                    <div className="flex items-center gap-1">
                      <img src={brand.logo} className="h-8 w-9" alt={brand.name.en} />
                      <span className="font-montserrat">{brand.name.en}</span>
                    </div>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Button
              variant="ghost"
              disabled={!brand}
              onClick={() => {
                setBrand("");
                setSearchParams({});
              }}
            >
              <Trans i18nKey="prices.clearSearch" />
            </Button>
          </>
        }
      >
        {navigation.state === "loading" ? (
          <div className="flex items-center justify-center">
            <Loader2 className="text-primary animate-spin" />
          </div>
        ) : brand.length ? (
          data.models.length ? (
            <ul className="grid grid-cols-4 gap-2">
              {data.models.map((model) => (
                <li key={model.id}>
                  <div className="bg-gray-scale-5 p-4 h-full min-h-[120px] flex flex-col">
                    <span className="flex-1 dir-ltr text-left font-montserrat">{model.name}</span>
                    {hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_MODEL]) ? (
                      <div className="flex items-center justify-start">
                        <Link
                          to={`/logs?entity=${model.id}`}
                          className={buttonVariants({ variant: "ghost", size: "icon" })}
                        >
                          <FileText size={18} />
                        </Link>
                        <Dialog
                          open={isOpen === model.id}
                          onOpenChange={(open) => {
                            if (!open) {
                              setIsOpen("");
                            }
                            setIsOpen(open ? model.id : "");
                          }}
                        >
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={() => {
                              form.reset({ name: model.name, brand: brand });
                              setIsOpen(model.id);
                            }}
                          >
                            <Pencil size={18} />
                          </Button>
                          <DialogContent>
                            <DialogHeader>
                              <Trans i18nKey="models.editModel" values={{ name: model.name }} />
                            </DialogHeader>
                            <Form {...form}>
                              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                                <input className="hidden" value={model.id} {...form.register("id")} />
                                <FormField
                                  control={form.control}
                                  name="brand"
                                  render={({ field }) => (
                                    <FormItem>
                                      <FormLabel>
                                        <Trans i18nKey="models.brands" />
                                      </FormLabel>
                                      <FormControl>
                                        <Select value={field.value} onValueChange={field.onChange}>
                                          <SelectTrigger>
                                            <SelectValue placeholder={t("models.brands")} />
                                          </SelectTrigger>
                                          <SelectContent>
                                            {data.brands?.map((brand) => (
                                              <SelectItem key={brand.id} value={brand.id}>
                                                <div className="flex items-center gap-1">
                                                  <img src={brand.logo} className="h-8 w-9" alt={brand.name.en} />
                                                  <span className="font-montserrat">{brand.name.en}</span>
                                                </div>
                                              </SelectItem>
                                            ))}
                                          </SelectContent>
                                        </Select>
                                      </FormControl>
                                      <FormMessage />
                                    </FormItem>
                                  )}
                                />
                                <FormField
                                  control={form.control}
                                  name="name"
                                  render={({ field }) => (
                                    <FormItem>
                                      <FormLabel>
                                        <Trans i18nKey="models.name" />
                                      </FormLabel>
                                      <FormControl>
                                        <Input
                                          placeholder={t("models.name")}
                                          {...field}
                                          className="font-montserrat text-left dir-ltr placeholder:text-right placeholder:dir-rtl"
                                        />
                                      </FormControl>
                                      <FormMessage />
                                    </FormItem>
                                  )}
                                />

                                <div className="flex justify-end gap-2">
                                  <DialogClose asChild>
                                    <Button
                                      variant="secondary"
                                      onClick={() => form.reset({ name: "", brand: "", id: "" })}
                                    >
                                      <Trans i18nKey="common.cancel" />
                                    </Button>
                                  </DialogClose>
                                  <Button type="submit" loading={form.formState.isSubmitting}>
                                    <Trans i18nKey="common.submit" />
                                  </Button>
                                </div>
                              </form>
                            </Form>
                          </DialogContent>
                        </Dialog>
                        <Button
                          loading={deletingId === model.id}
                          variant="ghost-destructive"
                          size="icon"
                          onClick={() => onDelete(model.id)}
                        >
                          <Trash2 size={20} />
                        </Button>
                      </div>
                    ) : null}
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <div className="text-gray-scale-4 text-center">
              <Trans i18nKey="common.isEmpty" />
            </div>
          )
        ) : (
          <div className="text-gray-scale-4 text-center">
            <Trans i18nKey="models.SelectBrand" />
          </div>
        )}
      </ListData>
    </Page>
  );
}
