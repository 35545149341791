import { CategoryAPI } from "@/api";
import CategoryForm from "@/components/form-category";
import Page from "@/components/page";
import { toast } from "@/components/ui/use-toast";
import { TNewCategory } from "@/types/category";
import { isAxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function CategoryAdd() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmitForm = async (category: TNewCategory) => {
    try {
      const resp = await CategoryAPI.create(category);
      toast({ description: resp.data.data.message.fa });
      navigate(-1);
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          description: err.response?.data.message,
          variant: "destructive",
        });
        return;
      }
      if (err instanceof Error) {
        toast({
          description: err.message,
          variant: "destructive",
        });
      }
    }
  };

  return (
    <Page
      breadcrumbs={[
        { label: t("sidebar.home"), link: "/" },
        { label: t("productsManagement.title"), link: "/products" },
        { label: t("categories.title"), link: "/products/categories" },
        { label: t("categories.newItem") },
      ]}
      title={t("categories.newItem")}
    >
      <CategoryForm onSubmitForm={onSubmitForm} />
    </Page>
  );
}
