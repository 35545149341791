type TProps = {
  title: string;
  children: React.ReactNode;
};
export default function InfoCard(props: TProps) {
  const { title, children } = props;
  return (
    <div className="bg-gray-scale-5 flex gap-4 min-h-[70px]">
      <h3 className="bg-white/5 w-[30px] flex items-center justify-center">
        <span className="inline-block whitespace-nowrap rotate-[90deg] text-[12px]  text-gray-scale-4">{title}</span>
      </h3>
      <div className="flex-1 py-4">{children}</div>
    </div>
  );
}
