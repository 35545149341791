import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { CommandList } from "cmdk";
import { ChevronsUpDown, PlusIcon } from "lucide-react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

export type OptionType = {
  label: string;
  value: string;
};

interface MultiSelectProps {
  options: OptionType[];
  selected: string[];
  onChange: React.Dispatch<React.SetStateAction<string[]>>;
  className?: string;
  placeholder?: string;
  noSearch?: boolean;
  disabled?: boolean;
}

export default function MultiSelect(props: MultiSelectProps) {
  const { options, selected, onChange, className, placeholder, noSearch = false, disabled, ...rest } = props;
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const getLabeled = () => {
    return options.filter((i) => selected.includes(i.value)) ?? [];
  };

  return (
    <Popover open={open} onOpenChange={setOpen} {...rest}>
      <PopoverTrigger asChild disabled={disabled}>
        <Button
          variant="input"
          role="combobox"
          aria-expanded={open}
          className={cn("w-full justify-between h-auto xl:h-10 !p-2", { "cursor-not-allowed": disabled })}
          onClick={() => setOpen(!open)}
        >
          <div className="flex items-start flex-1 gap-1">
            {selected.length ? (
              <div className="flex gap-1 flex-wrap">
                {getLabeled()
                  .slice(0, 7)
                  .map((item) => (
                    <Badge variant="outline" className="rounded-none border-white/10" key={item.value}>
                      {item.label}
                    </Badge>
                  ))}
                {selected.length > 7 ? (
                  <Badge
                    variant="outline"
                    className="border-white/10 rounded-none flex items-center justify-center px-1 h-[22px] dir-ltr"
                  >
                    <PlusIcon size={14} />
                    <span className="text-sm">{selected.length - 3}</span>
                  </Badge>
                ) : null}
              </div>
            ) : (
              <span className="text-black/30 dark:text-white/30">{placeholder}</span>
            )}
          </div>
          <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
        <Command className={className}>
          {noSearch ? null : <CommandInput placeholder={t("common.search")} />}
          <CommandList className="w-[var(--cmdk-list-width)]">
            <CommandEmpty>
              <Trans i18nKey="common.notFound" />
            </CommandEmpty>
            <CommandGroup className="max-h-64 overflow-auto">
              {options.map((option) => (
                <CommandItem
                  key={option.value}
                  className="flex items-center gap-2"
                  onSelect={() => {
                    onChange(
                      selected.includes(option.value)
                        ? selected.filter((item) => item !== option.value)
                        : [...selected, option.value]
                    );
                    setOpen(true);
                  }}
                >
                  <Checkbox className="pointer-events-none" checked={selected.includes(option.value)} />
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
