import { TagAPI } from "@/api";
import Page from "@/components/page";
import TagForm from "@/components/form-tag";
import { toast } from "@/components/ui/use-toast";
import { TCategory } from "@/types/category";
import { TNewTag } from "@/types/tag";
import { isAxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useLoaderData, useNavigate } from "react-router-dom";

export default function TagAdd() {
  const { t } = useTranslation();
  const data = useLoaderData() as TCategory[] | null;
  const navigate = useNavigate();

  const onSubmitForm = async (tag: TNewTag) => {
    try {
      const resp = await TagAPI.create(tag);
      toast({ description: resp.data.data.message.fa });
      navigate(-1);
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          description: err.response?.data.message,
          variant: "destructive",
        });
        return;
      }
      if (err instanceof Error) {
        toast({
          description: err.message,
          variant: "destructive",
        });
      }
    }
  };

  // TODO: implement Error Page
  if (!data) return <div>Error</div>;
  return (
    <Page
      breadcrumbs={[
        { label: t("sidebar.home"), link: "/" },
        { label: t("productsManagement.title"), link: "/products" },
        { label: t("productsTags.title"), link: "/products/tags" },
        { label: t("productsTags.addNew") },
      ]}
      title={t("productsTags.addNew")}
    >
      <TagForm categories={data} onSubmitForm={onSubmitForm} />
    </Page>
  );
}
