const ProductDevice = (props: { className?: string }) => (
  <svg width={40} height={40} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" className={props.className}>
    <path
      d="M13.6355 32.0204L7.98363 26.3685L15.0498 30.6062L13.6355 32.0204ZM9.3929 24.9494L10.8071 23.5352L17.8782 27.7778L16.464 29.192L9.3929 24.9494ZM17.8782 16.4641L19.2924 15.0499L26.3635 19.2925L24.9492 20.7067L17.8782 16.4641ZM20.7066 13.6357L23.535 10.8072L29.1919 16.4641L27.7777 17.8783L20.7066 13.6357Z"
      className="fill-white"
    />
    <path
      d="M31.3132 19.9996L19.9995 8.68592L23.535 5.15039L28.4848 10.1001L27.7777 10.8072L29.1919 12.2215L29.899 11.5144L34.8487 16.4641L31.3132 19.9996Z"
      className="fill-white"
    />
    <path
      d="M23.535 22.121L22.1208 23.5352L15.0498 19.2925L16.464 17.8783L23.535 22.121ZM20.7066 24.9494L19.2924 26.3636L12.2213 22.121L13.6355 20.7067L20.7066 24.9494Z"
      fill="#E25317"
    />
  </svg>
);
export default ProductDevice;
