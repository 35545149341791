export default {
  title: "مدیریت دسته‌بندی‌ها",
  edit: "ویرایش دسته‌بندی",
  newItem: "افزودن دسته‌بندی",
  editTitle: "ویرایش دسته‌بندی {{title}}",
  delete: "حذف دسته‌بندی",
  deleteDesc: "ایا از حذف این دسته‌بندی مطمئن هستید؟",
  titleFa: "عنوان فارسی",
  titleEn: "عنوان انگلیسی",
  seoTitleFa: "عنوان سئو فارسی",
  seoTitleEn: "عنوان سئو انگلیسی",
  faqTitleFa: "عنوان فارسی سوالات متداول",
  faqTitleEn: "عنوان انگلیسی سوالات متداول",
  seoDescriptionFa: "توضیحات سئو فارسی",
  seoDescriptionEn: "توضیحات سئو انگلیسی",
  canonical: "کانونیکال",
  desc: "توضیحات",
  faq: "سوالات متداول",
  faqList: "لیست سوالات متداول",
  addNewFaq: "افزودن سوال جدید",
  questionFa: "سوال فارسی",
  questionEn: "سوال انگلیسی",
  answerFa: "پاسخ فارسی",
  answerEn: "پاسخ انگلیسی",
  descFa: "توضیحات فارسی",
  descEn: "توضیحات انگلیسی",
};
