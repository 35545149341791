import { ProductAPI } from "@/api";
import Page from "@/components/page";
import ProductForm from "@/components/product-form/form";
import { TProductForm, draftSchema, publishSchema } from "@/components/product-form/schema";
import { toast } from "@/components/ui/use-toast";
import { TBrand } from "@/types/brand";
import { TCategory } from "@/types/category";
import { TColor } from "@/types/color";
import { TModel } from "@/types/model";
import { TTag } from "@/types/tag";
import { useTranslation } from "react-i18next";
import { useLoaderData, useNavigate } from "react-router-dom";
import { TProductDetailed } from "@/types/product";
import _ from "lodash";

export default function ProductAdd() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const data = useLoaderData() as null | {
    brands: TBrand[];
    models: TModel[];
    colors: TColor[];
    categories: TCategory[];
    subcategories: TCategory[];
    tags: TTag[];
    product?: TProductDetailed;
  };

  const onValidate = (data: TProductForm) => {
    const vld = publishSchema.safeParse(data);
    if (!vld.success) {
      const fields = Object.keys(vld.error.flatten().fieldErrors);
      return fields.map((key) => t(`products.errors.${key}`));
    }
    return null;
  };

  const onPublish = (data: TProductForm) => {
    const vld = publishSchema.safeParse(data);
    if (!vld.success) {
      const fields = Object.keys(vld.error.flatten().fieldErrors);
      return fields.map((key) => t(`products.errors.${key}`));
    }

    return async function () {
      const productColor = JSON.parse(
        JSON.stringify({
          has_color: !!data.color?.base_color?.length,
          base_color: data.color?.base_color?.length ? data.color?.base_color : undefined,
          product_color_name_en: data.color?.product_color_name_en?.trim().length
            ? data.color?.product_color_name_en
            : undefined,
          product_color_name_fa: data.color?.product_color_name_fa?.trim().length
            ? data.color?.product_color_name_fa
            : undefined,
        })
      );
      const desc = JSON.parse(
        JSON.stringify({
          fa: data.description?.fa.trim().length ? data.description?.fa.trim() : undefined,
          en: data.description?.en.trim().length ? data.description?.en.trim() : undefined,
        })
      );
      try {
        const respCreate = await ProductAPI.create({
          admin_preferred_related_products: [],
          availability: {
            is_available: true, //TODO: check this later
            total_count: data.availability?.total_count ? +data.availability?.total_count : 0,
          },
          brand: data.brand,
          category: data.category,
          color: productColor,
          description: _.isEmpty(desc) ? undefined : desc,
          name: data.name.trim(),
          price: {
            call_to_buy: data.price?.call_to_buy ?? false,
            is_used: data.price?.is_used ?? false,
            sale: {
              is_on_sale: data.price?.sale?.is_on_sale ?? false,
              off_percent: data.price.sale?.off_percent ? Number(data.price.sale?.off_percent) : 0,
            },
            price: Number(data.price?.price) ?? 0,
            is_competitive: false,
          },
          features: data.features ?? [],
          images: data.images ?? [],
          model: data.model,
          seo_name_en: data.seo_name_en.trim(),
          seo_name_fa: data.seo_name_fa.trim(),
          subcategory: data.subcategory,
          properties:
            data.properties?.map((prop) => ({
              ...prop,
              title: { fa: prop.title.fa.trim(), en: prop.title.en.trim() },
              items: prop.items ?? [],
            })) ?? [],
          tags: data.tags ?? [],
          videos: [],
          health_check_list: data.health_check_list?.filter((i) => !!i.product_value.length && !!i.score.length) ?? [],
        });
        const resp = await ProductAPI.publish(respCreate.data.data.id);
        toast({
          description: resp.data.data.message.fa,
        });

        return navigate("/products/list");
      } catch (err) {
        toast({ description: (err as Error).message, variant: "destructive" });
      }
    };
  };

  const onDraft = (data: TProductForm) => {
    const vld = draftSchema.safeParse(data);
    if (!vld.success) {
      const fields = Object.keys(vld.error.flatten().fieldErrors);
      return fields.map((key) => t(`products.errors.${key}`));
    }

    return async function () {
      const desc = JSON.parse(
        JSON.stringify({
          fa: data.description?.fa.length ? data.description?.fa : undefined,
          en: data.description?.en.length ? data.description?.en : undefined,
        })
      );
      const productColor = JSON.parse(
        JSON.stringify({
          has_color: !!data.color?.base_color?.length,
          base_color: data.color?.base_color?.length ? data.color?.base_color : undefined,
          product_color_name_en: data.color?.product_color_name_en?.trim().length
            ? data.color?.product_color_name_en
            : undefined,
          product_color_name_fa: data.color?.product_color_name_fa?.trim().length
            ? data.color?.product_color_name_fa
            : undefined,
        })
      );

      try {
        const respCreate = await ProductAPI.create({
          admin_preferred_related_products: [],
          availability: {
            is_available: true, //TODO: check this later
            total_count: data.availability?.total_count ? Number(data.availability?.total_count) : 0,
          },
          brand: data.brand,
          category: data.category,
          color: productColor,
          description: _.isEmpty(desc) ? undefined : desc,
          name: data.name.trim(),
          price: {
            call_to_buy: data.price?.call_to_buy ?? false,
            is_used: data.price?.is_used ?? false,
            sale: { is_on_sale: data.price?.sale?.is_on_sale ?? false, off_percent: 0 },
            price: Number(data.price?.price) ?? 0,
            is_competitive: false,
          },
          features: data.features ?? [],
          images: data.images ?? [],
          model: data.model,
          seo_name_en: data.seo_name_en.trim(),
          seo_name_fa: data.seo_name_fa.trim(),
          subcategory: data.subcategory,
          properties: data.properties?.map((prop) => ({ ...prop, items: prop.items ?? [] })) ?? [],
          tags: data.tags ?? [],
          videos: [],
          health_check_list: data.health_check_list?.filter((i) => !!i.product_value.length && !!i.score.length) ?? [],
        });
        const resp = await ProductAPI.draft(respCreate.data.data.id);
        toast({
          description: resp.data.data.message.fa,
        });

        return navigate("/products/list");
      } catch (err) {
        toast({ description: (err as Error).message, variant: "destructive" });
      }
    };
  };

  // TODO: implement Error Page
  if (!data) return <div>Error</div>;

  return (
    <Page
      breadcrumbs={[
        { label: t("sidebar.home"), link: "/" },
        { label: t("productsManagement.title"), link: "/products" },
        { label: t("products.products"), link: "/products/list" },
        { label: t("products.addNewProduct") },
      ]}
      title={t("products.addNewProduct")}
      classes={{ container: "flex-1 flex flex-col" }}
    >
      <ProductForm
        product={data.product}
        brands={data.brands}
        models={data.models}
        colors={data.colors}
        categories={data.categories}
        subcategories={data.subcategories}
        tags={data.tags}
        onDraft={onDraft}
        onPublish={onPublish}
        onValidate={onValidate}
      />
    </Page>
  );
}
