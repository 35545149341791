import { TAdminPermissions } from "@/types/admin";
import { useAuth } from "./providers/auth-provider";
import { Navigate, Outlet, useLocation } from "react-router-dom";

type TProps = {
  allowedPermissions: TAdminPermissions[];
};

export default function ProtectedOutlet(props: TProps) {
  const { allowedPermissions } = props;
  const location = useLocation();
  const auth = useAuth();

  if (
    auth.profile?.permissions
      .filter((permission) => permission.value)
      .find((permission) => allowedPermissions.includes(permission.key))
  ) {
    return <Outlet />;
  }
  return <Navigate to={`/forbidden?from=${location.pathname}`} replace />;
}
