import { UsersAPI } from "@/api";
import Mexico from "@/assets/images/mexico.svg";
import Map from "@/components/map";
import Page from "@/components/page";
import { useAuth } from "@/components/providers/auth-provider";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Dialog, DialogClose, DialogContent, DialogHeader, DialogTrigger } from "@/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "@/components/ui/use-toast";
import { ADMIN_PERMISSIONS } from "@/consts/admin.const";
import { cn, hasPermissions } from "@/lib/utils";
import { TUserAddress, TUserDetailed } from "@/types/user";
import { zodResolver } from "@hookform/resolvers/zod";
import _ from "lodash";
import { Pencil, Trash2 } from "lucide-react";
import React, { useRef } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useLoaderData, useRevalidator } from "react-router-dom";
import { z } from "zod";

export default function UserEdit() {
  const data = useLoaderData() as { profile: TUserDetailed; addresses: TUserAddress[] } | null;

  const { t } = useTranslation();
  const revalidator = useRevalidator();
  const [deletingAddress, setDeletingAddress] = React.useState("");
  const [isOpenAddress, setIsOpenAddress] = React.useState(false);
  const profileFormRef = useRef<HTMLFormElement>(null);
  const { profile } = useAuth();

  const addressFormScheme = z.object({
    title: z.string().min(1, { message: t("common.isRequired") }),
    no: z.string().min(1, { message: t("common.isRequired") }),
    floor: z.string().min(1, { message: t("common.isRequired") }),
    postal_address: z.string().min(1, { message: t("common.isRequired") }),
    postal_code: z.string().min(1, { message: t("common.isRequired") }),
    location: z.object(
      {
        lat: z
          .string()
          .min(1, { message: t("common.isRequired") })
          .optional(),
        lon: z
          .string()
          .min(1, { message: t("common.isRequired") })
          .optional(),
      },
      { required_error: t("common.isRequired") }
    ),
    dialogTitle: z.string(),
    id: z.string().optional(),
  });
  const profileFormScheme = z.object({
    firstName: z.string().min(1, { message: t("common.isRequired") }),
    lastName: z.string().min(1, { message: t("common.isRequired") }),
    email: z.string().email(t("common.isRequired")),
    phone: z.string().length(11, { message: t("common.isRequired") }),
    nationalCode: z.string().length(10, { message: t("common.isRequired") }),
    status: z.boolean(),
    avatar: z.string().optional(),
    id: z.string().optional(),
  });

  const addressForm = useForm<z.infer<typeof addressFormScheme>>({
    resolver: zodResolver(addressFormScheme),
    defaultValues: {
      title: "",
      no: "",
      floor: "",
      postal_address: "",
      postal_code: "",
      location: { lat: undefined, lon: undefined },
      dialogTitle: t("users.addressDialog.newAddress"),
      id: "",
    },
  });
  const profileForm = useForm<z.infer<typeof profileFormScheme>>({
    resolver: zodResolver(profileFormScheme),
    defaultValues: {
      firstName: data?.profile.first_name ?? "",
      lastName: data?.profile.last_name ?? "",
      email: data?.profile.email ?? "",
      phone: data?.profile.phone_number ?? "",
      nationalCode: data?.profile.national_code ?? "",
      id: data?.profile.id ?? "",
      status: data?.profile.status === "active",
      avatar: data?.profile.avatar_image,
    },
  });

  const onSubmitAddress = async (values: z.infer<typeof addressFormScheme>) => {
    try {
      if (values.id) {
        const resp = await UsersAPI.updateUserAddressById(values.id, {
          title: values.title,
          no: values.no,
          floor: values.floor,
          postal_address: values.postal_address,
          postal_code: values.postal_code,
          lat: values.location?.lat ?? "",
          lon: values.location?.lon ?? "",
        });
        toast({ description: resp.data.data.message.fa });
      } else {
        const resp = await UsersAPI.createUserAddress(data!.profile.id, {
          title: values.title,
          no: values.no,
          floor: values.floor,
          postal_address: values.postal_address,
          postal_code: values.postal_code,
          lat: values.location?.lat ?? "",
          lon: values.location?.lon ?? "",
        });
        toast({ description: resp.data.data.message.fa });
      }
      revalidator.revalidate();
      setIsOpenAddress(false);
      addressForm.reset();
    } catch (error) {
      console.log(error);
      toast({ description: (error as Error).message, variant: "destructive" });
    }
  };
  const onDeleteAddress = async (id: string) => {
    try {
      setDeletingAddress(id);
      const resp = await UsersAPI.deleteUserAddressById(id);
      toast({ description: resp.data.data.message.fa });
      revalidator.revalidate();
    } catch (error) {
      console.log(error);
      toast({ description: (error as Error).message, variant: "destructive" });
    } finally {
      setDeletingAddress("");
    }
  };

  const onSubmitProfile = async (values: z.infer<typeof profileFormScheme>) => {
    console.log(values);
    try {
      const resp = await UsersAPI.updateUser({
        user_id: values.id!,
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        phone_number: values.phone,
        national_code: values.nationalCode,
        status: values.status ? "active" : "deactivated",
        avatar_image: values.avatar,
      });
      toast({ description: resp.data.data.message.fa });
    } catch (err) {
      console.log(err);
      toast({ description: (err as Error).message, variant: "destructive" });
    }
  };

  // TODO: Implement Error Page
  if (!data) return <div>Error</div>;

  return (
    <Page
      breadcrumbs={[
        { label: t("sidebar.home"), link: "/" },
        { label: t("users.title"), link: "/users" },
        { label: t("users.edit") },
      ]}
      title={t("users.editUser", { name: `${data.profile.first_name} ${data.profile.last_name}` })}
    >
      {/* Profile */}
      <div className="bg-gray-scale-5 p-4">
        <div className="flex items-center">
          <span className="inline-block text-xl">
            <Trans i18nKey="users.profile" />
          </span>
        </div>
        <Form {...profileForm}>
          <form ref={profileFormRef} onSubmit={profileForm.handleSubmit(onSubmitProfile, console.log)} className="mt-4">
            <div className="flex gap-4">
              <img
                src={profileForm.getValues("avatar")?.length ? profileForm.getValues("avatar") : Mexico}
                alt={profileForm.getValues("firstName")}
                className="rounded-full p-px border-2 border-primary w-[156px] h-[156px]"
              />
              <div className="grid grid-cols-2 grid-rows-2 gap-4">
                <FormField
                  control={profileForm.control}
                  name="status"
                  render={({ field }) => (
                    <FormItem className="flex flex-col">
                      <FormLabel>
                        <Trans i18nKey="users.userStatus" />
                      </FormLabel>
                      <FormControl>
                        <div className="flex items-center gap-2">
                          <span className={cn("text-xs", { "text-primary": field.value })}>
                            <Trans i18nKey="users.statusActive" />
                          </span>
                          <Switch
                            disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_USER])}
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                          <span className={cn("text-xs", { "text-gray-scale-4": !field.value })}>
                            <Trans i18nKey="users.statusInactive" />
                          </span>
                        </div>
                      </FormControl>
                    </FormItem>
                  )}
                />
                <div className="text-sm">
                  <Trans
                    i18nKey="users.memeberDate"
                    values={{
                      date: new Intl.DateTimeFormat("fa-IR", {
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                      }).format(new Date(data.profile.register_date)),
                    }}
                  />
                </div>
                <div className="flex flex-col items-start gap-1">
                  <span className="text-sm">
                    <Trans i18nKey="users.emailVerification" />
                  </span>
                  {data.profile.email_verified ? (
                    <Badge variant="success" className="pointer-events-none">
                      <Trans i18nKey="users.verified" />
                    </Badge>
                  ) : (
                    <Badge variant="destructive" className="pointer-events-none">
                      <Trans i18nKey="users.unverified" />
                    </Badge>
                  )}
                </div>
                <div className="flex flex-col items-start gap-1">
                  <span className="text-sm">
                    <Trans i18nKey="users.phoneVerification" />
                  </span>
                  <Badge variant="success" className="pointer-events-none">
                    <Trans i18nKey="users.verified" />
                  </Badge>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-3 gap-4">
              <FormField
                control={profileForm.control}
                name="firstName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <Trans i18nKey="users.firstName" />
                    </FormLabel>
                    <FormControl>
                      <Input
                        disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_USER])}
                        placeholder={t("users.firstName")}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={profileForm.control}
                name="lastName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <Trans i18nKey="users.lastName" />
                    </FormLabel>
                    <FormControl>
                      <Input
                        disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_USER])}
                        placeholder={t("users.lastName")}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={profileForm.control}
                name="phone"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <Trans i18nKey="users.phone" />
                    </FormLabel>
                    <FormControl>
                      <Input
                        disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_USER])}
                        placeholder={t("users.phone")}
                        {...field}
                        className="dir-ltr text-left placeholder:dir-rtl placeholder:text-right"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={profileForm.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <Trans i18nKey="users.email" />
                    </FormLabel>
                    <FormControl>
                      <Input
                        disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_USER])}
                        placeholder={t("users.email")}
                        {...field}
                        className="dir-ltr text-left placeholder:dir-rtl placeholder:text-right font-montserrat placeholder:font-dana"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={profileForm.control}
                name="nationalCode"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <Trans i18nKey="users.nationalCode" />
                    </FormLabel>
                    <FormControl>
                      <Input
                        disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_USER])}
                        placeholder={t("users.nationalCode")}
                        {...field}
                        className="dir-ltr text-left placeholder:dir-rtl placeholder:text-right"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="flex justify-end mt-4">
              <AlertDialog>
                <AlertDialogTrigger
                  asChild
                  className={cn({ hidden: !hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_USER]) })}
                >
                  <Button loading={profileForm.formState.isSubmitting}>
                    <Trans i18nKey="common.submit" />
                  </Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogTitle>
                    <Trans i18nKey="users.warning" />
                  </AlertDialogTitle>
                  <AlertDialogDescription>
                    <Trans i18nKey="users.sureUpdate" />
                  </AlertDialogDescription>
                  <AlertDialogFooter>
                    <AlertDialogCancel asChild>
                      <Button variant="outline">
                        <Trans i18nKey="common.cancel" />
                      </Button>
                    </AlertDialogCancel>
                    <AlertDialogAction
                      onClick={() => {
                        profileFormRef.current?.dispatchEvent(new Event("submit", { bubbles: true }));
                      }}
                    >
                      <Trans i18nKey="common.submit" />
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </div>
          </form>
        </Form>
      </div>

      {/* Addresses */}
      <Dialog open={isOpenAddress} onOpenChange={setIsOpenAddress}>
        <div className="bg-gray-scale-5 mt-4 p-4">
          {/* header */}
          <div className="flex items-center justify-between mb-4">
            <span className="inline-block text-xl">
              <Trans i18nKey="users.addresses" />
            </span>

            <DialogTrigger asChild className={cn({ hidden: !hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_USER]) })}>
              <Button variant="secondary">
                <Trans i18nKey="common.addNew" />
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>{addressForm.getValues("dialogTitle")}</DialogHeader>
              <Form {...addressForm}>
                <form className="space-y-4" onSubmit={addressForm.handleSubmit(onSubmitAddress, console.log)}>
                  <input {...addressForm.register("id")} className="hidden" />
                  <FormField
                    control={addressForm.control}
                    name="location"
                    render={({ field }) => {
                      const latLng = JSON.parse(
                        JSON.stringify({
                          lat: field.value?.lat ? +field.value?.lat : undefined,
                          lng: field.value?.lon ? +field.value?.lon : undefined,
                        })
                      );
                      return (
                        <FormItem>
                          <FormControl>
                            <Map
                              position={_.isEmpty(latLng) ? undefined : latLng}
                              onChange={(lat, lng) => {
                                const loc = { lat: lat.toString(), lon: lng.toString() };
                                field.onChange(loc);
                              }}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      );
                    }}
                  />
                  <FormField
                    control={addressForm.control}
                    name="title"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          <Trans i18nKey="users.addressDialog.addressTitle" />
                        </FormLabel>
                        <FormControl>
                          <Input {...field} placeholder={t("users.addressDialog.addressTitle")} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={addressForm.control}
                    name="postal_address"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          <Trans i18nKey="users.addressDialog.fullAddress" />
                        </FormLabel>
                        <FormControl>
                          <Textarea
                            {...field}
                            className="resize-none"
                            placeholder={t("users.addressDialog.fullAddress")}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div className="flex">
                    <FormField
                      control={addressForm.control}
                      name="postal_code"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            <Trans i18nKey="users.addressDialog.postalCode" />
                          </FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              placeholder={t("users.addressDialog.postalCode")}
                              className="text-left dir-ltr placeholder:text-right placeholder:dir-rtl focus-visible:ring-transparent focus-visible:ring-offset-transparent focus:ring-transparent focus:ring-offset-transparent group-focus-within:ring-y-2"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={addressForm.control}
                      name="floor"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            <Trans i18nKey="users.addressDialog.floor" />
                          </FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              placeholder={t("users.addressDialog.floor")}
                              className="text-left dir-ltr placeholder:text-right placeholder:dir-rtl focus-visible:ring-transparent focus-visible:ring-offset-transparent focus:ring-transparent focus:ring-offset-transparent group-focus-within:ring-y-2"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={addressForm.control}
                      name="no"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>
                            <Trans i18nKey="users.addressDialog.no" />
                          </FormLabel>
                          <FormControl>
                            <Input
                              {...field}
                              placeholder={t("users.addressDialog.no")}
                              className="text-left dir-ltr placeholder:text-right placeholder:dir-rtl focus-visible:ring-transparent focus-visible:ring-offset-transparent focus:ring-transparent focus:ring-offset-transparent group-focus-within:ring-y-2"
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="flex justify-end gap-2">
                    <DialogClose asChild>
                      <Button variant="outline" onClick={() => addressForm.reset()}>
                        <Trans i18nKey="common.cancel" />
                      </Button>
                    </DialogClose>
                    <Button loading={addressForm.formState.isSubmitting} type="submit">
                      <Trans i18nKey="common.submit" />
                    </Button>
                  </div>
                </form>
              </Form>
            </DialogContent>
          </div>
          {/* list */}
          <div className="space-y-4">
            {data.addresses.length ? (
              data.addresses.map((address) => (
                <div key={address.id} className="flex border border-white/10 p-4">
                  <div className="flex-1">
                    <div className="font-bold">{address.title}</div>
                    <div className="text-gray-scale-4 text-sm">
                      <Trans
                        i18nKey="users.address"
                        values={{
                          address: address.title,
                          no: address.no,
                          floor: address.floor,
                          postalCode: address.postal_code,
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <DialogTrigger
                      asChild
                      className={cn({ hidden: !hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_USER]) })}
                    >
                      <Button
                        variant="ghost"
                        size="icon"
                        onClick={() =>
                          addressForm.reset({
                            ...address,
                            dialogTitle: t("users.addressDialog.editAddress"),
                            location: { lat: address.lat, lon: address.lon },
                          })
                        }
                      >
                        <Pencil size={18} />
                      </Button>
                    </DialogTrigger>
                    <Button
                      className={cn({ hidden: !hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_USER]) })}
                      variant="ghost-destructive"
                      size="icon"
                      loading={deletingAddress === address.id}
                      onClick={() => onDeleteAddress(address.id)}
                    >
                      <Trash2 size={20} />
                    </Button>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-gray-scale-4 text-center">
                <Trans i18nKey="common.isEmpty" />
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </Page>
  );
}
