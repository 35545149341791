import { StaticsAPI } from "@/api";
import ImageUploader from "@/components/image-upload";
import Page from "@/components/page";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTrigger } from "@/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "@/components/ui/use-toast";
import { TCarousel } from "@/types/statics";
import { zodResolver } from "@hookform/resolvers/zod";
import { DialogClose } from "@radix-ui/react-dialog";
import { Pencil, Trash2 } from "lucide-react";
import React from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useLoaderData, useRevalidator } from "react-router-dom";
import { z } from "zod";

export default function Carousel() {
  const data = useLoaderData() as TCarousel[] | null;
  const { t } = useTranslation();
  const revalidator = useRevalidator();
  const [isOpen, setIsOpen] = React.useState(false);
  const [deleting, setDeleting] = React.useState("");

  const formSchema = z.object({
    title_fa: z.string().min(1, { message: t("common.isRequired") }),
    title_en: z.string().min(1, { message: t("common.isRequired") }),
    description_fa: z.string().min(1, { message: t("common.isRequired") }),
    description_en: z.string().min(1, { message: t("common.isRequired") }),
    below_text_en: z.string().min(1, { message: t("common.isRequired") }),
    below_text_fa: z.string().min(1, { message: t("common.isRequired") }),
    image_url: z.string().url({ message: t("common.isRequired") }),
    link: z.string().min(1, { message: t("common.isRequired") }),
    dialogTitle: z.string().optional(),
    id: z.string().optional(),
  });

  const getDefaultValues = () => {
    return {
      dialogTitle: t("carousel.addNewSlide"),
      title_fa: "",
      title_en: "",
      description_fa: "",
      description_en: "",
      below_text_en: "",
      below_text_fa: "",
      image_url: "",
      link: "",
    };
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: getDefaultValues(),
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      if (values.id) {
        const resp = await StaticsAPI.updateCarouselContent(values.id, values);
        toast({ description: resp.data.data.message.fa });
      } else {
        const resp = await StaticsAPI.createCarouselContent(values);
        toast({ description: resp.data.data.message.fa });
      }
      revalidator.revalidate();
      setIsOpen(false);
      form.reset(getDefaultValues());
    } catch (err) {
      toast({ description: (err as Error).message, variant: "destructive" });
    }
  };

  const onDelete = async (id: string) => {
    try {
      setDeleting(id);
      const resp = await StaticsAPI.deleteCarouselContentById(id);
      revalidator.revalidate();
      toast({
        description: resp.data.data.message.fa,
      });
    } catch (err) {
      console.log(err);
      toast({
        variant: "destructive",
        description: (err as Error).message,
      });
    } finally {
      setDeleting("");
    }
  };

  // TODO: Implement Error Page
  if (!data) return <div>Error</div>;
  return (
    <Page
      title={t("carousel.title")}
      breadcrumbs={[
        { label: t("sidebar.home"), link: "/" },
        { label: t("website.title"), link: "/website" },
        { label: t("carousel.title") },
      ]}
    >
      <Dialog
        open={isOpen}
        onOpenChange={(open) => {
          if (!open) {
            form.reset(getDefaultValues());
          }
          setIsOpen(open);
        }}
      >
        <div className="mb-4 flex items-center justify-between">
          <span>
            <Trans i18nKey="carousel.slides" />
          </span>
          <DialogTrigger asChild>
            <Button>
              <Trans i18nKey="common.addNew" />
            </Button>
          </DialogTrigger>
        </div>
        <div className="grid grid-cols-2 gap-4">
          {data.map((item) => (
            <div key={item.id} className="bg-gray-scale-5 flex p-4 gap-4">
              <img src={item.image} alt="carousel" className="w-32 h-32 border border-white/10 object-cover" />
              <div className="flex flex-col flex-1 justify-center">
                <h3 className="text-xl font-bold">
                  {item.title.en}&nbsp;|&nbsp;{item.title.fa}
                </h3>
                <p className="text-gray-scale-4">
                  {item.description.en}&nbsp;|&nbsp;{item.description.fa}
                </p>
              </div>
              <div className="flex flex-col justify-center">
                <DialogTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() =>
                      form.reset({
                        dialogTitle: t("carousel.editSlide"),
                        id: item.id,
                        below_text_en: item.below_text.en,
                        below_text_fa: item.below_text.fa,
                        description_en: item.description.en,
                        description_fa: item.description.fa,
                        image_url: item.image,
                        link: item.link,
                        title_en: item.title.en,
                        title_fa: item.title.fa,
                      })
                    }
                  >
                    <Pencil size={18} />
                  </Button>
                </DialogTrigger>
                <Button
                  variant="ghost-destructive"
                  size="icon"
                  loading={deleting === item.id}
                  onClick={() => onDelete(item.id)}
                >
                  <Trash2 size={20} />
                </Button>
              </div>
            </div>
          ))}
        </div>
        <DialogContent className="max-w-5xl">
          <DialogHeader>{form.getValues("dialogTitle")}</DialogHeader>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="flex gap-2">
                <div className="col-span-2 grid grid-cols-2 gap-4 w-2/3">
                  <FormField
                    control={form.control}
                    name="title_fa"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          <Trans i18nKey="carousel.titleFa" />
                        </FormLabel>
                        <FormControl>
                          <Input placeholder={t("carousel.titleFa")} {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="title_en"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          <Trans i18nKey="carousel.titleEn" />
                        </FormLabel>
                        <FormControl>
                          <Input
                            className="text-left dir-ltr placeholder:text-right font-montserrat placeholder:font-dana"
                            placeholder={t("carousel.titleEn")}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="below_text_fa"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          <Trans i18nKey="carousel.belowTextFa" />
                        </FormLabel>
                        <FormControl>
                          <Input placeholder={t("carousel.belowTextFa")} {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="below_text_en"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>
                          <Trans i18nKey="carousel.belowTextEn" />
                        </FormLabel>
                        <FormControl>
                          <Input
                            className="text-left dir-ltr placeholder:text-right font-montserrat placeholder:font-dana"
                            placeholder={t("carousel.belowTextEn")}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <div className="flex flex-col gap-1 col-span-2">
                    <span className="text-sm">
                      <Trans i18nKey="carousel.description" />
                    </span>
                    <Tabs defaultValue="fa">
                      <TabsList className="w-full grid grid-cols-2">
                        <TabsTrigger value="fa">
                          <Trans i18nKey="common.fa" />
                        </TabsTrigger>
                        <TabsTrigger value="en">
                          <Trans i18nKey="common.en" />
                        </TabsTrigger>
                      </TabsList>
                      <TabsContent value="fa">
                        <FormField
                          control={form.control}
                          name="description_fa"
                          render={({ field }) => (
                            <FormItem>
                              <FormControl>
                                <Textarea
                                  rows={5}
                                  placeholder={t("carousel.descriptionFa")}
                                  className="resize-none"
                                  {...field}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </TabsContent>
                      <TabsContent value="en">
                        <FormField
                          control={form.control}
                          name="description_en"
                          render={({ field }) => (
                            <FormItem>
                              <FormControl>
                                <Textarea
                                  rows={5}
                                  placeholder={t("carousel.descriptionEn")}
                                  className="resize-none text-left dir-ltr placeholder:text-right font-montserrat placeholder:font-dana"
                                  {...field}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </TabsContent>
                    </Tabs>
                  </div>

                  <FormField
                    control={form.control}
                    name="link"
                    render={({ field }) => (
                      <FormItem className="col-span-2">
                        <FormLabel>
                          <Trans i18nKey="carousel.slideLink" />
                        </FormLabel>
                        <FormControl>
                          <Input
                            className="text-left dir-ltr placeholder:text-right font-montserrat placeholder:font-dana"
                            placeholder={t("carousel.slideLink")}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <FormField
                  control={form.control}
                  name="image_url"
                  render={({ field }) => (
                    <FormItem className="w-1/3 max-h-[534px]">
                      <FormControl>
                        <ImageUploader
                          className="h-full"
                          value={field.value}
                          onUpload={(url) => {
                            field.onChange(url ?? "");
                            form.setValue("image_url", url ?? "", { shouldValidate: true });
                          }}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="flex justify-end gap-1 mt-4">
                <DialogClose asChild>
                  <Button variant="outline" onClick={() => form.reset(getDefaultValues())}>
                    <Trans i18nKey="common.cancel" />
                  </Button>
                </DialogClose>
                <Button type="submit" loading={form.formState.isSubmitting}>
                  <Trans i18nKey="common.submit" />
                </Button>
              </div>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </Page>
  );
}
