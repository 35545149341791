import { StaticsAPI } from "@/api";
import Editor from "@/components/editor";
import Page from "@/components/page";
import { Button } from "@/components/ui/button";
import { Dialog, DialogClose, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Textarea } from "@/components/ui/textarea";
import { toast } from "@/components/ui/use-toast";
import { TUsedContent } from "@/types/statics";
import { zodResolver } from "@hookform/resolvers/zod";
import { Edit2, Trash2 } from "lucide-react";
import React from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useLoaderData, useRevalidator } from "react-router-dom";
import { z } from "zod";

export default function UsedProducts() {
  const data = useLoaderData() as TUsedContent | null;
  const { t } = useTranslation();
  const revalidator = useRevalidator();
  const [isOpen, setIsOpen] = React.useState(false);
  const [deleting, setDeleting] = React.useState("");

  const getDefaultValues = () => {
    return {
      dialogTitle: t("faq.addNewFaq"),
      question_fa: "",
      question_en: "",
      answer_fa: "",
      answer_en: "",
      id: "",
    };
  };

  const schemaContent = z.object({
    used_products_content: z.object({
      fa: z.string(),
      en: z.string(),
    }),
    about_used_content: z.object({
      fa: z.string(),
      en: z.string(),
    }),
  });
  const schemaFAQ = z.object({
    answer_fa: z.string().min(1, { message: t("common.isRequired") }),
    answer_en: z.string().min(1, { message: t("common.isRequired") }),
    question_fa: z.string().min(1, { message: t("common.isRequired") }),
    question_en: z.string().min(1, { message: t("common.isRequired") }),
    id: z.string().optional(),
    dialogTitle: z.string().optional(),
  });

  const formContent = useForm<z.infer<typeof schemaContent>>({
    defaultValues: data ?? {},
    resolver: zodResolver(schemaContent),
  });
  const formFAQ = useForm<z.infer<typeof schemaFAQ>>({
    resolver: zodResolver(schemaFAQ),
    defaultValues: getDefaultValues(),
  });

  const onSubmitContent = async (data: z.infer<typeof schemaContent>) => {
    try {
      const resp = await StaticsAPI.setUsedProductsContent(data);
      toast({ description: resp.data.data.message.fa });
    } catch (err) {
      toast({ description: (err as Error).message, variant: "destructive" });
    }
  };

  const onSubmitFAQ = async (values: z.infer<typeof schemaFAQ>) => {
    try {
      if (values.id) {
        const resp = await StaticsAPI.updateUsedProductsFAQ(values.id, {
          answer: { fa: values.answer_fa, en: values.answer_en },
          question: { fa: values.question_fa, en: values.question_en },
        });
        toast({ description: resp.data.data.message.fa });
      } else {
        const resp = await StaticsAPI.addUsedProductsFAQ({
          answer: { fa: values.answer_fa, en: values.answer_en },
          question: { fa: values.question_fa, en: values.question_en },
        });
        toast({ description: resp.data.data.message.fa });
      }
      revalidator.revalidate();
      setIsOpen(false);
      formFAQ.reset(getDefaultValues());
    } catch (err) {
      toast({ description: (err as Error).message, variant: "destructive" });
    }
  };

  const onDelete = async (id: string) => {
    try {
      setDeleting(id);
      const resp = await StaticsAPI.deleteUsedProductsFAQ(id);
      revalidator.revalidate();
      toast({ description: resp.data.data.message.fa });
    } catch (err) {
      toast({ description: (err as Error).message, variant: "destructive" });
    } finally {
      setDeleting("");
    }
  };

  // TODO: implement Error Page
  if (!data) return <div>Error</div>;
  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          formFAQ.reset(getDefaultValues());
        }
        setIsOpen(open);
      }}
    >
      <Page
        title={t("usedProducts.title")}
        breadcrumbs={[
          { label: t("sidebar.home"), link: "/" },
          { label: t("website.title"), link: "/website" },
          { label: t("usedProducts.title") },
        ]}
      >
        <Form {...formContent}>
          <form onSubmit={formContent.handleSubmit(onSubmitContent)} className="bg-gray-scale-5 p-4 space-y-8">
            <div className="flex flex-col gap-1 col-span-2">
              <span>
                <Trans i18nKey="usedProducts.content" />
              </span>
              <Tabs defaultValue="fa" className="w-full">
                <TabsList className="w-full grid grid-cols-2">
                  <TabsTrigger value="en">
                    <Trans i18nKey="common.en" />
                  </TabsTrigger>
                  <TabsTrigger value="fa">
                    <Trans i18nKey="common.fa" />
                  </TabsTrigger>
                </TabsList>
                <TabsContent value="fa">
                  <FormField
                    control={formContent.control}
                    name="used_products_content.fa"
                    render={({ field }) => (
                      <FormItem className="col-span-2">
                        <FormControl>
                          <Editor data={field.value ?? ""} onChange={field.onChange} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </TabsContent>
                <TabsContent value="en">
                  <FormField
                    control={formContent.control}
                    name="used_products_content.en"
                    render={({ field }) => (
                      <FormItem className="col-span-2">
                        <FormControl>
                          <Editor en data={field.value ?? ""} onChange={field.onChange} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </TabsContent>
              </Tabs>
            </div>

            <div className="flex flex-col gap-1 col-span-2">
              <span>
                <Trans i18nKey="usedProducts.about" />
              </span>
              <Tabs defaultValue="fa" className="w-full">
                <TabsList className="w-full grid grid-cols-2">
                  <TabsTrigger value="en">
                    <Trans i18nKey="common.en" />
                  </TabsTrigger>
                  <TabsTrigger value="fa">
                    <Trans i18nKey="common.fa" />
                  </TabsTrigger>
                </TabsList>
                <TabsContent value="fa">
                  <FormField
                    control={formContent.control}
                    name="about_used_content.fa"
                    render={({ field }) => (
                      <FormItem className="col-span-2">
                        <FormControl>
                          <Editor data={field.value ?? ""} onChange={field.onChange} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </TabsContent>
                <TabsContent value="en">
                  <FormField
                    control={formContent.control}
                    name="about_used_content.en"
                    render={({ field }) => (
                      <FormItem className="col-span-2">
                        <FormControl>
                          <Editor en data={field.value ?? ""} onChange={field.onChange} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </TabsContent>
              </Tabs>
            </div>
            <div className="flex justify-end">
              <Button loading={formContent.formState.isSubmitting} type="submit">
                <Trans i18nKey="common.submit" />
              </Button>
            </div>
          </form>
        </Form>

        {/* FAQ List */}
        <div className="mt-8">
          <div className="mb-2 flex items-center justify-between">
            <span className="inline-block text-lg">
              <Trans i18nKey="usedProducts.faqList" />
            </span>
            <DialogTrigger asChild>
              <Button>
                <Trans i18nKey="common.addNew" />
              </Button>
            </DialogTrigger>
          </div>
          <div className="flex flex-col gap-4">
            {data.faq_list.length ? (
              data.faq_list.map((item) => (
                <div key={item.id} className="bg-gray-scale-5 flex">
                  <div className="flex-1 space-y-2 p-4">
                    <div>
                      <p>{item.question.fa}</p>
                      <p className="text-sm text-gray-scale-4 dir-ltr font-montserrat">{item.question.en}</p>
                    </div>

                    <div>
                      <p>{item.answer.fa}</p>
                      <p className="text-sm text-gray-scale-4 dir-ltr font-montserrat">{item.answer.en}</p>
                    </div>
                  </div>
                  <div className="flex flex-col justify-center gap-1 pl-2">
                    <DialogTrigger asChild>
                      <Button
                        size="icon"
                        variant="ghost"
                        onClick={() => {
                          formFAQ.reset({
                            question_fa: item.question.fa,
                            question_en: item.question.en,
                            answer_fa: item.answer.fa,
                            answer_en: item.answer.en,
                            id: item.id,
                          });
                        }}
                      >
                        <Edit2 size={16} />
                      </Button>
                    </DialogTrigger>
                    <Button
                      onClick={() => onDelete(item.id)}
                      loading={deleting === item.id}
                      size="icon"
                      variant="ghost-destructive"
                    >
                      <Trash2 size={20} />
                    </Button>
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center text-gray-scale-4">
                <Trans i18nKey="common.isEmpty" />
              </div>
            )}
          </div>
        </div>
      </Page>

      {/* FAQ Form */}
      <DialogContent className="max-w-4xl">
        <DialogHeader>
          <DialogTitle>{formFAQ.getValues("dialogTitle")}</DialogTitle>
        </DialogHeader>
        <Form {...formFAQ}>
          <form className="grid grid-cols-4 gap-4" onSubmit={formFAQ.handleSubmit(onSubmitFAQ)}>
            <FormField
              control={formFAQ.control}
              name="question_fa"
              render={({ field }) => (
                <FormItem className="col-span-2">
                  <FormLabel>
                    <Trans i18nKey="faq.questionFa" />
                  </FormLabel>
                  <FormControl>
                    <Input placeholder={t("faq.questionFa")} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={formFAQ.control}
              name="question_en"
              render={({ field }) => (
                <FormItem className="col-span-2">
                  <FormLabel>
                    <Trans i18nKey="faq.questionEn" />
                  </FormLabel>
                  <FormControl>
                    <Input
                      className="text-left dir-ltr font-montserrat placeholder:font-dana placeholder:text-right"
                      placeholder={t("faq.questionEn")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={formFAQ.control}
              name="answer_fa"
              render={({ field }) => (
                <FormItem className="col-span-2">
                  <FormLabel>
                    <Trans i18nKey="faq.answerFa" />
                  </FormLabel>
                  <FormControl>
                    <Textarea className="resize-none" placeholder={t("faq.answerFa")} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={formFAQ.control}
              name="answer_en"
              render={({ field }) => (
                <FormItem className="col-span-2">
                  <FormLabel>
                    <Trans i18nKey="faq.answerEn" />
                  </FormLabel>
                  <FormControl>
                    <Textarea
                      className="resize-none text-left dir-ltr font-montserrat placeholder:font-dana placeholder:text-right"
                      placeholder={t("faq.answerEn")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex justify-end col-span-4 gap-2">
              <DialogClose asChild>
                <Button variant="outline" onClick={() => formFAQ.reset(getDefaultValues())}>
                  <Trans i18nKey="common.cancel" />
                </Button>
              </DialogClose>
              <Button onClick={formFAQ.handleSubmit(onSubmitFAQ, console.log)} loading={formFAQ.formState.isSubmitting}>
                <Trans i18nKey="common.submit" />
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
