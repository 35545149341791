import Page from "@/components/page";
import { TLog } from "@/types/log";
import { Trans, useTranslation } from "react-i18next";
import { useLoaderData } from "react-router-dom";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import js_beautify from "js-beautify";
export default function LogShow() {
  const data = useLoaderData() as TLog | null;
  const { t } = useTranslation();

  // TODO: Implement Error Page
  if (!data) return <div>Error</div>;

  return (
    <Page
      classes={{ container: "h-[calc(100vh-224px)]" }}
      breadcrumbs={[
        { label: t("sidebar.home"), link: "/" },
        { label: t("logs.title"), link: "/logs" },
        { label: t("logs.showLog") },
      ]}
      title={t("logs.showLog")}
    >
      <div className="flex flex-col gap-2">
        <span className="text-lg text-gray-scale-1">
          <Trans i18nKey={`logs.userActions.${data.action}`} />
        </span>
        <div className="text-xs text-gray-scale-3">
          <span>
            {data.date ? (
              new Intl.DateTimeFormat("fa-IR", { day: "2-digit", month: "long", year: "numeric" }).format(
                new Date(data.date)
              )
            ) : (
              <Trans i18nKey="common.unknown" />
            )}
          </span>
          &nbsp;&#44;&nbsp;
          <span>
            {data.date ? (
              new Intl.DateTimeFormat("fa-IR", { hour: "2-digit", minute: "2-digit", hour12: false }).format(
                new Date(data.date)
              )
            ) : (
              <Trans i18nKey="common.unknown" />
            )}
          </span>
          &nbsp;&#124;&nbsp;
          <span>
            {data.user.first_name}&nbsp;{data.user.last_name}
          </span>
        </div>
      </div>
      {data.body ? (
        <div className="mt-4 [&_*]:dir-ltr [&_*]:text-left h-full [&>pre]:h-full">
          <SyntaxHighlighter showLineNumbers wrapLines language="json" style={a11yDark}>
            {js_beautify(JSON.stringify(data.body))}
          </SyntaxHighlighter>
        </div>
      ) : (
        <div className="text-center mt-4 text-gray-scale-4">
          <Trans i18nKey="logs.noData" />
        </div>
      )}
    </Page>
  );
}
