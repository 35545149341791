import { StaticsAPI } from "@/api";
import { ADMIN_PERMISSIONS } from "@/consts/admin.const";
import { apiExecByPermission } from "@/lib/utils";
import { TSliderName } from "@/types/slider";

export default async function loader() {
  try {
    const resp = await apiExecByPermission([ADMIN_PERMISSIONS.READ_STATICS], () => StaticsAPI.getSlidersList());

    return resp.data.data.items.reduce((acc, item) => {
      acc[item] = item;
      return acc;
    }, {} as Record<TSliderName, TSliderName>);
  } catch (err) {
    return null;
  }
}
