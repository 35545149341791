import { CategoryAPI } from "@/api";
import CategoryFormPage from "@/components/form-category";
import Page from "@/components/page";
import { toast } from "@/components/ui/use-toast";
import { TCategoryDetailed, TCategoryFAQItem, TNewCategory } from "@/types/category";
import { isAxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useLoaderData, useParams, useRevalidator } from "react-router-dom";

export default function CategoryEdit() {
  const data = useLoaderData() as TCategoryDetailed | undefined;
  const { t } = useTranslation();
  const params = useParams() as { categoryId: string };
  const revalidator = useRevalidator();
  const onAddFAQ = async (data: Omit<TCategoryFAQItem, "id">) => {
    try {
      const resp = await CategoryAPI.addQAById(params.categoryId, data);
      revalidator.revalidate();
      toast({ description: resp.data.data.message.fa });
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          description: err.response?.data.message,
          variant: "destructive",
        });
        return;
      }
      if (err instanceof Error) {
        toast({
          description: err.message,
          variant: "destructive",
        });
      }
    }
  };
  const onDeleteFAQ = async (id: string) => {
    try {
      const resp = await CategoryAPI.deleteQAById(id, params.categoryId);
      revalidator.revalidate();
      toast({
        description: resp.data.data.message.fa,
      });
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          description: err.response?.data.message,
          variant: "destructive",
        });
        return;
      }
      if (err instanceof Error) {
        toast({
          description: err.message,
          variant: "destructive",
        });
      }
    }
  };
  const onEditFAQ = async (data: TCategoryFAQItem) => {
    try {
      const resp = await CategoryAPI.updateQAbyId(data.id, params.categoryId, {
        question: { fa: data.question.fa, en: data.question.en },
        answer: { fa: data.answer.fa, en: data.answer.en },
      });
      revalidator.revalidate();
      toast({ description: resp.data.data.message.fa });
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          description: err.response?.data.message,
          variant: "destructive",
        });
        return;
      }
      if (err instanceof Error) {
        toast({
          description: err.message,
          variant: "destructive",
        });
      }
    }
  };

  const onSubmit = async (category: TNewCategory) => {
    try {
      const resp = await CategoryAPI.update(params.categoryId, category);
      toast({ description: resp.data.data.message.fa });
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          description: err.response?.data.message,
          variant: "destructive",
        });
        return;
      }
      if (err instanceof Error) {
        toast({
          description: err.message,
          variant: "destructive",
        });
      }
    }
  };

  // TODO: Implement Error Page
  if (!data) return <div>Error</div>;

  return (
    <Page
      breadcrumbs={[
        { label: t("sidebar.home"), link: "/" },
        { label: t("productsManagement.title"), link: "/products" },
        { label: t("categories.title"), link: "/products/categories" },
        { label: t("categories.edit") },
      ]}
      title={t("categories.editTitle", { title: data?.title.fa })}
    >
      <CategoryFormPage
        onSubmitForm={onSubmit}
        initData={data}
        onEditFAQ={onEditFAQ}
        onDeleteFAQ={onDeleteFAQ}
        onAddFAQ={onAddFAQ}
      />
    </Page>
  );
}
