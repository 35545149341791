import { AdminsAPI, SubcategoryAPI } from "@/api";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { TCategory } from "@/types/category";
import { TSubcategory } from "@/types/subcategory";
import { TNewTag, TTagDetailed, TTagFAQItem, TTagNewFAQItem } from "@/types/tag";
import { zodResolver } from "@hookform/resolvers/zod";
import { isAxiosError } from "axios";
import _ from "lodash";
import { ChevronDown, Edit2, Trash2 } from "lucide-react";
import React from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { z } from "zod";
import Editor from "./editor";
import { Button, buttonVariants } from "./ui/button";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "./ui/collapsible";
import { Dialog, DialogClose, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "./ui/dialog";
import { Input } from "./ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs";
import { Textarea } from "./ui/textarea";
import { toast } from "./ui/use-toast";
import { useAuth } from "./providers/auth-provider";
import { hasPermissions } from "@/lib/utils";
import { ADMIN_PERMISSIONS } from "@/consts/admin.const";

type TProps = {
  onAddFAQ?: (data: TTagNewFAQItem) => Promise<void>;
  onDeleteFAQ?: (id: string, tagId: string) => Promise<void>;
  onEditFAQ?: (data: TTagFAQItem, subCategoryId: string) => Promise<void>;
  initData?: TTagDetailed;
  onSubmitForm: (category: TNewTag & { id: string }) => Promise<void>;
  categories: TCategory[];
};

export default function TagForm(props: TProps) {
  const { onSubmitForm: onSubmit, initData, onAddFAQ, onDeleteFAQ, onEditFAQ, categories } = props;
  const { t } = useTranslation();
  const params = useParams() as { id: string };
  const { profile } = useAuth();

  const [uploadingBanner, setUploadingBanner] = React.useState(false);
  const [uploadingThumbnail, setUploadingThumbnail] = React.useState(false);
  const [openFAQ, setOpenFAQ] = React.useState(false);
  const [openEditFAQ, setEditOpenFAQ] = React.useState(false);
  const [loadingDeleteFAQ, setLoadingDeleteFAQ] = React.useState("");
  const [selectedCategory, setSelectedCategory] = React.useState("");
  const [subcategories, setSubcategories] = React.useState<TSubcategory[]>([]);

  const bannerRef = React.useRef<HTMLInputElement>(null);
  const thumbnailRef = React.useRef<HTMLInputElement>(null);
  const categoryFormRef = React.useRef<HTMLFormElement>(null);
  const faqFormRef = React.useRef<HTMLFormElement>(null);

  const optionalSchema = z.object({
    id: z.string().optional(),
    subcategory: z.string().optional(), // create
    title_fa: z.string(),
    title_en: z.string(),
    canonical: z.string(),
    seo_title_fa: z.string(),
    seo_title_en: z.string(),
    seo_description_fa: z.string(),
    seo_description_en: z.string(),
    description_fa: z.string(),
    description_en: z.string(),
    faq_title_fa: z.string().optional(),
    faq_title_en: z.string().optional(),
    thumbnail: z.string().optional(),
    banner: z.string().optional(),
  });

  const tagFormSchema = () => {
    if (params.id) {
      return optionalSchema;
    } else {
      return z.object({
        id: z.string().min(1, t("common.isRequired")).optional(),
        subcategory: z.string().min(1, t("common.isRequired")).optional(), // create
        title_fa: z.string().min(1, t("common.isRequired")),
        title_en: z.string().min(1, t("common.isRequired")),
        canonical: z.string().min(1, t("common.isRequired")),
        seo_title_fa: z.string().min(1, t("common.isRequired")),
        seo_title_en: z.string().min(1, t("common.isRequired")),
        seo_description_fa: z.string().min(1, t("common.isRequired")),
        seo_description_en: z.string().min(1, t("common.isRequired")),
        description_fa: z.string().min(1, t("common.isRequired")),
        description_en: z.string().min(1, t("common.isRequired")),
        faq_title_fa: z
          .string()
          .min(1, t("common.isRequired"))
          .optional()
          .transform((e) => (e === "" ? undefined : e)),
        faq_title_en: z
          .string()
          .min(1, t("common.isRequired"))
          .optional()
          .transform((e) => (e === "" ? undefined : e)),
        thumbnail: z.string().url(t("common.isRequired")),
        banner: z.string().url(t("common.isRequired")),
      });
    }
  };
  const faqFormSchema = z.object({
    id: z.string().optional(),
    question_fa: z.string().min(1, t("common.isRequired")),
    question_en: z.string().min(1, t("common.isRequired")),
    answer_fa: z.string().min(1, t("common.isRequired")),
    answer_en: z.string().min(1, t("common.isRequired")),
  });

  const getInitData = (initData?: TTagDetailed): z.infer<typeof optionalSchema> => {
    if (!initData)
      return {
        banner: "",
        subcategory: "",
        title_fa: "",
        title_en: "",
        canonical: "",
        description_fa: "",
        description_en: "",
        seo_title_fa: "",
        seo_title_en: "",
        seo_description_fa: "",
        seo_description_en: "",
        faq_title_fa: "",
        faq_title_en: "",
        thumbnail: "",
      };
    return {
      id: initData.id,
      subcategory: "",
      banner: initData.banner ?? "",
      title_fa: initData.title.fa,
      title_en: initData.title.en,
      canonical: initData?.canonical ?? "",
      description_fa: initData.description?.fa ?? "",
      description_en: initData.description?.en ?? "",
      seo_title_fa: initData.seo_title?.fa ?? "",
      seo_title_en: initData.seo_title?.en ?? "",
      seo_description_fa: initData.seo_description?.fa ?? "",
      seo_description_en: initData.seo_description?.en ?? "",
      faq_title_fa: initData.faq_title?.fa ?? "",
      faq_title_en: initData.faq_title?.en ?? "",
      thumbnail: initData.thumbnail,
    };
  };

  const tagForm = useForm<z.infer<typeof optionalSchema>>({
    resolver: zodResolver(tagFormSchema()),
    defaultValues: { ...getInitData(initData) },
  });
  const faqForm = useForm<z.infer<typeof faqFormSchema>>({
    resolver: zodResolver(faqFormSchema),
    defaultValues: {
      question_fa: "",
      question_en: "",
      answer_fa: "",
      answer_en: "",
    },
  });

  const handleSubmitTag = async (values: z.infer<typeof optionalSchema>) => {
    try {
      const desc = JSON.parse(
        JSON.stringify({
          fa: values.description_fa?.length ? values.description_fa : undefined,
          en: values.description_en?.length ? values.description_en : undefined,
        })
      );

      const seoTitle = JSON.parse(
        JSON.stringify({
          fa: values.seo_title_fa?.length ? values.seo_title_fa : undefined,
          en: values.seo_title_en?.length ? values.seo_title_en : undefined,
        })
      );

      const seoDesc = JSON.parse(
        JSON.stringify({
          fa: values.seo_description_fa?.length ? values.seo_description_fa : undefined,
          en: values.seo_description_en?.length ? values.seo_description_en : undefined,
        })
      );

      const faqTitle = JSON.parse(
        JSON.stringify({
          fa: values.faq_title_fa?.length ? values.faq_title_fa : undefined,
          en: values.faq_title_en?.length ? values.faq_title_en : undefined,
        })
      );

      await onSubmit(
        JSON.parse(
          JSON.stringify({
            id: values.id, // may cause error
            sub_category_id: values.subcategory, // may cause error
            title_fa: values.title_fa.trim().length ? values.title_fa.trim() : undefined,
            title_en: values.title_en.trim().length ? values.title_en.trim() : undefined,
            canonical: values.canonical.trim().length ? values.canonical.trim() : undefined,
            description: _.isEmpty(desc) ? undefined : desc,
            seo_title: _.isEmpty(seoTitle) ? undefined : seoTitle,
            seo_description: _.isEmpty(seoDesc) ? undefined : seoDesc,
            faq_title: _.isEmpty(faqTitle) ? undefined : faqTitle,
            thumbnail: values.thumbnail?.length ? values.thumbnail : undefined,
            banner: values.banner?.trim() ? values.banner.trim() : undefined,
            descriptions: [],
          })
        )
      );
    } catch (err) {
      Promise.reject(err);
    }
  };

  const handleSubmitFAQ = async (values: z.infer<typeof faqFormSchema>) => {
    try {
      await onAddFAQ!({
        question: { fa: values.question_fa, en: values.question_en },
        answer: { fa: values.answer_fa, en: values.answer_en },
      });
      faqForm.reset();
      setOpenFAQ(false);
    } catch (err) {
      Promise.reject(err);
    }
  };
  const handleSubmitEditFAQ = async (values: z.infer<typeof faqFormSchema>) => {
    try {
      await onEditFAQ!(
        {
          id: values.id!,
          question: { fa: values.question_fa, en: values.question_en },
          answer: { fa: values.answer_fa, en: values.answer_en },
        },
        tagForm.getValues("subcategory") as string
      );

      faqForm.reset();
      setEditOpenFAQ(false);
    } catch (err) {
      Promise.reject(err);
    }
  };

  const handleDeleteFAQ = async (id: string) => {
    try {
      setLoadingDeleteFAQ(id);
      await onDeleteFAQ!(id, tagForm.getValues("subcategory") as string);
    } catch (err) {
      Promise.reject(err);
    } finally {
      setLoadingDeleteFAQ("");
    }
  };

  const onUpload = async (
    e: React.ChangeEvent<HTMLInputElement>,
    field: "thumbnail" | "banner",
    loadingHandler: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (!e.target.files) return;
    try {
      loadingHandler(true);
      const fd = new FormData();
      fd.append("image", e.target.files[0]);
      fd.append("watermark", "true");
      const resp = await AdminsAPI.upload(fd);
      tagForm.setValue(field, resp.data.data.url, { shouldValidate: true });
    } catch (err) {
      if (isAxiosError(err)) {
        return toast({
          description: err.response?.data.message,
          variant: "destructive",
        });
      }
      if (err instanceof Error) {
        return toast({
          description: err.message,
          variant: "destructive",
        });
      }
    } finally {
      loadingHandler(false);
    }
  };

  React.useEffect(() => {
    if (!selectedCategory) return;
    SubcategoryAPI.getListByCategoryId(selectedCategory).then((resp) => {
      setSubcategories(resp.data.data.items);
    });
  }, [selectedCategory]);

  return (
    <Form {...tagForm}>
      <form
        ref={categoryFormRef}
        onSubmit={tagForm.handleSubmit(handleSubmitTag, console.log)}
        className="grid grid-cols-4 gap-4 bg-gray-scale-5 p-4"
      >
        <Select
          disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_TAG])}
          onValueChange={setSelectedCategory}
          value={selectedCategory}
        >
          <SelectTrigger className="col-span-2 dir-rtl">
            <SelectValue placeholder={t("productsTags.category")} />
          </SelectTrigger>
          <SelectContent>
            {categories.map((category) => (
              <SelectItem key={category.id} value={category.id}>
                {category.title.fa}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <FormField
          control={tagForm.control}
          name="subcategory"
          render={({ field }) => (
            <FormItem className="col-span-2">
              <FormControl>
                <Select
                  disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_TAG])}
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <SelectTrigger className="dir-rtl">
                    <SelectValue placeholder={t("productsTags.subcategory")} />
                  </SelectTrigger>
                  <SelectContent>
                    {subcategories.map((subcategory) => (
                      <SelectItem key={subcategory.id} value={subcategory.id}>
                        {subcategory.title.fa}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={tagForm.control}
          name="banner"
          render={({ field }) => (
            <FormItem className="col-span-4">
              <FormControl>
                <div
                  className="-z-10 h-40 flex items-center justify-center bg-black/20 bg-no-repeat bg-cover"
                  style={{ backgroundImage: `url("${field.value}")` }}
                >
                  <Button
                    disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_TAG])}
                    loading={uploadingBanner}
                    onClick={() => bannerRef.current?.click()}
                  >
                    <Trans i18nKey="common.uploadImage" />
                    <input
                      ref={bannerRef}
                      type="file"
                      className="hidden"
                      onChange={(e) => onUpload(e, "banner", setUploadingBanner)}
                    />
                  </Button>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div className="col-span-3 space-y-4">
          <FormField
            control={tagForm.control}
            name="title_fa"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>
                  <Trans i18nKey="categories.titleFa" />
                </FormLabel>
                <FormControl>
                  <Input
                    disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_TAG])}
                    placeholder={t("categories.titleFa")}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={tagForm.control}
            name="title_en"
            render={({ field }) => (
              <FormItem className="w-full -z-10">
                <FormLabel>
                  <Trans i18nKey="categories.titleEn" />
                </FormLabel>
                <FormControl>
                  <Input
                    disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_TAG])}
                    className="text-left dir-ltr placeholder:text-right font-montserrat placeholder:font-dana"
                    placeholder={t("categories.titleFa")}
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <FormField
          control={tagForm.control}
          name="thumbnail"
          render={({ field }) => (
            <FormItem>
              <FormControl>
                <div
                  className="h-40 flex items-center justify-center bg-black/20 bg-no-repeat bg-cover"
                  style={{ backgroundImage: `url("${field.value}")` }}
                >
                  <Button
                    disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_TAG])}
                    loading={uploadingThumbnail}
                    onClick={() => thumbnailRef.current?.click()}
                  >
                    <Trans i18nKey="common.uploadImage" />
                    <input
                      ref={thumbnailRef}
                      type="file"
                      className="hidden"
                      onChange={(e) => onUpload(e, "thumbnail", setUploadingThumbnail)}
                    />
                  </Button>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={tagForm.control}
          name="seo_title_fa"
          render={({ field }) => (
            <FormItem className="col-span-2">
              <FormLabel>
                <Trans i18nKey="categories.seoTitleFa" />
              </FormLabel>
              <FormControl>
                <Input
                  disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_TAG])}
                  placeholder={t("categories.seoTitleFa")}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={tagForm.control}
          name="seo_title_en"
          render={({ field }) => (
            <FormItem className="col-span-2">
              <FormLabel>
                <Trans i18nKey="categories.seoTitleEn" />
              </FormLabel>
              <FormControl>
                <Input
                  disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_TAG])}
                  className="text-left dir-ltr placeholder:text-right font-montserrat placeholder:font-dana"
                  placeholder={t("categories.seoTitleEn")}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={tagForm.control}
          name="seo_description_fa"
          render={({ field }) => (
            <FormItem className="col-span-2">
              <FormLabel>
                <Trans i18nKey="categories.seoDescriptionFa" />
              </FormLabel>
              <FormControl>
                <Input
                  disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_TAG])}
                  placeholder={t("categories.seoDescriptionFa")}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={tagForm.control}
          name="seo_description_en"
          render={({ field }) => (
            <FormItem className="col-span-2">
              <FormLabel>
                <Trans i18nKey="categories.seoDescriptionEn" />
              </FormLabel>
              <FormControl>
                <Input
                  disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_TAG])}
                  className="text-left dir-ltr placeholder:text-right font-montserrat placeholder:font-dana"
                  placeholder={t("categories.seoDescriptionEn")}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={tagForm.control}
          name="faq_title_fa"
          render={({ field }) => (
            <FormItem className="col-span-2">
              <FormLabel>
                <Trans i18nKey="categories.faqTitleFa" />
              </FormLabel>
              <FormControl>
                <Input
                  disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_TAG])}
                  placeholder={t("categories.faqTitleFa")}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={tagForm.control}
          name="faq_title_en"
          render={({ field }) => (
            <FormItem className="col-span-2">
              <FormLabel>
                <Trans i18nKey="categories.faqTitleEn" />
              </FormLabel>
              <FormControl>
                <Input
                  disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_TAG])}
                  className="text-left dir-ltr placeholder:text-right font-montserrat placeholder:font-dana"
                  placeholder={t("categories.faqTitleEn")}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={tagForm.control}
          name="canonical"
          render={({ field }) => (
            <FormItem className="col-span-4">
              <FormLabel>
                <Trans i18nKey="categories.canonical" />
              </FormLabel>
              <FormControl>
                <Input
                  disabled={!hasPermissions(profile, [ADMIN_PERMISSIONS.WRITE_TAG])}
                  className="text-left dir-ltr font-montserrat placeholder:font-dana placeholder:text-right"
                  placeholder={t("categories.canonical")}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {/* Description's Editors */}
        <div className="flex flex-col gap-1 col-span-4">
          <span>
            <Trans i18nKey="categories.desc" />
          </span>
          <Tabs defaultValue="fa" className="w-full">
            <TabsList className="w-full grid grid-cols-2">
              <TabsTrigger value="en">
                <Trans i18nKey="categories.descEn" />
              </TabsTrigger>
              <TabsTrigger value="fa">
                <Trans i18nKey="categories.descFa" />
              </TabsTrigger>
            </TabsList>
            <TabsContent value="fa">
              <FormField
                control={tagForm.control}
                name="description_fa"
                render={({ field }) => (
                  <FormItem className="col-span-4">
                    <FormControl>
                      <Editor data={field.value} onChange={field.onChange} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </TabsContent>
            <TabsContent value="en">
              <FormField
                control={tagForm.control}
                name="description_en"
                render={({ field }) => (
                  <FormItem className="col-span-4">
                    <FormControl>
                      <Editor en data={field.value} onChange={field.onChange} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </TabsContent>
          </Tabs>
        </div>

        {/* FAQ */}
        {initData ? (
          <>
            <div className="col-span-4 flex items-center justify-between">
              <span>
                <Trans i18nKey="categories.faq" />
              </span>
              <Dialog open={openFAQ} onOpenChange={setOpenFAQ}>
                <DialogTrigger className={buttonVariants()}>
                  <Trans i18nKey="common.addNew" />
                </DialogTrigger>
                <DialogContent className="max-w-4xl">
                  <DialogHeader>
                    <DialogTitle>
                      <Trans i18nKey="categories.addNewFaq" />
                    </DialogTitle>
                  </DialogHeader>
                  <Form {...faqForm}>
                    <form
                      ref={faqFormRef}
                      className="grid grid-cols-4 gap-4"
                      onSubmit={faqForm.handleSubmit(handleSubmitFAQ)}
                    >
                      <FormField
                        control={faqForm.control}
                        name="question_fa"
                        render={({ field }) => (
                          <FormItem className="col-span-2">
                            <FormLabel>
                              <Trans i18nKey="categories.questionFa" />
                            </FormLabel>
                            <FormControl>
                              <Input placeholder={t("categories.questionFa")} {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={faqForm.control}
                        name="question_en"
                        render={({ field }) => (
                          <FormItem className="col-span-2">
                            <FormLabel>
                              <Trans i18nKey="categories.questionEn" />
                            </FormLabel>
                            <FormControl>
                              <Input
                                className="text-left dir-ltr font-montserrat placeholder:font-dana placeholder:text-right"
                                placeholder={t("categories.questionEn")}
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={faqForm.control}
                        name="answer_fa"
                        render={({ field }) => (
                          <FormItem className="col-span-2">
                            <FormLabel>
                              <Trans i18nKey="categories.answerFa" />
                            </FormLabel>
                            <FormControl>
                              <Textarea placeholder={t("categories.answerFa")} {...field} />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={faqForm.control}
                        name="answer_en"
                        render={({ field }) => (
                          <FormItem className="col-span-2">
                            <FormLabel>
                              <Trans i18nKey="categories.answerEn" />
                            </FormLabel>
                            <FormControl>
                              <Textarea
                                className="text-left dir-ltr font-montserrat placeholder:font-dana placeholder:text-right"
                                placeholder={t("categories.answerEn")}
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <div className="flex justify-end col-span-4 gap-2">
                        <DialogClose asChild>
                          <Button variant="outline" onClick={() => faqForm.reset()}>
                            <Trans i18nKey="common.cancel" />
                          </Button>
                        </DialogClose>
                        <Button
                          onClick={faqForm.handleSubmit(handleSubmitFAQ)}
                          loading={faqForm.formState.isSubmitting}
                        >
                          <Trans i18nKey="common.submit" />
                        </Button>
                      </div>
                    </form>
                  </Form>
                </DialogContent>
              </Dialog>
            </div>
            <Collapsible className="col-span-4">
              <CollapsibleTrigger asChild>
                <div className="flex items-center justify-between border-b border-white/10 cursor-pointer">
                  <span>
                    <Trans i18nKey="categories.faqList" />
                  </span>
                  <Button variant="secondary" size="sm" className="w-9 p-0">
                    <ChevronDown />
                  </Button>
                </div>
              </CollapsibleTrigger>
              <CollapsibleContent className="pt-4 flex flex-col gap-4">
                {initData.faq_list.length ? (
                  initData.faq_list.map((item) => (
                    <div key={item.id} className="bg-gray-scale-6/50 p-2 flex">
                      <div className="flex-1 space-y-1">
                        <div>
                          <p>{item.question.fa}</p>
                          <p className="text-sm text-gray-scale-4 dir-ltr">{item.question.en}</p>
                        </div>

                        <div>
                          <p>{item.answer.fa}</p>
                          <p className="text-sm text-gray-scale-4 dir-ltr">{item.answer.en}</p>
                        </div>
                      </div>
                      <div className="flex flex-col justify-center gap-1">
                        <Dialog open={openEditFAQ} onOpenChange={setEditOpenFAQ}>
                          <DialogContent className="max-w-4xl">
                            <DialogHeader>
                              <DialogTitle>
                                <Trans i18nKey="categories.addNewFaq" />
                              </DialogTitle>
                            </DialogHeader>
                            <Form {...faqForm}>
                              <form
                                ref={faqFormRef}
                                className="grid grid-cols-4 gap-4"
                                onSubmit={faqForm.handleSubmit(handleSubmitEditFAQ)}
                              >
                                <FormField
                                  control={faqForm.control}
                                  name="question_fa"
                                  render={({ field }) => (
                                    <FormItem className="col-span-2">
                                      <FormLabel>
                                        <Trans i18nKey="categories.questionFa" />
                                      </FormLabel>
                                      <FormControl>
                                        <Input placeholder={t("categories.questionFa")} {...field} />
                                      </FormControl>
                                      <FormMessage />
                                    </FormItem>
                                  )}
                                />
                                <FormField
                                  control={faqForm.control}
                                  name="question_en"
                                  render={({ field }) => (
                                    <FormItem className="col-span-2">
                                      <FormLabel>
                                        <Trans i18nKey="categories.questionEn" />
                                      </FormLabel>
                                      <FormControl>
                                        <Input
                                          className="text-left dir-ltr font-montserrat placeholder:font-dana placeholder:text-right"
                                          placeholder={t("categories.questionEn")}
                                          {...field}
                                        />
                                      </FormControl>
                                      <FormMessage />
                                    </FormItem>
                                  )}
                                />
                                <FormField
                                  control={faqForm.control}
                                  name="answer_fa"
                                  render={({ field }) => (
                                    <FormItem className="col-span-2">
                                      <FormLabel>
                                        <Trans i18nKey="categories.answerFa" />
                                      </FormLabel>
                                      <FormControl>
                                        <Textarea placeholder={t("categories.answerFa")} {...field} />
                                      </FormControl>
                                      <FormMessage />
                                    </FormItem>
                                  )}
                                />
                                <FormField
                                  control={faqForm.control}
                                  name="answer_en"
                                  render={({ field }) => (
                                    <FormItem className="col-span-2">
                                      <FormLabel>
                                        <Trans i18nKey="categories.answerEn" />
                                      </FormLabel>
                                      <FormControl>
                                        <Textarea
                                          className="text-left dir-ltr font-montserrat placeholder:font-dana placeholder:text-right"
                                          placeholder={t("categories.answerEn")}
                                          {...field}
                                        />
                                      </FormControl>
                                      <FormMessage />
                                    </FormItem>
                                  )}
                                />
                                <div className="flex justify-end col-span-4 gap-2">
                                  <DialogClose>
                                    <Button variant="outline" onClick={() => faqForm.reset()}>
                                      <Trans i18nKey="common.cancel" />
                                    </Button>
                                  </DialogClose>
                                  <Button
                                    onClick={faqForm.handleSubmit(handleSubmitEditFAQ)}
                                    loading={faqForm.formState.isSubmitting}
                                  >
                                    <Trans i18nKey="common.submit" />
                                  </Button>
                                </div>
                              </form>
                            </Form>
                          </DialogContent>
                        </Dialog>
                        <Button
                          size="icon"
                          variant="ghost"
                          onClick={() => {
                            faqForm.reset({
                              question_fa: item.question.fa,
                              question_en: item.question.en,
                              answer_fa: item.answer.fa,
                              answer_en: item.answer.en,
                              id: item.id,
                            });
                            setEditOpenFAQ(true);
                          }}
                        >
                          <Edit2 size={16} />
                        </Button>
                        <Button
                          onClick={() => handleDeleteFAQ(item.id)}
                          loading={loadingDeleteFAQ === item.id}
                          size="icon"
                          variant="ghost-destructive"
                        >
                          <Trash2 size={20} />
                        </Button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-center text-gray-scale-4">
                    <Trans i18nKey="common.isEmpty" />
                  </div>
                )}
              </CollapsibleContent>
            </Collapsible>
          </>
        ) : null}

        {/* Submit */}
        <div className="col-span-4 flex justify-end">
          <Button
            loading={tagForm.formState.isSubmitting}
            onClick={() => {
              if (categoryFormRef.current) {
                categoryFormRef.current.dispatchEvent(new Event("submit", { bubbles: true }));
              }
            }}
          >
            <Trans i18nKey="common.submit" />
          </Button>
        </div>
      </form>
    </Form>
  );
}
