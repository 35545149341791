const ProductBody = (props: { className?: string }) => (
  <svg width={40} height={40} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" className={props.className}>
    <path
      d="M10.625 13.4244C12.725 17.1684 10.391 18.2244 8.746 20.3814C7.101 22.5384 3.434 27.6376 7.46 32.8326C11.486 38.0276 20.055 39.6076 26.485 37.1646C32.915 34.7216 35.974 26.9732 33.185 23.0572C30.396 19.1412 26.161 16.7665 29.279 12.6275C32.397 8.4885 30.367 5.44746 29.279 6.87946C28.191 8.31146 26.592 9.62507 25.143 8.97907C23.694 8.33307 21.861 7.12888 21.96 2.73688C18.64 0.89788 3.67 1.84238 10.625 13.4244Z"
      fill="#E25317"
    />
    <path d="M25 0H15V14H25V0Z" fill="#E25317" />
    <path d="M18 0H16V28H18V0Z" className="fill-gray-scale-5" />
    <path d="M21 0H19V28H21V0Z" className="fill-gray-scale-5" />
    <path d="M24 0H22V28H24V0Z" className="fill-gray-scale-5" />
    <path d="M25 18H15V23H25V18Z" fill="#E25317" />
    <path d="M24 19H16V22H24V19Z" className="fill-gray-scale-5" stroke="#E25317" />
    <path d="M25 27H15V29H25V27Z" className="fill-white" />
  </svg>
);
export default ProductBody;
