import { useTranslation } from "react-i18next";
import ComputerSVG from "@/assets/images/computer.svg";
import React from "react";

export default function Mobile() {
  const { t } = useTranslation();

  React.useEffect(() => {
    const bodyEl = document.querySelector("body");
    bodyEl?.classList.add("min-w-full");
    return () => {
      bodyEl?.classList.remove("min-w-full");
    };
  }, []);

  return (
    <main className="h-screen flex items-center justify-center px-4">
      <div className="flex items-center flex-col bg-white/10 p-4 gap-2">
        <img src={ComputerSVG} alt="computer" className="w-64" />
        <h1 className="text-2xl font-bold text-white">{t("mobile.title")}</h1>
        <p className="text-white/50">{t("mobile.desc")}</p>
      </div>
    </main>
  );
}
