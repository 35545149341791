export default {
  title: "مدیریت محصولات",
  totals: {
    totalCategoriesCount: "تعداد کل دسته بندی‌ها",
    subcategories: "تعداد کل زیردسته بندی‌ها",
    products: "تعداد کل محصولات",
    tags: "تعداد کل تگها",
    totalBrandsCount: "تعداد کل برندها",
  },
  categoriesManagement: "مدیریت دسته بندی‌ها",
  subCategoriesManagement: "مدیریت زیردسته بندی‌ها",
  productsManagement: "مدیریت محصولات",
  tagsManagement: "مدیریت تگ‌ها",
  brandsManagement: "مدیریت برندها",
  modelsManagement: "مدیریت مدلها",
  priceManagement: "مدیریت قیمت‌/موجودی",
  latestProducts: "آخرین محصولات",
};
