import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { fa } from "@/dictionary";

i18n
  .use(initReactI18next)
  .init({
    resources: {
      fa,
    },
    lng: "fa",
  })
  .then(() => {
    if (import.meta.env.DEV) {
      console.log("i18n initialized");
    }
  });

export default i18n;
