import { API } from "@/lib/axios";
import { TBrand, TBrandDetailed, TBrandNewFAQItem, TNewBrand } from "@/types/brand";
import { TTranslatedText } from "@/types/general";
import { AxiosResponse } from "axios";

("");
const ENTITY = "/brand";

export const getList = async (query: {
  page: number;
  limit: number;
  query?: string;
}): Promise<AxiosResponse<{ data: { items: TBrand[] } }>> => {
  return API.post(`${ENTITY}/list`, {
    pagination: { page: query.page, limit: query.limit },
    query: query.query,
  });
};

export const deleteById = async (id: string): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.delete(`${ENTITY}/${id}`);

export const addQAById = async (
  brandId: string,
  data: TBrandNewFAQItem
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.post(`${ENTITY}/faq-list/add`, { brand_id: brandId, ...data });

export const deleteQAById = async (
  id: string,
  brandId: string
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.delete(`${ENTITY}/faq-list/delete`, { data: { brand_id: brandId, id } });

export const updateQAbyId = async (
  faqId: string,
  brandId: string,
  data: { question: TTranslatedText; answer: TTranslatedText }
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.patch(`${ENTITY}/faq-list/update`, { brand_id: brandId, id: faqId, ...data });

export const create = async (data: TNewBrand): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> =>
  API.post(ENTITY, data);

export const update = async (
  id: string,
  data: TNewBrand
): Promise<AxiosResponse<{ data: { message: TTranslatedText } }>> => API.patch(ENTITY, { brand_id: id, ...data });

export const getById = async (id: string): Promise<AxiosResponse<{ data: TBrandDetailed }>> =>
  API.get(`${ENTITY}/${id}`);
