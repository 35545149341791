import { cn } from "@/lib/utils";
import { Menu } from "lucide-react";
import React from "react";
import { isMobile, isTablet } from "react-device-detect";
import { Navigate, Outlet } from "react-router-dom";
import { Button } from "../ui/button";
import Sidebar from "./sidebar";
// import GlobalLoading from "../global-loading";

export default function RootLayout() {
  // const navigation = useNavigation();
  const [isOpen, setIsOpen] = React.useState(true);
  if (isMobile && !isTablet) {
    return <Navigate to="/mobile" />;
  }
  // if (navigation.state === "loading") {
  //   return <GlobalLoading />;
  // }

  return (
    <main className={cn("pr-[320px] duration-150 animate-in min-h-screen h-full", { "pr-0": !isOpen })}>
      <Sidebar open={isOpen} toggleOpen={() => setIsOpen((prev) => !prev)} />
      <section className="p-4 relative h-full min-h-screen flex flex-col">
        <Button
          variant="ghost"
          size="icon"
          onClick={() => setIsOpen((prev) => !prev)}
          className={cn("opacity-0 -top-1 -right-1 !absolute z-10 text-white", { "opacity-100": !isOpen })}
        >
          <Menu />
        </Button>
        <Outlet />
      </section>
    </main>
  );
}
