import { OrderAPI } from "@/api";
import { ADMIN_PERMISSIONS } from "@/consts/admin.const";
import { apiExecByPermission } from "@/lib/utils";
import { TOrderFilters, TOrderStatus } from "@/types/order";
import qs from "qs";
import { LoaderFunctionArgs } from "react-router-dom";

export default async function loader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const queryString: Partial<{ status: TOrderStatus; query: string; page: number; limit: number }> = qs.parse(
    url.searchParams.toString()
  );
  const filters: TOrderFilters = {
    page: Number(queryString.page) || 1,
    limit: Number(queryString.limit) || 10,
    status: queryString.status && (Number(queryString.status) as TOrderStatus),
    query: queryString.query as string,
  };

  return Promise.all([
    apiExecByPermission([ADMIN_PERMISSIONS.READ_ORDER], () => OrderAPI.getList(filters)),
    apiExecByPermission([ADMIN_PERMISSIONS.READ_ORDER], () => OrderAPI.getStatusCounts()),
  ])
    .then(([list, counts]) => {
      const statusCounts = counts.data.data.items.reduce((acc, item) => {
        acc[item.status] = item.count;
        return acc;
      }, {} as Record<TOrderStatus, number>);
      return { list: list.data.data, counts: statusCounts };
    })
    .catch(() => null);
}
