import i18n from "@/lib/i18n";
import { z } from "zod";

export const draftSchema = z.object({
  // Required Fields
  name: z.string().min(1, { message: i18n.t("common.isRequired") }),
  brand: z.string().min(1, { message: i18n.t("common.isRequired") }),
  model: z.string().min(1, { message: i18n.t("common.isRequired") }),
  category: z.string().min(1, { message: i18n.t("common.isRequired") }),
  subcategory: z.string().min(1, { message: i18n.t("common.isRequired") }),
  seo_name_fa: z.string().min(1, { message: i18n.t("common.isRequired") }),
  seo_name_en: z.string().min(1, { message: i18n.t("common.isRequired") }),
  color: z
    .object({
      base_color: z.string().optional(),
      product_color_name_fa: z.string().optional(),
      product_color_name_en: z.string().optional(),
      has_color: z.boolean(),
    })
    .optional(),
  tags: z
    .array(z.string().min(1, { message: i18n.t("common.isRequired") }))
    .min(1, { message: i18n.t("common.isRequired") }),

  // Optional Fields
  description: z
    .object({
      fa: z.string(),
      en: z.string(),
    })
    .optional(),

  price: z.object({
    //   is_competitive: z.boolean(), // FIXME: need to be implemented competitive
    price: z.string(),
    call_to_buy: z.boolean(),
    is_used: z.boolean(),
    sale: z
      .object({
        off_percent: z.string().optional(),
        is_on_sale: z.boolean().optional(),
      })
      .optional(),
  }),
  images: z
    .array(
      z.object({
        url: z.string(),
        thumbnail: z.string(),
        is_primary: z.boolean(),
      })
    )
    .optional(),
  availability: z
    .object({
      // is_available: z.boolean(), // FIXME: need to be implemented availability
      total_count: z.string().min(1, { message: i18n.t("common.isRequired") }),
    })
    .optional(),
  features: z
    .array(
      z.object({
        fa: z.string(),
        en: z.string(),
      })
    )
    .optional(),
  properties: z
    .array(
      z.object({
        title: z.object({
          fa: z.string(),
          en: z.string(),
        }),
        icon: z.string(),
        items: z
          .array(
            z.object({
              fa: z.object({
                prop: z.string(),
                value: z.string(),
              }),
              en: z.object({
                prop: z.string(),
                value: z.string(),
              }),
            })
          )
          .optional(),
      })
    )
    .optional(),
  health_check_list: z
    .array(
      z.object({
        standard_value: z.string(),
        product_value: z.string(),
        title: z.string(),
        score: z.string(),
        reference_id: z.string(),
      })
    )
    .optional(),
  // Form essential data
  id: z.string().optional(),
});

export const publishSchema = z.object({
  // Product data
  name: z.string().min(1, { message: i18n.t("common.isRequired") }),
  brand: z.string().min(1, { message: i18n.t("common.isRequired") }),
  model: z.string().min(1, { message: i18n.t("common.isRequired") }),
  category: z.string().min(1, { message: i18n.t("common.isRequired") }),
  subcategory: z.string().min(1, { message: i18n.t("common.isRequired") }),
  seo_name_fa: z.string().min(1, { message: i18n.t("common.isRequired") }),
  seo_name_en: z.string().min(1, { message: i18n.t("common.isRequired") }),

  // Complicated Fields
  tags: z
    .array(z.string().min(1, { message: i18n.t("common.isRequired") }))
    .min(1, { message: i18n.t("common.isRequired") }),
  description: z.object({
    fa: z.string().min(1, { message: i18n.t("common.isRequired") }),
    en: z.string().min(1, { message: i18n.t("common.isRequired") }),
  }),
  color: z
    .object({
      base_color: z.string().optional(),
      product_color_name_fa: z.string().optional(),
      product_color_name_en: z.string().optional(),
      has_color: z.boolean().optional(),
    })
    .optional(),
  price: z.object({
    //   is_competitive: z.boolean(), // FIXME: need to be implemented competitive
    price: z.string().min(1, { message: i18n.t("common.isRequired") }),
    call_to_buy: z.boolean(),
    is_used: z.boolean(),
    sale: z
      .object({
        off_percent: z.string().optional(),
        is_on_sale: z.boolean().optional(),
      })
      .optional(),
  }),
  images: z
    .array(
      z.object({
        url: z.string().min(1, { message: i18n.t("common.isRequired") }),
        thumbnail: z.string().min(1, { message: i18n.t("common.isRequired") }),
        is_primary: z.boolean(),
      })
    )
    .min(1, { message: i18n.t("common.isRequired") }),
  availability: z.object({
    // is_available: z.boolean(), // FIXME: need to be implemented availability
    total_count: z.string().min(1, { message: i18n.t("common.isRequired") }),
  }),
  features: z
    .array(
      z.object({
        fa: z.string().min(1, { message: i18n.t("common.isRequired") }),
        en: z.string().min(1, { message: i18n.t("common.isRequired") }),
      })
    )
    .min(1, { message: i18n.t("common.isRequired") }),
  properties: z
    .array(
      z.object({
        title: z.object({
          fa: z.string().min(1, { message: i18n.t("common.isRequired") }),
          en: z.string().min(1, { message: i18n.t("common.isRequired") }),
        }),
        icon: z.string().min(1, { message: i18n.t("common.isRequired") }),
        items: z
          .array(
            z.object({
              fa: z.object({
                prop: z.string().min(1, { message: i18n.t("common.isRequired") }),
                value: z.string().min(1, { message: i18n.t("common.isRequired") }),
              }),
              en: z.object({
                prop: z.string().min(1, { message: i18n.t("common.isRequired") }),
                value: z.string().min(1, { message: i18n.t("common.isRequired") }),
              }),
            })
          )
          .min(1, { message: i18n.t("common.isRequired") }),
      })
    )
    .min(1, { message: i18n.t("common.isRequired") }),
  health_check_list: z
    .array(
      z.object({
        standard_value: z.string(),
        product_value: z.string(),
        title: z.string(),
        score: z.string(),
        reference_id: z.string(),
      })
    )
    .optional(), // FIXME: need to be required when the price.is_used is true
  // Form essential data
  id: z.string().optional(),
});

export type TProductFormPublish = z.infer<typeof publishSchema>;
export type TProductForm = z.infer<typeof draftSchema>;
