export default {
  title: "مدیریت سفارشات",
  totalCount: "تعداد کل سفارشات",
  totalMonthCount: "تعداد سفارشات در این ماه",
  totalSale: "تعداد کل خرید ها",
  totalMonthSale: "تعداد کل خرید ها در این ماه",
  search: "جستجو",
  orderDate: "<0>تاریخ سفارش:</0> {{date}}",
  orderCode: "کد سفارش:",
  orderTotal: "<0>مجموع سفارش:</0> {{total}} تومان",
  status: {
    all: "همه",
    waitingForPayment: "در انتظار پرداخت",
    paid: "پرداخت شده",
    sending: "ارسال شده",
    delivered: "تحویل داده شده",
    canceled: "لغو شده",
    pendingForReturn: "در انتظار برگشت",
    pendingForWithdrawal: "در انتظار برداشت",
    returned: "برگشت داده شده",
  },
};
