import { ProductAPI } from "@/api";
import { TProduct } from "@/types/product";
import { zodResolver } from "@hookform/resolvers/zod";
import { addCommas } from "@persian-tools/persian-tools";
import { isAxiosError } from "axios";
import { ChevronLeft, ExternalLink } from "lucide-react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { z } from "zod";
import Price from "./price";
import { Button, buttonVariants } from "./ui/button";
import { Checkbox } from "./ui/checkbox";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "./ui/form";
import { Input } from "./ui/input";
import { toast } from "./ui/use-toast";

type TProps = {
  product: TProduct;
};

export default function PriceProductCard(props: TProps) {
  const { product } = props;
  const { t } = useTranslation();

  const formSchema = z.object({
    price: z
      .string()
      .min(1, { message: t("common.incorrectInput") })
      .optional(),
    is_competitive: z.boolean().optional(),
    call_to_buy: z.boolean().optional(),
    total_count: z
      .string()
      .min(1, { message: t("common.incorrectInput") })
      .optional(),
    id: z.string(),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      id: product.id,
      price: String(product.price),
      is_competitive: false,
      call_to_buy: product.call_to_buy,
      total_count: String(product.total_count),
    },
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    try {
      const resp = await ProductAPI.updatePriceById(values.id, {
        call_to_buy: values.call_to_buy,
        is_competitive: values.is_competitive,
        price: parseInt(values.price ?? "0"),
        total_count: parseInt(values.total_count ?? "0"),
      });
      toast({
        description: resp.data.data.message.fa,
      });
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          description: err.response?.data.message,
          variant: "destructive",
        });
        return;
      }

      if (err instanceof Error) {
        toast({
          description: err.message,
        });
      }
    }
  };

  return (
    <div className="bg-gray-scale-5 flex h-full">
      <div className="bg-white flex items-center justify-center min-h-[130px] w-[180px] relative">
        <img className="w-full h-full object-cover object-bottom" src={product.image} alt={product.name} />
        {product.is_used ? (
          <span className="inline-block bg-primary text-white text-xs absolute bottom-0 right-0 px-2">
            <Trans i18nKey="common.usedItem" />
          </span>
        ) : null}
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit, console.log)} className="flex-1 px-4 py-2">
          <header className="flex items-center gap-1">
            <div className="flex gap-1 flex-col [&_*]:!text-xs text-xs text-gray-scale-4 flex-1">
              <span>
                <Trans
                  i18nKey="prices.lastUpdate"
                  values={{
                    date: new Intl.DateTimeFormat("fa-IR", { year: "numeric", month: "long", day: "2-digit" }).format(
                      new Date(product.updated_at)
                    ),
                  }}
                />
              </span>
              <div className="flex items-center gap-1">
                <span className="text-nowrap">
                  <Trans i18nKey="prices.currentSituation" />:
                </span>
                <Price
                  call_to_buy={product.call_to_buy}
                  price={product.price}
                  price_to_pay={product.price_to_pay}
                  is_on_sale={product.is_on_sale}
                  is_available={product.is_available}
                />
              </div>
            </div>
            <div className="flex items-center gap-1">
              <Button loading={form.formState.isSubmitting} type="submit">
                <Trans i18nKey="common.save" />{" "}
              </Button>
              <a
                className={buttonVariants({ variant: "secondary", size: "icon" })}
                href={import.meta.env.VITE_SHOP_BASE_URL + "/product/" + product.slug}
                target="_blank"
              >
                <ExternalLink />
              </a>
              <NavLink
                className={buttonVariants({
                  variant: "secondary",
                  size: "icon",
                })}
                to={`/products/${product.id}`}
              >
                <ChevronLeft />
              </NavLink>
            </div>
          </header>
          <div className="flex flex-col items-start gap-1 my-2">
            <span>{product.name}</span>

            <div className="flex items-center gap-1 text-xs text-gray-scale-4">
              <span>{product.category.title.fa}</span>
              &nbsp;|&nbsp;
              <span>{product.brand.name.fa}</span>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-1">
            <input {...form.register("id")} className="hidden" />
            <FormField
              control={form.control}
              name="price"
              render={({ field }) => {
                const { onChange, value, ...rest } = field;
                return (
                  <FormItem className="space-y-0">
                    <FormLabel>
                      <Trans i18nKey="prices.price" />
                    </FormLabel>
                    <FormControl>
                      <Input
                        className="text-left dir-ltr placeholder:dir-ltr placeholder:text-right placeholder:font-dana font-montserrat"
                        value={addCommas(value ?? "")}
                        onChange={(e) => {
                          onChange(e.target.value.replace(/,/g, ""));
                        }}
                        placeholder={t("prices.price")}
                        {...rest}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
            <FormField
              control={form.control}
              name="total_count"
              render={({ field }) => (
                <FormItem className="space-y-0">
                  <FormLabel>
                    <Trans i18nKey="prices.count" />
                  </FormLabel>
                  <FormControl>
                    <Input
                      className="text-left placeholder:text-right placeholder:font-dana font-montserrat"
                      placeholder={t("prices.count")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="call_to_buy"
              render={({ field }) => (
                <FormItem className="space-y-0 mr-1 flex items-end gap-1 mb-3">
                  <FormLabel>
                    <Trans i18nKey="prices.callToBuy" />
                  </FormLabel>
                  <FormControl>
                    <Checkbox checked={field.value} onCheckedChange={field.onChange} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </form>
      </Form>
    </div>
  );
}
