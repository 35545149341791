import { AdminsAPI, LogsAPI } from "@/api";
import { TLogQuery } from "@/types/log";
import qs from "qs";
import { LoaderFunctionArgs } from "react-router-dom";

export default async function loader({ request }: LoaderFunctionArgs) {
  const url = new URL(request.url);
  const queryString: Partial<TLogQuery> = qs.parse(url.searchParams.toString());
  const query: TLogQuery = {
    page: Number(queryString.page) || 1,
    limit: Number(queryString.limit) || 10,
    user: queryString.user as string,
    entity: queryString.entity as string,
  };

  return Promise.all([LogsAPI.getList(query), AdminsAPI.getList({ limit: 100, page: 1 })])
    .then(([logs, admins]) => {
      return {
        logs: logs.data.data,
        admins: admins.data.data.items,
      };
    })
    .catch(() => null);
}
