export default {
  title: "مدیریت زیر دسته‌بندی‌ها",
  categories: "دسته‌بندی‌ها",
  addNew: "افزودن زیر دسته‌بندی",
  editLabel: "ویرایش زیردسته‌بندی",
  edit: "ویرایش زیردسته‌بندی {{title}}",
  delete: "حذف زیردسته‌بندی",
  deleteDesc: "ایا از حذف این زیردسته‌بندی مطمئن هستید؟",
  titleFa: "عنوان فارسی",
  titleEn: "عنوان انگلیسی",
  seoTitleFa: "عنوان سئو فارسی",
  seoTitleEn: "عنوان سئو انگلیسی",
  seoDescriptionFa: "توضیحات سئو فارسی",
  seoDescriptionEn: "توضیحات سئو انگلیسی",
  canonical: "کانونیکال",
  desc: "توضیحات",
  faq: "سوالات متداول",
  faqList: "لیست سوالات متداول",
  addNewFaq: "افزودن سوال جدید",
  questionFa: "سوال فارسی",
  questionEn: "سوال انگلیسی",
  answerFa: "پاسخ فارسی",
  answerEn: "پاسخ انگلیسی",
  descFa: "توضیحات فارسی",
  descEn: "توضیحات انگلیسی",
};
