import { CommentsAPI } from "@/api";
import { ADMIN_PERMISSIONS } from "@/consts/admin.const";
import { apiExecByPermission } from "@/lib/utils";
import { TCommentsQuery, TCommentsStatus } from "@/types/comment";
import qs from "qs";
import { LoaderFunctionArgs } from "react-router-dom";

export default async function loader({ request }: LoaderFunctionArgs) {
  try {
    const url = new URL(request.url);
    const queryString: Partial<TCommentsQuery> = qs.parse(url.searchParams.toString());
    const query: TCommentsQuery = {
      page: Number(queryString.page) || 1,
      limit: Number(queryString.limit) || 10,
      status: queryString.status ? (Number(queryString.status) as TCommentsStatus) : undefined,
    };

    const resp = await apiExecByPermission([ADMIN_PERMISSIONS.READ_COMMENT], () => CommentsAPI.getList(query));
    return resp.data.data;
  } catch (err) {
    console.log(err);
    return null;
  }
}
