import { ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME } from "@/consts/config.const";
import axios, { isAxiosError } from "axios";
import Cookies from "js-cookie";

export const API_URL = import.meta.env.VITE_API_BASE_URL + "/admin";

export const API = axios.create({ baseURL: API_URL });

API.interceptors.request.use(
  function (config) {
    config.headers["Authorization"] = "Bearer " + Cookies.get(ACCESS_TOKEN_NAME);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    if (isAxiosError(error)) {
      //   if (error.response?.status === 403) {
      //     const refreshToken = Cookies.get(REFRESH_TOKEN_NAME);
      //     if (refreshToken) {
      //       const resp = await AuthAPI.refreshToken(refreshToken);
      //       Cookies.set(ACCESS_TOKEN_NAME, resp.data.data.access_token, { expires: 365 });
      //       Cookies.set(REFRESH_TOKEN_NAME, resp.data.data.refresh_token, { expires: 365 });
      //       if (error.config) {
      //         error.config.headers["Authorization"] = "Bearer " + resp.data.data.access_token;
      //         return API.request(error.config);
      //       }
      //       window.location.reload();
      //     }
      //   }
      if (error.response?.status === 401) {
        Cookies.remove(ACCESS_TOKEN_NAME);
        Cookies.remove(REFRESH_TOKEN_NAME);
        localStorage.clear();
        window.location.href = "/auth/login";
      }
    }
    return Promise.reject(error);
  }
);
