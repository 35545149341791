import Logo from "@/assets/images/logo.svg";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";

import { AdminsAPI, AuthAPI } from "@/api";
import { InputPassword } from "@/components/input-password";
import { useAuth } from "@/components/providers/auth-provider";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { toast } from "@/components/ui/use-toast";
import { ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME } from "@/consts/config.const";
import { zodResolver } from "@hookform/resolvers/zod";
import { isAxiosError } from "axios";
import Cookies from "js-cookie";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { z } from "zod";
import { getRouteByPermission } from "@/lib/utils";

export default function Login() {
  const { t } = useTranslation();
  const auth = useAuth();
  const [searchParams] = useSearchParams();
  const from = searchParams.get("from");

  const formSchema = z.object({
    email: z.string().email(t("login.emailError")),
    password: z.string().min(1, t("login.passwordError")),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    try {
      const respToken = await AuthAPI.login(values.email, values.password);
      Cookies.set(ACCESS_TOKEN_NAME, respToken.data.data.access_token);
      Cookies.set(REFRESH_TOKEN_NAME, respToken.data.data.refresh_token);
      const respProfile = await AdminsAPI.getProfile();
      const getFirstRoute = getRouteByPermission(respProfile.data.data.permissions);
      auth.setProfile(respProfile.data.data);
      auth.setIsLoggedIn(true);
      window.location.href = from ?? getFirstRoute;
    } catch (error) {
      if (isAxiosError(error)) {
        return toast({ variant: "destructive", description: error.response?.data.fa });
      }

      if (error instanceof Error) {
        return toast({ variant: "destructive", description: error.message });
      }
    }
  }

  return (
    <main className="w-full h-screen bg-[url('/bg.webp')] bg-cover bg-no-repeat">
      <div className="h-full bg-black/70 w-1/4 min-w-[420px] backdrop-blur flex flex-col  justify-evenly p-10">
        <h1 className="text-7xl">{t("login.login")}</h1>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="w-full flex flex-col gap-5">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("login.email")}</FormLabel>
                  <FormControl>
                    <Input placeholder={t("login.email")} {...field} className="text-left placeholder:text-right" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{t("login.password")}</FormLabel>
                  <FormControl>
                    <InputPassword
                      className="font-montserrat text-left dir-ltr placeholder:text-right placeholder:font-dana"
                      placeholder={t("login.password")}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button loading={form.formState.isSubmitting} type="submit">
              {t("login.login")}
            </Button>
          </form>
        </Form>
        <div className="flex flex-col items-center gap-0.5">
          <div>
            <img src={Logo} alt="logo" />
          </div>
          <span className="text-xs tracking-[9.8px] capitalize">{t("login.brandName")}</span>
        </div>
      </div>
    </main>
  );
}
