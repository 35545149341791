import { UsersAPI } from "@/api";
import { ADMIN_PERMISSIONS } from "@/consts/admin.const";
import { apiExecByPermission } from "@/lib/utils";
import { TUserListQuery } from "@/types/user";
import qs from "qs";
import { LoaderFunctionArgs } from "react-router-dom";

export default async function loader({ request }: LoaderFunctionArgs) {
  try {
    const url = new URL(request.url);
    const queryString: Partial<{
      query: string;
      page: number;
      limit: number;
    }> = qs.parse(url.searchParams.toString());

    const query: TUserListQuery = {
      page: Number(queryString.page) || 1,
      limit: Number(queryString.limit) || 10,
      query: queryString.query as string,
    };
    const resp = await apiExecByPermission([ADMIN_PERMISSIONS.READ_USER], () => UsersAPI.getList(query));
    return resp.data.data;
  } catch (err) {
    console.log(err);
    return null;
  }
}
