import { RouterProvider } from "react-router-dom";
import router from "./router";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Toaster } from "./components/ui/toaster";
import { AuthProvider } from "./components/providers/auth-provider";

function App() {
  const { t } = useTranslation();
  return (
    <HelmetProvider>
      <Helmet>
        <title>{t("common.docTitle")}</title>
      </Helmet>
      <AuthProvider>
        <RouterProvider router={router} />
        <Toaster />
      </AuthProvider>
    </HelmetProvider>
  );
}

export default App;
