export default {
  title: "لیست لاگ‌ها",
  action: "لاگ/جزئیات",
  showLog: "مشاهده لاگ",
  noData: "اطلاعاتی موجود نیست",
  clearSearch: "پاک کردن فیلترها",
  users: "ادمین‌ها",
  userActions: {
    UpdateAdmin: "ویرایش ادمین",
    SignUpAdmin: "عضویت ادمین",
    DeleteAdmin: "حذف ادمین",
    UpdateAdminProfile: "ویرایش پروفایل ادمین",
    CreateBrand: "ایجاد برند",
    UpdateBrand: "ویرایش برند",
    DeleteBrand: "حذف برند",
    AddBrandFaq: "افزودن پرسش و پاسخ برند",
    UpdateBrandFaq: "ویرایش پرسش و پاسخ برند",
    DeleteBrandFaq: "حذف پرسش و پاسخ برند",
    MoveDownBrandFaq: "پایین بردن پرسش و پاسخ برند",
    MoveUpBrandFaq: "بالا بردن پرسش و پاسخ برند",

    CreateCategory: "ایجاد دسته‌بندی",
    UpdateCategory: "ویرایش دسته‌بندی",
    DeleteCategory: "حذف دسته‌بندی",
    AddCategoryFaq: "افزودن پرسش و پاسخ دسته‌بندی",
    UpdateCategoryFaq: "ویرایش پرسش و پاسخ دسته‌بندی",
    DeleteCategoryFaq: "حذف پرسش و پاسخ دسته‌بندی",
    MoveDownCategoryFaq: "پایین بردن پرسش و پاسخ دسته‌بندی",
    MoveUpCategoryFaq: "بالا بردن پرسش و پاسخ دسته‌بندی",

    CreateSubcategory: "ایجاد زیردسته‌بندی",
    UpdateSubcategory: "ویرایش زیردسته‌بندی",
    DeleteSubcategory: "حذف زیردسته‌بندی",
    AddSubcategoryFaq: "افزودن پرسش و پاسخ زیردسته‌بندی",
    UpdateSubcategoryFaq: "ویرایش پرسش و پاسخ زیردسته‌بندی",
    DeleteSubcategoryFaq: "حذف پرسش و پاسخ زیردسته‌بندی",
    MoveDownSubcategoryFaq: "پایین بردن پرسش و پاسخ زیردسته‌بندی",
    MoveUpSubcategoryFaq: "بالا بردن پرسش و پاسخ زیردسته‌بندی",

    CreateProduct: "ایجاد محصول",
    UpdateProduct: "ویرایش محصول",
    DeleteProduct: "حذف محصول",
    UpdateProductPrice: "ویرایش قیمت محصول",
    DraftProduct: "پیش‌نویس محصول",
    PublishProduct: "انتشار محصول",
    UpdateProductExcel: "به‌روزرسانی محصول از اکسل",

    CreateColor: "ایجاد رنگ",
    UpdateColor: "ویرایش رنگ",
    DeleteColor: "حذف رنگ",

    UpdateComment: "ویرایش نظر",
    ConfirmComment: "تأیید نظر",
    RejectComment: "رد نظر",
    DeleteComment: "حذف نظر",

    UpdateModel: "ویرایش مدل",
    CreateModel: "ایجاد مدل",
    DeleteModel: "حذف مدل",

    UpdateOrder: "ویرایش سفارش",
    ChangeOrderStatus: "تغییر وضعیت سفارش",
    UpdatePaymentMethod: "ویرایش روش پرداخت",
    SetOrderLockTime: "تنظیم زمان قفل سفارش",

    CreateVideo: "ایجاد ویدئو",
    UpdateVideo: "ویرایش ویدئو",
    DeleteVideo: "حذف ویدئو",
    CreateCarousel: "ایجاد کروسل",
    UpdateCarousel: "ویرایش کروسل",
    MoveDownCarousel: "پایین بردن کروسل",
    MoveUpCarousel: "بالا بردن کروسل",
    DeleteCarousel: "حذف کروسل",
    UpdateBanner: "ویرایش بنر",
    SetFooter: "تنظیم پاورقی",
    SetAboutUs: "تنظیم درباره ما",
    UpdateAboutUsBanner: "ویرایش بنر درباره ما",
    SetContactUs: "تنظیم تماس با ما",
    SetPolicy: "تنظیم سیاست",
    SetFaqContent: "تنظیم محتوای پرسش و پاسخ",
    AddFaq: "افزودن پرسش و پاسخ",
    DeleteFaq: "حذف پرسش و پاسخ",
    UpdateFaq: "ویرایش پرسش و پاسخ",
    MoveUpFaq: "بالا بردن پرسش و پاسخ",
    MoveDownFaq: "پایین بردن پرسش و پاسخ",
    AddSlider: "افزودن اسلایدر",
    DeleteSlider: "حذف اسلایدر",

    CreateTag: "ایجاد برچسب",
    UpdateTag: "ویرایش برچسب",
    DeleteTag: "حذف برچسب",
    AddTagFaq: "افزودن پرسش و پاسخ برچسب",
    UpdateTagFaq: "ویرایش پرسش و پاسخ برچسب",
    DeleteTagFaq: "حذف پرسش و پاسخ برچسب",
    MoveDownTagFaq: "پایین بردن پرسش و پاسخ برچسب",
    MoveUpTagFaq: "بالا بردن پرسش و پاسخ برچسب",

    CreateUser: "ایجاد کاربر",
    UpdateUser: "ویرایش کاربر",
    AddUserAddress: "افزودن آدرس کاربر",
    UpdateUserAddress: "ویرایش آدرس کاربر",
    DeleteUserAddress: "حذف آدرس کاربر",
    SetUsedProducts: "تغییر محصول به دست دوم",
    AddUsedProductsFaq: "افزودن پرسش و پاسخ به توضیحات صفحه دست دوم",
    DeleteUsedProductsFaq: "حذف پرسش و پاسخ از توضحات صفحه دست دوم",
    UpdateUsedProductsFaq: "ویرایش پرسش و پاسخ در توضحات صفحه دست دوم",
  },
};
