import { OrderAPI } from "@/api";
import { ADMIN_PERMISSIONS } from "@/consts/admin.const";
import { apiExecByPermission } from "@/lib/utils";
import { LoaderFunctionArgs, ParamParseKey } from "react-router-dom";

const USER_ROUTE = "/orders/:id";
type TypedParams = Record<ParamParseKey<typeof USER_ROUTE>, string>;

export default async function loader({ params }: LoaderFunctionArgs) {
  const id = (params as TypedParams).id;
  try {
    const resp = await apiExecByPermission([ADMIN_PERMISSIONS.READ_ORDER], () => OrderAPI.getById(id));
    return resp.data.data;
  } catch (err) {
    return null;
  }
}
