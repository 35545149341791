export const PRODUCT_SEARCH_SORTS = {
  PRICE_ASC: "PRICE_ASC",
  PRICE_DSC: "PRICE_DSC",
  CREATED_AT_ASC: "CREATED_AT_ASC",
  CREATED_AT_DSC: "CREATED_AT_DSC",
  UPDATED_AT_ASC: "UPDATED_AT_ASC",
  UPDATED_AT_DSC: "UPDATED_AT_DSC",
} as const;

export const PRODUCT_PROPERTIES_ICONS = {
  INFO: 0,
  BODY: 1,
  NECK: 2,
  DEVICE: 3,
  ELECTRONICS: 4,
  MORE: 5,
} as const;
