export default {
  title: "مدیریت برندها",
  addNew: "افزودن برند جدید",
  edit: "ویرایش برند {{title}}",
  editLabel: "ویرایش برند",
  delete: "حذف برند {{title}}",
  deleteConfirm: "از حذف برند {{title}} اطمینان دارید؟",
  nameFa: "عنوان فارسی",
  nameEn: "عنوان انگلیسی",
  desc: "توضیحات",
  descEn: "توضیحات انگلیسی",
  descFa: "توضیحات فارسی",
  faq: "سوالات متداول",
  faqTitleFa: "عنوان فارسی سوالات متداول",
  faqTitleEn: "عنوان انگلیسی سوالات متداول",
  canonical: "کانونیکال",
  faqList: "لیست سوالات متداول",
  questionFa: "سوال فارسی",
  questionEn: "سوال انگلیسی",
  answerFa: "پاسخ فارسی",
  answerEn: "پاسخ انگلیسی",
  uploadImage: "افزودن عکس",
  seoTitleFa: "عنوان فارسی سئو",
  seoTitleEn: "عنوان انگلیسی سئو",
  seoDescFa: "توضیحات فارسی سئو",
  seoDescEn: "توضیحات انگلیسی سئو",
};
