import CountCard from "@/components/card-count";
import ListData from "@/components/list-data";
import Page from "@/components/page";
import Pagination from "@/components/pagination";
import Price from "@/components/price";
import { Badge } from "@/components/ui/badge";
import { Button, buttonVariants } from "@/components/ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { TBrand } from "@/types/brand";
import { TCategory } from "@/types/category";
import { TPaginatedList } from "@/types/general";
import { TProduct } from "@/types/product";
import { TSubcategory } from "@/types/subcategory";
import _ from "lodash";
import { ExternalLink, FileText, Loader2 } from "lucide-react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link, useLoaderData, useNavigation, useSearchParams } from "react-router-dom";

export default function ProductsList() {
  const { t } = useTranslation();
  const data = useLoaderData() as {
    products: TPaginatedList<TProduct>;
    brands: TBrand[];
    categories: TCategory[];
    subcategories: TSubcategory[];
  } | null;

  const [searchParams, setSearchParams] = useSearchParams();
  const navigation = useNavigation();

  const [category, setCategory] = React.useState(searchParams.get("category") ?? "");
  const [subcategory, setSubcategory] = React.useState(searchParams.get("subcategory") ?? "");
  const [brand, setBrand] = React.useState(searchParams.get("brand") ?? "");

  // TODO: implement Error Page
  if (!data) return <div>Error</div>;

  return (
    <Page
      breadcrumbs={[
        { label: t("sidebar.home"), link: "/" },
        { label: t("productsManagement.title"), link: "/products" },
        { label: t("products.productsList") },
      ]}
      title={t("products.productsList")}
      action={
        <Link to="/products/add" className={buttonVariants()}>
          <Trans i18nKey="products.addNewProduct" />
        </Link>
      }
    >
      <div className="grid grid-cols-3 gap-4 mb-4">
        <CountCard count={data.products.pagination.total_items} title={t("products.totalProducts")} />
      </div>
      <ListData
        actionsClassNames="flex items-center gap-2"
        actions={
          <>
            {navigation.state === "loading" ? <Loader2 className="text-primary animate-spin" /> : null}
            {/* Category Select */}
            <Select
              value={category}
              onValueChange={(value) => {
                setCategory(value);
                setSearchParams((prevParams) => {
                  prevParams.set("category", value);
                  return prevParams;
                });
              }}
            >
              <SelectTrigger className="w-[160px]">
                <SelectValue placeholder={t("prices.categories")} />
              </SelectTrigger>
              <SelectContent>
                {data.categories?.map((category) => (
                  <SelectItem key={category.id} value={category.id}>
                    {category.title.fa}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            {/* Subcategory Select */}
            <Select
              value={subcategory}
              disabled={data.subcategories?.length === 0}
              onValueChange={(value) => {
                setSubcategory(value);
                setSearchParams((prevParams) => {
                  prevParams.set("subcategory", value);
                  return prevParams;
                });
              }}
            >
              <SelectTrigger className="w-[160px]">
                <SelectValue placeholder={t("prices.subcategories")} />
              </SelectTrigger>
              <SelectContent>
                {data.subcategories?.map((subcategory) => (
                  <SelectItem key={subcategory.id} value={subcategory.id}>
                    {subcategory.title.fa}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            {/* Brand Select */}
            <Select
              value={brand}
              onValueChange={(value) => {
                setBrand(value);
                setSearchParams((prevParams) => {
                  prevParams.set("brand", value);
                  return prevParams;
                });
              }}
            >
              <SelectTrigger className="w-[160px]">
                <SelectValue placeholder={t("prices.brands")} />
              </SelectTrigger>
              <SelectContent>
                {data.brands?.map((brand) => (
                  <SelectItem key={brand.id} value={brand.id}>
                    {brand.name.fa}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            <Button
              variant="ghost"
              disabled={!category && !subcategory && !brand}
              onClick={() => {
                setBrand("");
                setCategory("");
                setSubcategory("");
                setSearchParams({});
              }}
            >
              <Trans i18nKey="prices.clearSearch"></Trans>
            </Button>
          </>
        }
      >
        <Table className="bg-gray-scale-5">
          <TableHeader>
            <TableRow>
              <TableHead className="text-right">
                <Trans i18nKey="products.productTitle" /> ({data.products.pagination.filtered_items})
              </TableHead>
              <TableHead className="text-left">
                <Trans i18nKey="products.productPrice" />
              </TableHead>
              <TableHead className="text-left">
                <Trans i18nKey="products.createdAt" />
              </TableHead>
              <TableHead className="text-left">
                <Trans i18nKey="products.updatedAt" />
              </TableHead>
              <TableHead className="text-left">
                <Trans i18nKey="products.colors" />
              </TableHead>
              <TableHead />
              <TableHead />
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.products.items.map((product) => (
              <TableRow key={product.id}>
                <TableCell>
                  <div className="p-2 flex gap-2">
                    <img src={product.image} className="w-[70px] h-[70px] bg-white" />
                    <div className="flex-1 flex flex-col gap-1 font-montserrat">
                      <div className="flex items-center justify-between">
                        <span className="text-gray-scale-4 text-xs">{product.brand.name.en}</span>
                        {product.is_used ? (
                          <Badge variant="secondary" className="text-[10px] font-dana font-bold px-2 rounded-none">
                            <Trans i18nKey="common.used" />
                          </Badge>
                        ) : null}
                      </div>
                      <span>{product.name}</span>
                      <div className="text-xs text-gray-scale-4 divide-x">
                        <span>{product.category.title.en}</span>
                        &nbsp;|&nbsp;
                        <span>{product.subcategory.title.en}</span>
                        &nbsp;|&nbsp;
                        <span>{product.model.name}</span>
                      </div>
                    </div>
                  </div>
                </TableCell>
                <TableCell className="text-left">
                  <Price
                    className="justify-end text-nowrap"
                    call_to_buy={product.call_to_buy}
                    price={product.price}
                    price_to_pay={product.price_to_pay}
                    is_on_sale={product.is_on_sale}
                    is_available={product.is_available}
                  />
                </TableCell>
                <TableCell className="text-left text-nowrap">
                  {new Intl.DateTimeFormat("fa-IR", { day: "2-digit", month: "long", year: "numeric" }).format(
                    new Date(product.created_at)
                  )}
                </TableCell>
                <TableCell className="text-left text-nowrap">
                  {new Intl.DateTimeFormat("fa-IR", { day: "2-digit", month: "long", year: "numeric" }).format(
                    new Date(product.updated_at)
                  )}
                </TableCell>

                <TableCell>
                  <div className="flex flex-col gap-1 items-end">
                    {product.colors.map((color, index) =>
                      !_.isEmpty(color.color.base_color) ? (
                        <div
                          key={index}
                          className="w-[20px] h-[20px] rounded-full border border-white"
                          style={{ backgroundImage: `url("${color.color.base_color.link}")` }}
                        />
                      ) : (
                        <span key={index} className="text-muted-foreground">
                          <Trans i18nKey="common.unknown" />
                        </span>
                      )
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  <div className="flex flex-col gap-1">
                    <Link
                      to={`/products/${product.id}`}
                      className={buttonVariants({ variant: "secondary", size: "sm" })}
                    >
                      <Trans i18nKey="products.showDetails" />
                    </Link>
                    <a
                      className={buttonVariants({
                        variant: "secondary",
                        size: "sm",
                        className: "flex items-center gap-1",
                      })}
                      target="_blank"
                      href={`${import.meta.env.VITE_SHOP_BASE_URL}/product/${product.slug}`}
                    >
                      <ExternalLink size={16} className="inline-block mb-1" />
                      <Trans i18nKey="products.shop" />
                    </a>
                  </div>
                </TableCell>
                <TableCell>
                  <Link
                    to={`/logs?entity=${product.id}`}
                    className={buttonVariants({ variant: "ghost", size: "icon" })}
                  >
                    <FileText size={18} />
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ListData>
      {data.products?.items.length ? (
        <Pagination
          className="mt-4"
          path="/products/list"
          currentPage={data.products?.pagination.current_page}
          totalPage={data.products?.pagination.total_pages}
        />
      ) : null}
    </Page>
  );
}
