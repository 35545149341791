import { cn } from "@/lib/utils";
import { Link } from "react-router-dom";

type TProps = {
  bgImage: string;
  title: string;
  route: string;
  className?: string;
  disabled?: boolean;
};

export default function HoverCard(props: TProps) {
  const { title, bgImage, route, className, disabled } = props;
  return (
    <Link
      to={route}
      className={cn({
        "grayscale cursor-none pointer-events-none opacity-50": disabled,
      })}
    >
      <div
        style={{ backgroundImage: `url(${bgImage})` }}
        className={cn(
          "group w-full h-[220px] bg-cover bg-no-repeat bg-[#1f1f1f] flex items-center justify-start p-5 cursor-pointer transition-all duration-300 ease-out $",
          className
        )}
      >
        <span
          dir="rtl"
          className="text-white text-right text-xl group-hover:text-primary transition-all duration-300 ease-out"
        >
          {title}
        </span>
        <div className="h-[1px] w-[40px] bg-white mr-5 group-hover:bg-primary group-hover:w-20 transition-all duration-300 ease-out" />
      </div>
    </Link>
  );
}
