const Star = ({ className, width = 25, height = 25 }: { className?: string; width?: number; height?: number }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 25"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    fill="currentColor"
  >
    <g clipPath="url(#clip0_6338_23476)">
      <path d="M12.75 17.27L18.93 21L17.29 13.97L22.75 9.24L15.56 8.63L12.75 2L9.94 8.63L2.75 9.24L8.21 13.97L6.57 21L12.75 17.27Z" />
    </g>
    <defs>
      <clipPath id="clip0_6338_23476">
        <rect width={24} height={24} transform="translate(0.75)" />
      </clipPath>
    </defs>
  </svg>
);
export default Star;
