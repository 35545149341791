import { LogsAPI } from "@/api";
import { LoaderFunctionArgs } from "react-router-dom";

export default async function loader({ params }: LoaderFunctionArgs) {
  try {
    const { id } = params as { id: string };
    const resp = await LogsAPI.getById(id);
    return resp.data.data;
  } catch (err) {
    return null;
  }
}
