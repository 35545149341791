import { BrandAPI } from "@/api";
import BrandForm from "@/components/form-brand";
import Page from "@/components/page";
import { toast } from "@/components/ui/use-toast";
import { TNewBrand } from "@/types/brand";
import { isAxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function BrandAdd() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onSubmit = async (data: TNewBrand) => {
    try {
      const resp = await BrandAPI.create(data);
      toast({
        description: resp.data.data.message.fa,
      });
      navigate(-1);
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          description: err.response?.data.message,
          variant: "destructive",
        });
        return;
      }
      if (err instanceof Error) {
        toast({
          description: err.message,
          variant: "destructive",
        });
      }
    }
  };

  return (
    <Page
      breadcrumbs={[
        { label: t("sidebar.home"), link: "/" },
        { label: t("productsManagement.title"), link: "/products" },
        { label: t("brands.title"), link: "/products/brands" },
        { label: t("brands.addNew") },
      ]}
      title={t("brands.addNew")}
    >
      <BrandForm onSubmit={onSubmit} />
    </Page>
  );
}
