import { Trans } from "react-i18next";
import { Button } from "./ui/button";
import React from "react";
import { AdminsAPI } from "@/api";
import { isAxiosError } from "axios";
import { toast } from "./ui/use-toast";
import { cn } from "@/lib/utils";

type TProps = {
  value: string;
  onUpload: (url: string | null) => void;
  className?: string;
  disabled?: boolean;
};

export default function ImageUploader(props: TProps) {
  const { value, onUpload, className, disabled } = props;
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [uploading, setUploading] = React.useState(false);

  const uploadHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return onUpload(null);
    try {
      setUploading(true);
      const fd = new FormData();
      fd.append("image", e.target.files[0]);
      fd.append("watermark", "true");
      const resp = await AdminsAPI.upload(fd);
      onUpload(resp.data.data.url);
    } catch (err) {
      onUpload(null);
      if (isAxiosError(err)) {
        return toast({
          description: err.response?.data.message,
          variant: "destructive",
        });
      }
      if (err instanceof Error) {
        return toast({
          description: err.message,
          variant: "destructive",
        });
      }
    } finally {
      setUploading(false);
    }
  };
  return (
    <div
      className={cn(
        "h-40 flex items-center justify-center bg-white/10 border border-white/10 bg-no-repeat bg-cover bg-center",
        className
      )}
      style={{ backgroundImage: `url("${value}")` }}
    >
      <Button className={cn({ hidden: disabled })} loading={uploading} onClick={() => inputRef.current?.click()}>
        <Trans i18nKey="common.uploadImage" />
        <input
          ref={inputRef}
          type="file"
          className="hidden"
          onChange={uploadHandler} //(e) => onUpload(e, "banner", setUploadingBanner)}
        />
      </Button>
    </div>
  );
}
