import { BrandAPI } from "@/api";
import BrandForm from "@/components/form-brand";
import Page from "@/components/page";
import { toast } from "@/components/ui/use-toast";
import { TBrandDetailed, TBrandFAQItem, TBrandNewFAQItem, TNewBrand } from "@/types/brand";
import { isAxiosError } from "axios";
import { useTranslation } from "react-i18next";
import { useLoaderData, useNavigate, useParams, useRevalidator } from "react-router-dom";

export default function BrandEdit() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const brand = useLoaderData() as TBrandDetailed;
  const params = useParams() as { id: string };
  const revalidator = useRevalidator();

  const onSubmit = async (data: TNewBrand) => {
    try {
      const resp = await BrandAPI.update(brand.id, data);
      toast({
        description: resp.data.data.message.fa,
      });
      navigate(-1);
    } catch (error) {
      if (isAxiosError(error) && error.response?.data?.data?.message) {
        toast({
          description: error.response?.data.data.message.fa,
        });
        return;
      }
      if (error instanceof Error) {
        toast({
          description: error.message,
        });
      }
    }
  };

  const onAddFAQ = async (data: TBrandNewFAQItem) => {
    try {
      const resp = await BrandAPI.addQAById(params.id, data);
      revalidator.revalidate();
      toast({ description: resp.data.data.message.fa });
    } catch (error) {
      if (isAxiosError(error) && error.response?.data?.data?.message) {
        toast({
          description: error.response?.data.data.message.fa,
        });
        return;
      }
      if (error instanceof Error) {
        toast({
          description: error.message,
        });
      }
    }
  };

  const onEditFAQ = async (data: TBrandFAQItem) => {
    try {
      const resp = await BrandAPI.updateQAbyId(data.id, params.id, {
        question: { fa: data.question.fa, en: data.question.en },
        answer: { fa: data.answer.fa, en: data.answer.en },
      });
      revalidator.revalidate();
      toast({ description: resp.data.data.message.fa });
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          description: err.response?.data.message,
          variant: "destructive",
        });
        return;
      }
      if (err instanceof Error) {
        toast({
          description: err.message,
          variant: "destructive",
        });
      }
    }
  };

  const onDeleteFAQ = async (id: string) => {
    try {
      const resp = await BrandAPI.deleteQAById(id, params.id);
      revalidator.revalidate();
      toast({
        description: resp.data.data.message.fa,
      });
    } catch (err) {
      if (isAxiosError(err)) {
        toast({
          description: err.response?.data.message,
          variant: "destructive",
        });
        return;
      }
      if (err instanceof Error) {
        toast({
          description: err.message,
          variant: "destructive",
        });
      }
    }
  };

  return (
    <Page
      breadcrumbs={[
        { label: t("sidebar.home"), link: "/" },
        { label: t("productsManagement.title"), link: "/products" },
        { label: t("brands.title"), link: "/products/brands" },
        { label: t("brands.editLabel") },
      ]}
      title={t("brands.edit", { title: brand.name.fa })}
    >
      <BrandForm
        onSubmit={onSubmit}
        initData={brand}
        onEditFAQ={onEditFAQ}
        onDeleteFAQ={onDeleteFAQ}
        onAddFAQ={onAddFAQ}
      />
    </Page>
  );
}
